import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if @invitation.team}}\n  <AkStack @alignItems='center' @spacing='1'>\n    <AkTypography data-test-invite-type-text @tag='span' @color='textSecondary'>\n      {{t 'team'}}\n    </AkTypography>\n\n    <AkLink\n      data-test-invite-type-link\n      @color='primary'\n      @route='authenticated.dashboard.organization.teams'\n      @query={{hash team_query=@invitation.team.name}}\n    >\n      {{@invitation.team.name}}\n    </AkLink>\n  </AkStack>\n{{else}}\n  <AkTypography @tag='span' @color='textSecondary'>\n    {{t 'organization'}}\n  </AkTypography>\n{{/if}}", {"contents":"{{#if @invitation.team}}\n  <AkStack @alignItems='center' @spacing='1'>\n    <AkTypography data-test-invite-type-text @tag='span' @color='textSecondary'>\n      {{t 'team'}}\n    </AkTypography>\n\n    <AkLink\n      data-test-invite-type-link\n      @color='primary'\n      @route='authenticated.dashboard.organization.teams'\n      @query={{hash team_query=@invitation.team.name}}\n    >\n      {{@invitation.team.name}}\n    </AkLink>\n  </AkStack>\n{{else}}\n  <AkTypography @tag='span' @color='textSecondary'>\n    {{t 'organization'}}\n  </AkTypography>\n{{/if}}","moduleName":"irene/components/organization-invitation-list/invite-type/index.hbs","parseOptions":{"srcName":"irene/components/organization-invitation-list/invite-type/index.hbs"}});
import Component from '@glimmer/component';
import OrganizationInvitationModel from 'irene/models/organization-invitation';
import OrganizationTeamInvitationModel from 'irene/models/organization-team-invitation';

export interface OrganizationInvitationListInviteTypeSignature {
  Args: {
    invitation: OrganizationInvitationModel | OrganizationTeamInvitationModel;
  };
  Element: HTMLElement;
}

export default class OrganizationInvitationListInviteType extends Component<OrganizationInvitationListInviteTypeSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'organization-invitation-list/invite-type': typeof OrganizationInvitationListInviteType;
    'OrganizationInvitationList::InviteType': typeof OrganizationInvitationListInviteType;
  }
}
