import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkStack @alignItems='center' @justifyContent='center' local-class='login-root'>\n  <AkStack @direction='column' local-class='root-container'>\n    <AkStack\n      @width='full'\n      @justifyContent='center'\n      class='pt-3 pb-2'\n      local-class='logo-container'\n    >\n      <AuthAssets />\n    </AkStack>\n\n    <AkDivider @color='dark' />\n\n    {{yield}}\n  </AkStack>\n</AkStack>", {"contents":"<AkStack @alignItems='center' @justifyContent='center' local-class='login-root'>\n  <AkStack @direction='column' local-class='root-container'>\n    <AkStack\n      @width='full'\n      @justifyContent='center'\n      class='pt-3 pb-2'\n      local-class='logo-container'\n    >\n      <AuthAssets />\n    </AkStack>\n\n    <AkDivider @color='dark' />\n\n    {{yield}}\n  </AkStack>\n</AkStack>","moduleName":"irene/components/user-login/root-container/index.hbs","parseOptions":{"srcName":"irene/components/user-login/root-container/index.hbs"}});
import Component from '@glimmer/component';

export interface UserLoginRootContainerComponentSignature {
  Element: HTMLElement;
  Blocks: {
    default: [];
  };
}

export default class UserLoginRootContainerComponent extends Component<UserLoginRootContainerComponentSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'UserLogin::RootContainer': typeof UserLoginRootContainerComponent;
  }
}
