export default {
  "severity-level-root": "_severity-level-root_1adn9m",
  "severity-level-header": "_severity-level-header_1adn9m",
  "severity-indicator": "_severity-indicator_1adn9m",
  "severity-critical": "_severity-critical_1adn9m",
  "severity-high": "_severity-high_1adn9m",
  "severity-medium": "_severity-medium_1adn9m",
  "severity-low": "_severity-low_1adn9m",
  "severity-passed": "_severity-passed_1adn9m",
  "severity-none": "_severity-none_1adn9m"
};
