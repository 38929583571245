export default {
  "analysis-section": "_analysis-section_1vbtfl",
  "analysis-overridded-passed": "_analysis-overridded-passed_1vbtfl",
  "analysis-content-title": "_analysis-content-title_1vbtfl",
  "analysis-static-content": "_analysis-static-content_1vbtfl",
  "vulnerability-finding-container": "_vulnerability-finding-container_1vbtfl",
  "analysis-content-container": "_analysis-content-container_1vbtfl",
  "analysis-content-value": "_analysis-content-value_1vbtfl",
  "vulnerability-finding-description": "_vulnerability-finding-description_1vbtfl"
};
