import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkLink\n  @underline='none'\n  @route='authenticated.security.files'\n  @model={{@project.id}}\n  data-test-project-search-list-view-file-btn\n>\n  <AkButton @variant='outlined'>\n    View Files\n  </AkButton>\n</AkLink>", {"contents":"<AkLink\n  @underline='none'\n  @route='authenticated.security.files'\n  @model={{@project.id}}\n  data-test-project-search-list-view-file-btn\n>\n  <AkButton @variant='outlined'>\n    View Files\n  </AkButton>\n</AkLink>","moduleName":"irene/components/security/project-search-list/view-all-files/index.hbs","parseOptions":{"srcName":"irene/components/security/project-search-list/view-all-files/index.hbs"}});
import Component from '@glimmer/component';
import SecurityProjectModel from 'irene/models/security/project';

export interface SecurityProjectSearchListViewAllFilesComponentSignature {
  Args: {
    project: SecurityProjectModel;
  };
}

export default class SecurityProjectSearchListViewAllFilesComponent extends Component<SecurityProjectSearchListViewAllFilesComponentSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Security::ProjectSearchList::ViewAllFiles': typeof SecurityProjectSearchListViewAllFilesComponent;
    'security/project-search-list/view-all-files': typeof SecurityProjectSearchListViewAllFilesComponent;
  }
}
