import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.me.org.is_admin}}\n  <OrganizationMember::List\n    @queryParams={{@queryParams}}\n    @organization={{this.organization.selected}}\n  />\n\n  <div class='mt-7'>\n    <OrganizationInvitationList\n      @queryParams={{@queryParams}}\n      @organization={{this.organization.selected}}\n    >\n      <:headerContent>\n        <AkTypography data-test-invitation-list-title @variant='h5'>\n          {{t 'pendingInvitations'}}\n        </AkTypography>\n      </:headerContent>\n    </OrganizationInvitationList>\n  </div>\n{{/if}}", {"contents":"{{#if this.me.org.is_admin}}\n  <OrganizationMember::List\n    @queryParams={{@queryParams}}\n    @organization={{this.organization.selected}}\n  />\n\n  <div class='mt-7'>\n    <OrganizationInvitationList\n      @queryParams={{@queryParams}}\n      @organization={{this.organization.selected}}\n    >\n      <:headerContent>\n        <AkTypography data-test-invitation-list-title @variant='h5'>\n          {{t 'pendingInvitations'}}\n        </AkTypography>\n      </:headerContent>\n    </OrganizationInvitationList>\n  </div>\n{{/if}}","moduleName":"irene/components/organization-member/index.hbs","parseOptions":{"srcName":"irene/components/organization-member/index.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import MeService from 'irene/services/me';
import OrganizationService from 'irene/services/organization';
import OrganizationModel from 'irene/models/organization';
import OrganizationMemberModel from 'irene/models/organization-member';
import { OrganizationMembersRouteQueryParams } from 'irene/routes/authenticated/dashboard/organization/users';

interface OrganizationMemberSignature {
  Args: {
    member: OrganizationMemberModel;
    organization: OrganizationModel;
    queryParams: OrganizationMembersRouteQueryParams;
  };
  Element: HTMLElement;
}

export default class OrganizationMemberComponent extends Component<OrganizationMemberSignature> {
  @service declare me: MeService;
  @service declare organization: OrganizationService;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    OrganizationMember: typeof OrganizationMemberComponent;
  }
}
