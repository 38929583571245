import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{! @glint-nocheck: or}}\n<EmberTable\n  data-test-ak-table\n  ...attributes\n  local-class='\n    ak-table-root\n    ak-table-header-color-{{or @headerColor \"neutral\"}}\n    ak-table-variant-{{or @variant \"semi-bordered\"}}\n    ak-table-border-color-{{or @borderColor \"light\"}}\n    {{if @hoverable \"ak-table-hoverable\"}}\n    ak-table-padding-{{if @dense \"dense\" \"default\"}}'\n  as |et|\n>\n  {{yield et}}\n</EmberTable>", {"contents":"{{! @glint-nocheck: or}}\n<EmberTable\n  data-test-ak-table\n  ...attributes\n  local-class='\n    ak-table-root\n    ak-table-header-color-{{or @headerColor \"neutral\"}}\n    ak-table-variant-{{or @variant \"semi-bordered\"}}\n    ak-table-border-color-{{or @borderColor \"light\"}}\n    {{if @hoverable \"ak-table-hoverable\"}}\n    ak-table-padding-{{if @dense \"dense\" \"default\"}}'\n  as |et|\n>\n  {{yield et}}\n</EmberTable>","moduleName":"irene/components/ak-table/index.hbs","parseOptions":{"srcName":"irene/components/ak-table/index.hbs"}});
import Component from '@glimmer/component';

interface AkTableSignature {
  Element: HTMLDivElement;
  Args: {
    headerColor?: string;
    variant?: string;
    borderColor?: string;
    hoverable?: boolean;
    dense?: boolean;
  };

  Blocks: {
    // FIXME: Add a better type for yielded block
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    default: any;
  };
}

export default class AkTableComponent extends Component<AkTableSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    AkTable: typeof AkTableComponent;
  }
}
