import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkStack @direction='column' @spacing='4'>\n  <AccountSettings::DeveloperSettings::PersonalTokenList />\n\n  <AkDivider @color='dark' />\n</AkStack>", {"contents":"<AkStack @direction='column' @spacing='4'>\n  <AccountSettings::DeveloperSettings::PersonalTokenList />\n\n  <AkDivider @color='dark' />\n</AkStack>","moduleName":"irene/components/account-settings/developer-settings/index.hbs","parseOptions":{"srcName":"irene/components/account-settings/developer-settings/index.hbs"}});
import Component from '@glimmer/component';

export default class AccountSettingsDeveloperSettingsComponent extends Component {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AccountSettings::DeveloperSettings': typeof AccountSettingsDeveloperSettingsComponent;
  }
}
