import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if @vulnerabilityPreference.riskOverridden}}\n  <AnalysisRisk::Tag\n    @computedRisk={{@vulnerabilityPreference.risk}}\n    @isOverridden={{true}}\n    @disableOverriddenTooltip={{true}}\n    data-test-prjSettings-analysisSettings-vulnPreferenceItem-riskTag\n  />\n{{/if}}", {"contents":"{{#if @vulnerabilityPreference.riskOverridden}}\n  <AnalysisRisk::Tag\n    @computedRisk={{@vulnerabilityPreference.risk}}\n    @isOverridden={{true}}\n    @disableOverriddenTooltip={{true}}\n    data-test-prjSettings-analysisSettings-vulnPreferenceItem-riskTag\n  />\n{{/if}}","moduleName":"irene/components/project-settings/analysis-settings/vulnerability-list/overridden-severity/index.hbs","parseOptions":{"srcName":"irene/components/project-settings/analysis-settings/vulnerability-list/overridden-severity/index.hbs"}});
import Component from '@glimmer/component';
import type VulnerabilityPreferenceModel from 'irene/models/vulnerability-preference';

export interface ProjectSettingsAnalysisSettingsVulnerabilityListOverriddenSeveritySignature {
  Args: {
    vulnerabilityPreference: VulnerabilityPreferenceModel;
  };
}

export default class ProjectSettingsAnalysisSettingsVulnerabilityListOverriddenSeverityComponent extends Component<ProjectSettingsAnalysisSettingsVulnerabilityListOverriddenSeveritySignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'project-settings/analysis-settings/vulnerability-list/overridden-severity': typeof ProjectSettingsAnalysisSettingsVulnerabilityListOverriddenSeverityComponent;
  }
}
