import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  data-test-list-item-text\n  ...attributes\n  local-class='\n        ak-list-item-text-root\n        {{if @disabled \"ak-list-item-text-disabled\"}}'\n>\n  <AkTypography\n    data-test-list-item-text-primary\n    local-class='{{if @disabled \"ak-list-item-text-disabled\"}}'\n    class={{@primaryTextClass}}\n    @tag='span'\n    @noWrap={{true}}\n    @gutterBottom={{if @secondaryText true false}}\n  >\n    {{@primaryText}}\n  </AkTypography>\n\n  {{#if @secondaryText}}\n    <AkTypography\n      data-test-list-item-text-secondary\n      local-class='{{if @disabled \"ak-list-item-text-disabled\"}}'\n      @tag='span'\n      @noWrap={{true}}\n      @variant='body2'\n    >\n      {{@secondaryText}}\n    </AkTypography>\n  {{/if}}\n</div>", {"contents":"<div\n  data-test-list-item-text\n  ...attributes\n  local-class='\n        ak-list-item-text-root\n        {{if @disabled \"ak-list-item-text-disabled\"}}'\n>\n  <AkTypography\n    data-test-list-item-text-primary\n    local-class='{{if @disabled \"ak-list-item-text-disabled\"}}'\n    class={{@primaryTextClass}}\n    @tag='span'\n    @noWrap={{true}}\n    @gutterBottom={{if @secondaryText true false}}\n  >\n    {{@primaryText}}\n  </AkTypography>\n\n  {{#if @secondaryText}}\n    <AkTypography\n      data-test-list-item-text-secondary\n      local-class='{{if @disabled \"ak-list-item-text-disabled\"}}'\n      @tag='span'\n      @noWrap={{true}}\n      @variant='body2'\n    >\n      {{@secondaryText}}\n    </AkTypography>\n  {{/if}}\n</div>","moduleName":"irene/components/ak-list/item-text/index.hbs","parseOptions":{"srcName":"irene/components/ak-list/item-text/index.hbs"}});
import Component from '@glimmer/component';

export interface AkListItemTextSignature {
  Element: HTMLDivElement;
  Args: {
    primaryText?: string;
    primaryTextClass?: string;
    secondaryText?: string;
    disabled?: boolean;
  };
}

export default class AkListItemTextComponent extends Component<AkListItemTextSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AkList::ItemText': typeof AkListItemTextComponent;
    'ak-list/item-text': typeof AkListItemTextComponent;
  }
}
