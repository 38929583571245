import Model, { attr } from '@ember-data/model';

export default class SecurityVulnerabilityModel extends Model {
  @attr('string')
  declare name: string;

  @attr()
  declare types: unknown;
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'security/vulnerability': SecurityVulnerabilityModel;
  }
}
