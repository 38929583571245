export default {
  "ak-chip-root": "_ak-chip-root_t086gp",
  "ak-chip-button": "_ak-chip-button_t086gp",
  "ak-chip-variant-filled": "_ak-chip-variant-filled_t086gp",
  "ak-chip-color-default": "_ak-chip-color-default_t086gp",
  "ak-chip-color-primary": "_ak-chip-color-primary_t086gp",
  "ak-chip-color-secondary": "_ak-chip-color-secondary_t086gp",
  "ak-chip-color-success": "_ak-chip-color-success_t086gp",
  "ak-chip-color-error": "_ak-chip-color-error_t086gp",
  "ak-chip-color-warn": "_ak-chip-color-warn_t086gp",
  "ak-chip-color-info": "_ak-chip-color-info_t086gp",
  "ak-chip-variant-semi-filled": "_ak-chip-variant-semi-filled_t086gp",
  "ak-chip-variant-outlined": "_ak-chip-variant-outlined_t086gp",
  "ak-chip-variant-semi-filled-outlined": "_ak-chip-variant-semi-filled-outlined_t086gp",
  "ak-chip-label": "_ak-chip-label_t086gp",
  "ak-chip-icon": "_ak-chip-icon_t086gp",
  "ak-chip-size-medium": "_ak-chip-size-medium_t086gp",
  "ak-chip-delete-icon-button": "_ak-chip-delete-icon-button_t086gp",
  "ak-chip-size-small": "_ak-chip-size-small_t086gp"
};
