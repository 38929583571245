import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  class='swagger-ui-schemas-container'\n  data-test-publicApiDocs-schemas-container\n  {{did-insert this.initializeSchemas}}\n/>", {"contents":"<div\n  class='swagger-ui-schemas-container'\n  data-test-publicApiDocs-schemas-container\n  {{did-insert this.initializeSchemas}}\n/>","moduleName":"irene/components/public-api-docs/schemas/index.hbs","parseOptions":{"srcName":"irene/components/public-api-docs/schemas/index.hbs"}});
// @ts-expect-error no type defs
import SwaggerUI from 'swagger-ui';

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { type SwaggerUIDataProps } from '..';

interface PublicApiDocsSchemasSignature {
  Args: {
    data: SwaggerUIDataProps;
  };
}

export default class PublicApiDocsSchemasComponent extends Component<PublicApiDocsSchemasSignature> {
  @action
  initializeSchemas(element: HTMLDivElement) {
    SwaggerUI({
      spec: { ...this.args.data, info: {}, paths: {} },
      domNode: element,
      presets: [SwaggerUI.presets.apis, SwaggerUI.SwaggerUIStandalonePreset],
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'public-api-docs/schemas': typeof PublicApiDocsSchemasComponent;
  }
}
