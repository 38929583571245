export default {
  "file-compare-header-container": "_file-compare-header-container_1vzv1a",
  "file-compare-header-breadcrumbs": "_file-compare-header-breadcrumbs_1vzv1a",
  "file-compare-header-project-overview": "_file-compare-header-project-overview_1vzv1a",
  "divider": "_divider_1vzv1a",
  "file-icon": "_file-icon_1vzv1a",
  "file-compare-header-info": "_file-compare-header-info_1vzv1a",
  "files-overview-container": "_files-overview-container_1vzv1a",
  "platform-android": "_platform-android_1vzv1a",
  "platform-apple": "_platform-apple_1vzv1a"
};
