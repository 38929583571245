import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkTypography data-test-prjSettings-analysisSettings-vulnPreferenceItem-name>\n  {{this.vulnerabilityDetails.name}}\n</AkTypography>", {"contents":"<AkTypography data-test-prjSettings-analysisSettings-vulnPreferenceItem-name>\n  {{this.vulnerabilityDetails.name}}\n</AkTypography>","moduleName":"irene/components/project-settings/analysis-settings/vulnerability-list/name/index.hbs","parseOptions":{"srcName":"irene/components/project-settings/analysis-settings/vulnerability-list/name/index.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';

import type VulnerabilityPreferenceModel from 'irene/models/vulnerability-preference';

export interface ProjectSettingsAnalysisSettingsVulnerabilityListNameSignature {
  Args: {
    vulnerabilityPreference: VulnerabilityPreferenceModel;
  };
}

export default class ProjectSettingsAnalysisSettingsVulnerabilityListNameComponent extends Component<ProjectSettingsAnalysisSettingsVulnerabilityListNameSignature> {
  @service declare store: Store;

  get vulnerabilityDetails() {
    return this.store.peekRecord(
      'vulnerability',
      this.args.vulnerabilityPreference.id
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'project-settings/analysis-settings/vulnerability-list/name': typeof ProjectSettingsAnalysisSettingsVulnerabilityListNameComponent;
  }
}
