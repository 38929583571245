export default {
  "summary": "_summary_186shm",
  "riskbar": "_riskbar_186shm",
  "riskkey-color": "_riskkey-color_186shm",
  "riskblock--critical": "_riskblock--critical_186shm",
  "riskkey-color--critical": "_riskkey-color--critical_186shm",
  "riskblock--high": "_riskblock--high_186shm",
  "riskkey-color--high": "_riskkey-color--high_186shm",
  "riskblock--medium": "_riskblock--medium_186shm",
  "riskkey-color--medium": "_riskkey-color--medium_186shm",
  "riskblock--low": "_riskblock--low_186shm",
  "riskkey-color--low": "_riskkey-color--low_186shm",
  "riskblock--passed": "_riskblock--passed_186shm",
  "riskkey-color--passed": "_riskkey-color--passed_186shm",
  "riskblock--untested": "_riskblock--untested_186shm",
  "riskkey-color--untested": "_riskkey-color--untested_186shm",
  "index": "_index_186shm",
  "riskkey": "_riskkey_186shm",
  "riskkey-label": "_riskkey-label_186shm",
  "riskkey-text": "_riskkey-text_186shm",
  "riskkey-count": "_riskkey-count_186shm"
};
