export default {
  "ak-appbar-root": "_ak-appbar-root_oxtibf",
  "ak-appbar-gutter": "_ak-appbar-gutter_oxtibf",
  "ak-appbar-color-inherit": "_ak-appbar-color-inherit_oxtibf",
  "ak-appbar-default-icon-button": "_ak-appbar-default-icon-button_oxtibf",
  "ak-appbar-color-default": "_ak-appbar-color-default_oxtibf",
  "ak-appbar-color-light": "_ak-appbar-color-light_oxtibf",
  "ak-appbar-color-dark": "_ak-appbar-color-dark_oxtibf",
  "ak-appbar-position-static": "_ak-appbar-position-static_oxtibf",
  "ak-appbar-position-relative": "_ak-appbar-position-relative_oxtibf",
  "ak-appbar-position-fixed": "_ak-appbar-position-fixed_oxtibf",
  "ak-appbar-position-sticky": "_ak-appbar-position-sticky_oxtibf",
  "ak-appbar-position-absolute": "_ak-appbar-position-absolute_oxtibf",
  "ak-appbar-placement-top": "_ak-appbar-placement-top_oxtibf",
  "ak-appbar-placement-bottom": "_ak-appbar-placement-bottom_oxtibf",
  "ak-appbar-bottom-elevation": "_ak-appbar-bottom-elevation_oxtibf"
};
