export default {
  "search-input-conntainer-width": "_search-input-conntainer-width_1lbglk",
  "search-package-name-input": "_search-package-name-input_1lbglk",
  "header-home-page": "_header-home-page_1lbglk",
  "header-home-page-title": "_header-home-page-title_1lbglk",
  "header-home-page-sorting-filter": "_header-home-page-sorting-filter_1lbglk",
  "select-team-class": "_select-team-class_1lbglk",
  "filter-input": "_filter-input_1lbglk",
  "divider": "_divider_1lbglk",
  "clear-filter-label": "_clear-filter-label_1lbglk",
  "clear-filter-icon": "_clear-filter-icon_1lbglk",
  "clear-filter": "_clear-filter_1lbglk",
  "filter-input-dropdown": "_filter-input-dropdown_1lbglk"
};
