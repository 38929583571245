import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkTypography @fontWeight='bold' local-class='marketplace-header'>\n  {{t 'marketplace'}}\n</AkTypography>\n\n<AkStack @direction='column' class='pt-4 pl-2 pb-1'>\n  <AkTypography @fontWeight='bold' local-class='sub-header'>\n    {{t 'plugins'}}\n  </AkTypography>\n\n  <AkTypography local-class='sub-header-description'>\n    {{t 'marketplacePluginDesc'}}\n  </AkTypography>\n</AkStack>\n\n<AkStack class='pb-4'>\n  <Marketplace::PluginList />\n</AkStack>\n\n<AkStack @direction='column' class='pl-2 pb-1'>\n  <AkTypography @fontWeight='bold' local-class='sub-header'>\n    {{t 'integration'}}\n  </AkTypography>\n\n  <AkTypography local-class='sub-header-description'>\n    {{t 'marketplaceIntegrationDesc'}}\n  </AkTypography>\n</AkStack>\n\n<AkStack class='pb-4'>\n  <Marketplace::IntegrationList />\n</AkStack>", {"contents":"<AkTypography @fontWeight='bold' local-class='marketplace-header'>\n  {{t 'marketplace'}}\n</AkTypography>\n\n<AkStack @direction='column' class='pt-4 pl-2 pb-1'>\n  <AkTypography @fontWeight='bold' local-class='sub-header'>\n    {{t 'plugins'}}\n  </AkTypography>\n\n  <AkTypography local-class='sub-header-description'>\n    {{t 'marketplacePluginDesc'}}\n  </AkTypography>\n</AkStack>\n\n<AkStack class='pb-4'>\n  <Marketplace::PluginList />\n</AkStack>\n\n<AkStack @direction='column' class='pl-2 pb-1'>\n  <AkTypography @fontWeight='bold' local-class='sub-header'>\n    {{t 'integration'}}\n  </AkTypography>\n\n  <AkTypography local-class='sub-header-description'>\n    {{t 'marketplaceIntegrationDesc'}}\n  </AkTypography>\n</AkStack>\n\n<AkStack class='pb-4'>\n  <Marketplace::IntegrationList />\n</AkStack>","moduleName":"irene/components/marketplace/index.hbs","parseOptions":{"srcName":"irene/components/marketplace/index.hbs"}});
import Component from '@glimmer/component';

export default class MarketplaceComponent extends Component {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    Marketplace: typeof MarketplaceComponent;
  }
}
