import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.isFirstRow}}\n  <AkTypography @fontWeight='bold'>\n    {{t 'fileCompare.nameOfTestCase'}}\n  </AkTypography>\n{{else}}\n  <AkTypography>\n    {{this.vulnerabilityName}}\n  </AkTypography>\n{{/if}}", {"contents":"{{#if this.isFirstRow}}\n  <AkTypography @fontWeight='bold'>\n    {{t 'fileCompare.nameOfTestCase'}}\n  </AkTypography>\n{{else}}\n  <AkTypography>\n    {{this.vulnerabilityName}}\n  </AkTypography>\n{{/if}}","moduleName":"irene/components/file-compare/table/test-case/index.hbs","parseOptions":{"srcName":"irene/components/file-compare/table/test-case/index.hbs"}});
import Component from '@glimmer/component';
import { FileCompareTableData } from 'irene/components/file-compare/table';

interface FileCompareTableTestCaseSignature {
  Args: {
    comparison: FileCompareTableData;
  };
}

export default class FileCompareTableTestCaseComponent extends Component<FileCompareTableTestCaseSignature> {
  get comparison() {
    return this.args.comparison;
  }

  // Row types
  get isFirstRow() {
    return 'file1' in this.comparison;
  }

  get vulnerabilityName() {
    if (this.comparison.vulnerability) {
      return this.comparison.vulnerability.get('name');
    }

    return '';
  }
}
