export default {
  "title": "_title_1toiua",
  "header": "_header_1toiua",
  "body": "_body_1toiua",
  "filter": "_filter_1toiua",
  "date-range-picker": "_date-range-picker_1toiua",
  "date-range": "_date-range_1toiua",
  "date": "_date_1toiua",
  "duration-options": "_duration-options_1toiua",
  "option": "_option_1toiua",
  "active": "_active_1toiua",
  "tooltip": "_tooltip_1toiua",
  "tt-val-label": "_tt-val-label_1toiua",
  "loading-spinner": "_loading-spinner_1toiua"
};
