export default {
  "client-info-card": "_client-info-card_1aubmn",
  "client-info-header": "_client-info-header_1aubmn",
  "thumbnail": "_thumbnail_1aubmn",
  "thumbnail-icon": "_thumbnail-icon_1aubmn",
  "title": "_title_1aubmn",
  "empty-title": "_empty-title_1aubmn",
  "table": "_table_1aubmn",
  "row": "_row_1aubmn",
  "key": "_key_1aubmn",
  "value": "_value_1aubmn",
  "actions": "_actions_1aubmn",
  "action": "_action_1aubmn"
};
