import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkTypography\n  @fontWeight='bold'\n  data-test-sbomComponentVulnerabilities-cvssScore\n>\n  {{#if this.isUnknownSeverity}}\n    -\n  {{else}}\n    {{this.score}}\n  {{/if}}\n</AkTypography>", {"contents":"<AkTypography\n  @fontWeight='bold'\n  data-test-sbomComponentVulnerabilities-cvssScore\n>\n  {{#if this.isUnknownSeverity}}\n    -\n  {{else}}\n    {{this.score}}\n  {{/if}}\n</AkTypography>","moduleName":"irene/components/sbom/component-details/vulnerabilities/cvss-score/index.hbs","parseOptions":{"srcName":"irene/components/sbom/component-details/vulnerabilities/cvss-score/index.hbs"}});
import Component from '@glimmer/component';
import SbomVulnerabilityModel, {
  VulnerabilitySeverity,
} from 'irene/models/sbom-vulnerability';

export interface SbomComponentDetailsVulnerabilitiesCvssScoreSignature {
  Args: {
    sbomVulnerability: SbomVulnerabilityModel;
  };
}

export default class SbomComponentDetailsVulnerabilitiesCvssScoreComponent extends Component<SbomComponentDetailsVulnerabilitiesCvssScoreSignature> {
  get score() {
    return this.args.sbomVulnerability.score;
  }

  get isUnknownSeverity() {
    return (
      this.args.sbomVulnerability.severity === VulnerabilitySeverity.UNKNOWN
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'sbom/component-details/vulnerabilities/cvss-score': typeof SbomComponentDetailsVulnerabilitiesCvssScoreComponent;
  }
}
