import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  data-test-list-item-icon\n  ...attributes\n  local-class='\n    ak-list-item-icon-root\n    {{if @leftGutter \"ak-list-item-icon-gutter-left\"}}\n    {{if @rightGutter \"ak-list-item-icon-gutter-right\"}}\n    {{if @disabled \"ak-list-item-icon-disabled\"}}'\n>\n  {{yield}}\n</div>", {"contents":"<div\n  data-test-list-item-icon\n  ...attributes\n  local-class='\n    ak-list-item-icon-root\n    {{if @leftGutter \"ak-list-item-icon-gutter-left\"}}\n    {{if @rightGutter \"ak-list-item-icon-gutter-right\"}}\n    {{if @disabled \"ak-list-item-icon-disabled\"}}'\n>\n  {{yield}}\n</div>","moduleName":"irene/components/ak-list/item-icon/index.hbs","parseOptions":{"srcName":"irene/components/ak-list/item-icon/index.hbs"}});
import Component from '@glimmer/component';

export interface AkListItemIconSignature {
  Element: HTMLDivElement;
  Args: {
    leftGutter?: boolean;
    rightGutter?: boolean;
    disabled?: boolean;
  };
  Blocks: { default: [] };
}

export default class AkListItemIconComponent extends Component<AkListItemIconSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AkList::ItemIcon': typeof AkListItemIconComponent;
    'ak-list/item-icon': typeof AkListItemIconComponent;
  }
}
