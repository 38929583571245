import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkButton\n  @disabled={{this.disableReportBtn}}\n  {{style paddingRight='12px'}}\n  {{on 'click' this.openReportDrawer}}\n  data-test-fileReportBtn\n>\n  <:leftIcon>\n    <AkIcon\n      @variant='outlined'\n      @iconName='description'\n      data-test-fileReportBtn-icon\n    />\n  </:leftIcon>\n\n  <:default>\n    {{t 'viewReport'}}\n  </:default>\n</AkButton>\n\n<File::ReportDrawer\n  @file={{@file}}\n  @open={{this.reportDrawerOpen}}\n  @onClose={{this.closeReportDrawer}}\n/>", {"contents":"<AkButton\n  @disabled={{this.disableReportBtn}}\n  {{style paddingRight='12px'}}\n  {{on 'click' this.openReportDrawer}}\n  data-test-fileReportBtn\n>\n  <:leftIcon>\n    <AkIcon\n      @variant='outlined'\n      @iconName='description'\n      data-test-fileReportBtn-icon\n    />\n  </:leftIcon>\n\n  <:default>\n    {{t 'viewReport'}}\n  </:default>\n</AkButton>\n\n<File::ReportDrawer\n  @file={{@file}}\n  @open={{this.reportDrawerOpen}}\n  @onClose={{this.closeReportDrawer}}\n/>","moduleName":"irene/components/file/report-btn/index.hbs","parseOptions":{"srcName":"irene/components/file/report-btn/index.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

import FileModel from 'irene/models/file';

export interface FileReportBtnSignature {
  Args: {
    file: FileModel;
  };
}

export default class FileReportBtn extends Component<FileReportBtnSignature> {
  @tracked reportDrawerOpen = false;

  get isStaticScanDone() {
    return this.args.file.isStaticDone;
  }

  get disableReportBtn() {
    return !this.isStaticScanDone;
  }

  @action openReportDrawer() {
    if (this.disableReportBtn) {
      return;
    }

    this.reportDrawerOpen = true;
  }

  @action closeReportDrawer() {
    this.reportDrawerOpen = false;
  }
}
