import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkStack @alignItems='center' @spacing='0.5'>\n  <AkIconButton\n    data-test-sbomComponentVulnerabilities-toggleDetailsBtn\n    {{on 'click' @collapsibleContentHandler}}\n  >\n    <AkIcon\n      @iconName={{if @isContentCollapsed 'arrow-drop-down' 'arrow-drop-up'}}\n    />\n  </AkIconButton>\n\n  <AkTypography data-test-sbomComponentVulnerabilities-vulnerabilityId>\n\n    {{@sbomVulnerability.vulnerabilityId}}\n  </AkTypography>\n</AkStack>", {"contents":"<AkStack @alignItems='center' @spacing='0.5'>\n  <AkIconButton\n    data-test-sbomComponentVulnerabilities-toggleDetailsBtn\n    {{on 'click' @collapsibleContentHandler}}\n  >\n    <AkIcon\n      @iconName={{if @isContentCollapsed 'arrow-drop-down' 'arrow-drop-up'}}\n    />\n  </AkIconButton>\n\n  <AkTypography data-test-sbomComponentVulnerabilities-vulnerabilityId>\n\n    {{@sbomVulnerability.vulnerabilityId}}\n  </AkTypography>\n</AkStack>","moduleName":"irene/components/sbom/component-details/vulnerabilities/vulnerability-id/index.hbs","parseOptions":{"srcName":"irene/components/sbom/component-details/vulnerabilities/vulnerability-id/index.hbs"}});
import Component from '@glimmer/component';
import SbomVulnerabilityModel from 'irene/models/sbom-vulnerability';

export interface SbomComponentDetailsVulnerabilitiesVulnerabilityIdSignature {
  Args: {
    collapsibleContentHandler: () => void;
    isContentCollapsed: boolean;
    sbomVulnerability: SbomVulnerabilityModel;
  };
}

export default class SbomComponentDetailsVulnerabilitiesVulnerabilityIdComponent extends Component<SbomComponentDetailsVulnerabilitiesVulnerabilityIdSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'sbom/component-details/vulnerabilities/vulnerability-id': typeof SbomComponentDetailsVulnerabilitiesVulnerabilityIdComponent;
  }
}
