import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#each (array 0 1) as |loader|}}\n  <AkStack\n    class='px-3 {{if (eq loader 0) \"py-2\" \"pb-2\"}}'\n    @spacing='1'\n    @alignItems='center'\n  >\n    <AkSkeleton @width='16px' @height='16px' />\n\n    <AkSkeleton @width='100px' @height='16px' />\n  </AkStack>\n{{/each}}", {"contents":"{{#each (array 0 1) as |loader|}}\n  <AkStack\n    class='px-3 {{if (eq loader 0) \"py-2\" \"pb-2\"}}'\n    @spacing='1'\n    @alignItems='center'\n  >\n    <AkSkeleton @width='16px' @height='16px' />\n\n    <AkSkeleton @width='100px' @height='16px' />\n  </AkStack>\n{{/each}}","moduleName":"irene/components/file-details/dynamic-scan/action/drawer/automated-dast/loading/index.hbs","parseOptions":{"srcName":"irene/components/file-details/dynamic-scan/action/drawer/automated-dast/loading/index.hbs"}});
import Component from '@glimmer/component';

export default class FileDetailsDynamicScanActionDrawerAutomatedDastLoadingComponent extends Component {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'FileDetails::DynamicScan::Action::Drawer::AutomatedDast::Loading': typeof FileDetailsDynamicScanActionDrawerAutomatedDastLoadingComponent;
  }
}
