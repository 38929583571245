import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkStack @spacing='1' @alignItems='center' class='px-1 pr-2'>\n  <AkIcon @iconName={{this.iconName}} @size='small' />\n\n  <AkTypography @fontWeight='medium' local-class='trigger-label'>\n    {{this.selectedItem}}\n  </AkTypography>\n</AkStack>", {"contents":"<AkStack @spacing='1' @alignItems='center' class='px-1 pr-2'>\n  <AkIcon @iconName={{this.iconName}} @size='small' />\n\n  <AkTypography @fontWeight='medium' local-class='trigger-label'>\n    {{this.selectedItem}}\n  </AkTypography>\n</AkStack>","moduleName":"irene/components/file-details/dynamic-scan/action/drawer/device-pref-table/filter-selected-item/index.hbs","parseOptions":{"srcName":"irene/components/file-details/dynamic-scan/action/drawer/device-pref-table/filter-selected-item/index.hbs"}});
import Component from '@glimmer/component';

interface SecurityAnalysisListFilterSelectedItemArgs {
  extra: Record<'selectedOptionLabel' | 'iconName', string>;
}

export default class SecurityAnalysisListFilterSelectedItemComponent extends Component<SecurityAnalysisListFilterSelectedItemArgs> {
  get selectedItem() {
    return this.args.extra?.selectedOptionLabel;
  }

  get iconName() {
    return this.args.extra?.iconName;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'file-details/dynamic-scan/action/drawer/device-pref-table/filter-selected-item': typeof SecurityAnalysisListFilterSelectedItemComponent;
  }
}
