import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkStack\n  data-test-app-logo-container\n  ...attributes\n  local-class='app-logo-container-size-{{this.size}} {{this.paddingClass}} {{this.borderClass}}'\n  @alignItems='center'\n  @justifyContent='center'\n>\n  {{#if @loading}}\n    <AkSkeleton\n      data-test-app-logo-skeleton\n      @width='100px'\n      @height='100%'\n      @variant='rectangular'\n    />\n  {{else}}\n    <img\n      data-test-appLogo-img\n      local-class='app-logo-image {{if @rounded \"app-logo-image-rounded\"}}'\n      src={{this.src}}\n      alt={{this.alt}}\n      role={{this.role}}\n    />\n  {{/if}}\n</AkStack>", {"contents":"<AkStack\n  data-test-app-logo-container\n  ...attributes\n  local-class='app-logo-container-size-{{this.size}} {{this.paddingClass}} {{this.borderClass}}'\n  @alignItems='center'\n  @justifyContent='center'\n>\n  {{#if @loading}}\n    <AkSkeleton\n      data-test-app-logo-skeleton\n      @width='100px'\n      @height='100%'\n      @variant='rectangular'\n    />\n  {{else}}\n    <img\n      data-test-appLogo-img\n      local-class='app-logo-image {{if @rounded \"app-logo-image-rounded\"}}'\n      src={{this.src}}\n      alt={{this.alt}}\n      role={{this.role}}\n    />\n  {{/if}}\n</AkStack>","moduleName":"irene/components/app-logo/index.hbs","parseOptions":{"srcName":"irene/components/app-logo/index.hbs"}});
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';

interface AppLogoSignature {
  Element: HTMLElement;
  Args: {
    src?: string;
    alt?: string;
    padding?: boolean;
    loading?: boolean;
    rounded?: boolean;
    size?: 'small' | 'medium' | 'large';
    role?: string;
    border?: boolean;
  };
}

export default class AppLogoComponent extends Component<AppLogoSignature> {
  get size() {
    return this.args.size || 'small';
  }

  get padding() {
    return isEmpty(this.args.padding) ? true : this.args.padding;
  }

  get paddingClass() {
    return this.padding ? 'app-logo-container-padding' : '';
  }

  get alt() {
    return this.args.alt || '';
  }

  get src() {
    return this.args.src || '';
  }

  get role() {
    return this.args.role || 'img';
  }

  get border() {
    return isEmpty(this.args.border) ? true : this.args.border;
  }

  get borderClass() {
    return this.border ? 'app-logo-container-border' : '';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    AppLogo: typeof AppLogoComponent;
  }
}
