export default {
  "header": "_header_13iccc",
  "title": "_title_13iccc",
  "table": "_table_13iccc",
  "table-header": "_table-header_13iccc",
  "table-row": "_table-row_13iccc",
  "pagination": "_pagination_13iccc",
  "loading-error": "_loading-error_13iccc",
  "loading": "_loading_13iccc",
  "empty-data": "_empty-data_13iccc"
};
