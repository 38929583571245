import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<FileDetails::VulnerabilityAnalysis::Header\n  @file={{this.file}}\n  @filterVulnerabilityType={{this.filterVulnerabilityType}}\n  @setVulnerabilityType={{this.setVulnerabilityType}}\n/>\n\n<FileDetails::VulnerabilityAnalysis::Table\n  @file={{this.file}}\n  @filterVulnerabilityType={{this.filterVulnerabilityType}}\n  @sorts={{this.sorts}}\n  @updateAnalysesSorts={{this.updateAnalysesSorts}}\n/>", {"contents":"<FileDetails::VulnerabilityAnalysis::Header\n  @file={{this.file}}\n  @filterVulnerabilityType={{this.filterVulnerabilityType}}\n  @setVulnerabilityType={{this.setVulnerabilityType}}\n/>\n\n<FileDetails::VulnerabilityAnalysis::Table\n  @file={{this.file}}\n  @filterVulnerabilityType={{this.filterVulnerabilityType}}\n  @sorts={{this.sorts}}\n  @updateAnalysesSorts={{this.updateAnalysesSorts}}\n/>","moduleName":"irene/components/file-details/vulnerability-analysis/index.hbs","parseOptions":{"srcName":"irene/components/file-details/vulnerability-analysis/index.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import type { EmberTableSort } from 'ember-table';

import ENUMS from 'irene/enums';
import type FileModel from 'irene/models/file';

export interface FileDetailsVulnerabilityAnalysisSignature {
  Args: {
    file: FileModel;
  };
}

const DEFAULT_ANALYSES_SORTS = [
  { isAscending: false, valuePath: 'computedRisk' },
];

export default class FileDetailsVulnerabilityAnalysisComponent extends Component<FileDetailsVulnerabilityAnalysisSignature> {
  @tracked filterVulnerabilityType: string | number =
    ENUMS.VULNERABILITY_TYPE.UNKNOWN;

  @tracked sorts = DEFAULT_ANALYSES_SORTS;

  get file() {
    return this.args.file;
  }

  @action
  setVulnerabilityType(type: string | number) {
    this.filterVulnerabilityType = type;

    this.resetSorts();
  }

  @action
  resetSorts() {
    this.sorts = DEFAULT_ANALYSES_SORTS;
  }

  @action
  updateAnalysesSorts(sorts: EmberTableSort[]) {
    this.sorts = sorts;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'FileDetails::VulnerabilityAnalysis': typeof FileDetailsVulnerabilityAnalysisComponent;
  }
}
