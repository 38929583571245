export default {
  "ak-table-root": "_ak-table-root_1jqw2e",
  "ak-table-padding-dense": "_ak-table-padding-dense_1jqw2e",
  "ak-table-padding-default": "_ak-table-padding-default_1jqw2e",
  "ak-table-header-color-neutral": "_ak-table-header-color-neutral_1jqw2e",
  "ak-table-header-color-transparent": "_ak-table-header-color-transparent_1jqw2e",
  "ak-table-variant-semi-bordered": "_ak-table-variant-semi-bordered_1jqw2e",
  "ak-table-border-color-light": "_ak-table-border-color-light_1jqw2e",
  "ak-table-border-color-dark": "_ak-table-border-color-dark_1jqw2e",
  "ak-table-variant-full-bordered": "_ak-table-variant-full-bordered_1jqw2e",
  "ak-table-hoverable": "_ak-table-hoverable_1jqw2e"
};
