import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<ProjectSettings::DastScenarioToggle\n  @project={{@project}}\n  @scenario={{@scenario}}\n  @hideToggleLoader={{true}}\n/>", {"contents":"<ProjectSettings::DastScenarioToggle\n  @project={{@project}}\n  @scenario={{@scenario}}\n  @hideToggleLoader={{true}}\n/>","moduleName":"irene/components/project-settings/general-settings/dynamicscan-automation-settings/scenario-table/status/index.hbs","parseOptions":{"srcName":"irene/components/project-settings/general-settings/dynamicscan-automation-settings/scenario-table/status/index.hbs"}});
import Component from '@glimmer/component';
import ScanParameterGroupModel from 'irene/models/scan-parameter-group';
import ProjectModel from 'irene/models/project';

export interface ProjectSettingsGeneralSettingsDynamicscanAutomationScenarioTableStatusSignature {
  Args: {
    project?: ProjectModel;
    scenario: ScanParameterGroupModel;
  };
}

export default class ProjectSettingsGeneralSettingsDynamicscanAutomationScenarioTableStatusComponent extends Component<ProjectSettingsGeneralSettingsDynamicscanAutomationScenarioTableStatusSignature> {}
