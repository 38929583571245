export default {
  "position-relative": "_position-relative_9zl9yl",
  "position-absolute": "_position-absolute_9zl9yl",
  "position-sticky": "_position-sticky_9zl9yl",
  "position-fixed": "_position-fixed_9zl9yl",
  "position-static": "_position-static_9zl9yl",
  "flex-row": "_flex-row_9zl9yl",
  "flex-column": "_flex-column_9zl9yl",
  "flex-justify-start": "_flex-justify-start_9zl9yl",
  "flex-justify-end": "_flex-justify-end_9zl9yl",
  "flex-justify-center": "_flex-justify-center_9zl9yl",
  "flex-justify-space-between": "_flex-justify-space-between_9zl9yl",
  "flex-justify-space-around": "_flex-justify-space-around_9zl9yl",
  "flex-align-start": "_flex-align-start_9zl9yl",
  "flex-align-end": "_flex-align-end_9zl9yl",
  "flex-align-center": "_flex-align-center_9zl9yl",
  "flex-align-space-between": "_flex-align-space-between_9zl9yl",
  "flex-align-space-around": "_flex-align-space-around_9zl9yl",
  "flex-wrap": "_flex-wrap_9zl9yl",
  "flex-row-gap-#{$i}": "_flex-row-gap-#{$i}_9zl9yl",
  "p-#{$i}": "_p-#{$i}_9zl9yl",
  "py-#{$i}": "_py-#{$i}_9zl9yl",
  "px-#{$i}": "_px-#{$i}_9zl9yl",
  "pt-#{$i}": "_pt-#{$i}_9zl9yl",
  "pb-#{$i}": "_pb-#{$i}_9zl9yl",
  "pl-#{$i}": "_pl-#{$i}_9zl9yl",
  "pr-#{$i}": "_pr-#{$i}_9zl9yl",
  "m-#{$i}": "_m-#{$i}_9zl9yl",
  "my-#{$i}": "_my-#{$i}_9zl9yl",
  "mx-#{$i}": "_mx-#{$i}_9zl9yl",
  "mt-#{$i}": "_mt-#{$i}_9zl9yl",
  "mb-#{$i}": "_mb-#{$i}_9zl9yl",
  "ml-#{$i}": "_ml-#{$i}_9zl9yl",
  "mr-#{$i}": "_mr-#{$i}_9zl9yl",
  "mx-auto": "_mx-auto_9zl9yl",
  "w-#{$i}/12": "_w-#{$i}/12_9zl9yl",
  "w-full": "_w-full_9zl9yl"
};
