import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkStack @alignItems='center' @spacing='1'>\n  <AnalysisRisk::Tag\n    @computedRisk={{@analysis.risk}}\n    {{style maxWidth='85px'}}\n    data-test-securityAnalysisListTable-analysisRisk\n  />\n\n  {{#if this.riskIsOverriden}}\n    <AkIcon\n      @iconName='east'\n      @size='small'\n      data-test-securityAnalysisListTable-analysisOverriddenIcon\n    />\n\n    <AnalysisRisk::Tag\n      @computedRisk={{this.overridenRisk}}\n      {{style maxWidth='85px'}}\n      data-test-securityAnalysisListTable-analysisOverriddenRisk\n    />\n  {{/if}}\n</AkStack>", {"contents":"<AkStack @alignItems='center' @spacing='1'>\n  <AnalysisRisk::Tag\n    @computedRisk={{@analysis.risk}}\n    {{style maxWidth='85px'}}\n    data-test-securityAnalysisListTable-analysisRisk\n  />\n\n  {{#if this.riskIsOverriden}}\n    <AkIcon\n      @iconName='east'\n      @size='small'\n      data-test-securityAnalysisListTable-analysisOverriddenIcon\n    />\n\n    <AnalysisRisk::Tag\n      @computedRisk={{this.overridenRisk}}\n      {{style maxWidth='85px'}}\n      data-test-securityAnalysisListTable-analysisOverriddenRisk\n    />\n  {{/if}}\n</AkStack>","moduleName":"irene/components/security/analysis-list/table/risk/index.hbs","parseOptions":{"srcName":"irene/components/security/analysis-list/table/risk/index.hbs"}});
import Component from '@glimmer/component';
import type SecurityAnalysisModel from 'irene/models/security/analysis';

export interface SecurityAnalysisListTableRiskComponentSignature {
  Args: {
    analysis: SecurityAnalysisModel;
  };
}

export default class SecurityAnalysisListTableRiskComponent extends Component<SecurityAnalysisListTableRiskComponentSignature> {
  get analysis() {
    return this.args.analysis;
  }

  get overridenRisk() {
    return this.analysis.overriddenRisk;
  }

  get riskIsOverriden() {
    return this.overridenRisk !== null;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'security/analysis-list/table/risk': typeof SecurityAnalysisListTableRiskComponent;
  }
}
