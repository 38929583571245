export default {
  "partner-plan": "_partner-plan_2ycn0d",
  "plan": "_plan_2ycn0d",
  "plan-key": "_plan-key_2ycn0d",
  "plan-label": "_plan-label_2ycn0d",
  "plan-type": "_plan-type_2ycn0d",
  "per-app": "_per-app_2ycn0d",
  "per-scan": "_per-scan_2ycn0d",
  "plan-value": "_plan-value_2ycn0d",
  "credits-left": "_credits-left_2ycn0d",
  "credits-type": "_credits-type_2ycn0d"
};
