import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkStack\n  @direction='column'\n  @justifyContent='center'\n  data-test-amVersionTable-storeVersion\n>\n  {{#if @amAppVersion.displayVersion}}\n    {{@amAppVersion.displayVersion}}\n  {{/if}}\n</AkStack>", {"contents":"<AkStack\n  @direction='column'\n  @justifyContent='center'\n  data-test-amVersionTable-storeVersion\n>\n  {{#if @amAppVersion.displayVersion}}\n    {{@amAppVersion.displayVersion}}\n  {{/if}}\n</AkStack>","moduleName":"irene/components/app-monitoring/version-table/store-version/index.hbs","parseOptions":{"srcName":"irene/components/app-monitoring/version-table/store-version/index.hbs"}});
import Component from '@glimmer/component';
import AmAppVersionModel from 'irene/models/am-app-version';

interface AppMonitoringVersionTableStoreVersionSignature {
  Args: {
    amAppVersion: AmAppVersionModel;
  };
}

export default class AppMonitoringVersionTableStoreVersionComponent extends Component<AppMonitoringVersionTableStoreVersionSignature> {}
