import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkStack\n  @width='full'\n  @alignItems='center'\n  @justifyContent='center'\n  ...attributes\n>\n  <AkStack\n    local-class='loading-container'\n    @alignItems='center'\n    @justifyContent='center'\n    @spacing='1.5'\n  >\n    <AkLoader @size={{16}} />\n    <AkTypography local-class='loading-text' @color='textSecondary'>\n      {{t 'loading'}}...\n    </AkTypography>\n  </AkStack>\n</AkStack>", {"contents":"<AkStack\n  @width='full'\n  @alignItems='center'\n  @justifyContent='center'\n  ...attributes\n>\n  <AkStack\n    local-class='loading-container'\n    @alignItems='center'\n    @justifyContent='center'\n    @spacing='1.5'\n  >\n    <AkLoader @size={{16}} />\n    <AkTypography local-class='loading-text' @color='textSecondary'>\n      {{t 'loading'}}...\n    </AkTypography>\n  </AkStack>\n</AkStack>","moduleName":"irene/components/project-settings/loader/index.hbs","parseOptions":{"srcName":"irene/components/project-settings/loader/index.hbs"}});
import Component from '@glimmer/component';

interface ProjectSettingsLoaderSignature {
  Element: HTMLElement;
  Args: null;
}

export default class ProjectSettingsLoaderComponent extends Component<ProjectSettingsLoaderSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ProjectSettings::Loader': typeof ProjectSettingsLoaderComponent;
  }
}
