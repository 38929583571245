import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkStack @width='8/12' @alignItems='center' class='mb-3'>\n  <AkBreadcrumbs::Container>\n    {{#each this.breadcrumbItems as |item|}}\n      <AkBreadcrumbs::Item\n        @route={{item.route}}\n        @model={{item.model}}\n        @linkTitle={{item.linkTitle}}\n      />\n    {{/each}}\n  </AkBreadcrumbs::Container>\n</AkStack>\n\n<AkStack\n  @width='8/12'\n  local-class='header-security-file-search-page-title'\n  @alignItems='center'\n  class='mb-4'\n>\n  <AkStack @direction='column' @spacing='0.5'>\n    <AkTypography @variant='h5' data-test-security-file-search-list-heading>\n      List of Files\n    </AkTypography>\n\n    <AkTypography\n      @variant='body2'\n      data-test-security-file-search-list-description\n    >\n      Here you can view all the files of a selected project.\n    </AkTypography>\n  </AkStack>\n</AkStack>", {"contents":"<AkStack @width='8/12' @alignItems='center' class='mb-3'>\n  <AkBreadcrumbs::Container>\n    {{#each this.breadcrumbItems as |item|}}\n      <AkBreadcrumbs::Item\n        @route={{item.route}}\n        @model={{item.model}}\n        @linkTitle={{item.linkTitle}}\n      />\n    {{/each}}\n  </AkBreadcrumbs::Container>\n</AkStack>\n\n<AkStack\n  @width='8/12'\n  local-class='header-security-file-search-page-title'\n  @alignItems='center'\n  class='mb-4'\n>\n  <AkStack @direction='column' @spacing='0.5'>\n    <AkTypography @variant='h5' data-test-security-file-search-list-heading>\n      List of Files\n    </AkTypography>\n\n    <AkTypography\n      @variant='body2'\n      data-test-security-file-search-list-description\n    >\n      Here you can view all the files of a selected project.\n    </AkTypography>\n  </AkStack>\n</AkStack>","moduleName":"irene/components/security/file-search-list/header/index.hbs","parseOptions":{"srcName":"irene/components/security/file-search-list/header/index.hbs"}});
import Component from '@glimmer/component';
import { AkBreadcrumbsItemSignature } from 'irene/components/ak-breadcrumbs/item';

export default class SecurityFileSearchListHeaderComponent extends Component {
  get breadcrumbItems(): AkBreadcrumbsItemSignature['Args'][] {
    return [
      {
        route: 'authenticated.security.projects',
        linkTitle: 'Projects',
      },
      {
        route: 'authenticated.security.files',
        linkTitle: 'List of Files',
      },
    ];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Security::FileSearchList::Header': typeof SecurityFileSearchListHeaderComponent;
  }
}
