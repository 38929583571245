export default {
  "colored-box": "_colored-box_1qm76g",
  "progress": "_progress_1qm76g",
  "is-warning": "_is-warning_1qm76g",
  "is-info": "_is-info_1qm76g",
  "is-danger": "_is-danger_1qm76g",
  "is-critical": "_is-critical_1qm76g",
  "is-success": "_is-success_1qm76g",
  "is-progress": "_is-progress_1qm76g",
  "is-error": "_is-error_1qm76g"
};
