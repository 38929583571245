import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkTabs as |Akt|>\n  {{#each this.tabs as |item|}}\n    <Akt.tabItem\n      @id={{item.id}}\n      @route={{item.route}}\n      @currentWhen={{item.activeRoutes}}\n    >\n      {{item.label}}\n    </Akt.tabItem>\n  {{/each}}\n</AkTabs>", {"contents":"<AkTabs as |Akt|>\n  {{#each this.tabs as |item|}}\n    <Akt.tabItem\n      @id={{item.id}}\n      @route={{item.route}}\n      @currentWhen={{item.activeRoutes}}\n    >\n      {{item.label}}\n    </Akt.tabItem>\n  {{/each}}\n</AkTabs>","moduleName":"irene/components/account-settings/index.hbs","parseOptions":{"srcName":"irene/components/account-settings/index.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';

export default class AccountSettingsComponent extends Component {
  @service declare intl: IntlService;

  get tabs() {
    return [
      {
        label: this.intl.t('general'),
        route: 'authenticated.dashboard.account-settings.general',
        activeRoutes: 'authenticated.dashboard.account-settings.general',
      },
      {
        label: this.intl.t('security'),
        route: 'authenticated.dashboard.account-settings.security',
        activeRoutes: 'authenticated.dashboard.account-settings.security',
      },
      {
        label: this.intl.t('developerSettings'),
        route: 'authenticated.dashboard.account-settings.developersettings',
        activeRoutes:
          'authenticated.dashboard.account-settings.developersettings',
      },
    ];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    AccountSettings: typeof AccountSettingsComponent;
  }
}
