import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkStack\n  @alignItems='center'\n  @justifyContent='center'\n  @direction='column'\n  @spacing='1'\n  class='py-7 px-4'\n>\n  <AkSvg::ProjectListEmpty data-test-serviceAccount-emptySvg />\n\n  <AkTypography @variant='h5' class='mt-4' data-test-serviceAccount-emptyTitle>\n    {{t 'serviceAccountModule.emptyTitle'}}\n  </AkTypography>\n\n  <AkTypography @align='center' data-test-serviceAccount-emptyDescription>\n    {{t 'serviceAccountModule.emptyDescription' htmlSafe=true}}\n  </AkTypography>\n</AkStack>", {"contents":"<AkStack\n  @alignItems='center'\n  @justifyContent='center'\n  @direction='column'\n  @spacing='1'\n  class='py-7 px-4'\n>\n  <AkSvg::ProjectListEmpty data-test-serviceAccount-emptySvg />\n\n  <AkTypography @variant='h5' class='mt-4' data-test-serviceAccount-emptyTitle>\n    {{t 'serviceAccountModule.emptyTitle'}}\n  </AkTypography>\n\n  <AkTypography @align='center' data-test-serviceAccount-emptyDescription>\n    {{t 'serviceAccountModule.emptyDescription' htmlSafe=true}}\n  </AkTypography>\n</AkStack>","moduleName":"irene/components/organization/service-account/empty/index.hbs","parseOptions":{"srcName":"irene/components/organization/service-account/empty/index.hbs"}});
import Component from '@glimmer/component';

export default class OrganizationServiceAccountEmptyComponent extends Component {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Organization::ServiceAccount::Empty': typeof OrganizationServiceAccountEmptyComponent;
  }
}
