import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkStack\n  @direction='column'\n  @alignItems='center'\n  @justifyContent='center'\n  @spacing='1.5'\n  class='px-3 py-5'\n>\n  <AkSvg::EmptyLoadingList />\n\n  <AkStack @alignItems='center' @spacing='1.5' class='mt-1'>\n    <AkLoader @size={{16}} />\n\n    <AkTypography>\n      {{t 'loading'}}...\n    </AkTypography>\n  </AkStack>\n</AkStack>", {"contents":"<AkStack\n  @direction='column'\n  @alignItems='center'\n  @justifyContent='center'\n  @spacing='1.5'\n  class='px-3 py-5'\n>\n  <AkSvg::EmptyLoadingList />\n\n  <AkStack @alignItems='center' @spacing='1.5' class='mt-1'>\n    <AkLoader @size={{16}} />\n\n    <AkTypography>\n      {{t 'loading'}}...\n    </AkTypography>\n  </AkStack>\n</AkStack>","moduleName":"irene/components/file-details/api-scan/captured-apis/loading/index.hbs","parseOptions":{"srcName":"irene/components/file-details/api-scan/captured-apis/loading/index.hbs"}});
import Component from '@glimmer/component';

export default class FileDetailsApiScanCapturedApisLoadingComponent extends Component {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'FileDetails::ApiScan::CapturedApis::Loading': typeof FileDetailsApiScanCapturedApisLoadingComponent;
  }
}
