export default {
  "notification_header": "_notification_header_zq8kbg",
  "notification_header_line": "_notification_header_line_zq8kbg",
  "notification_title": "_notification_title_zq8kbg",
  "notification_title_area": "_notification_title_area_zq8kbg",
  "notification_settings_area": "_notification_settings_area_zq8kbg",
  "notifications_unread_count": "_notifications_unread_count_zq8kbg",
  "notification_unread_text": "_notification_unread_text_zq8kbg",
  "notification_unread_toggle": "_notification_unread_toggle_zq8kbg",
  "vertical-divider": "_vertical-divider_zq8kbg"
};
