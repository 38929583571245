import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkTypography data-test-sbomComponent-version @tag='span'>\n  {{or @sbomComponent.version '-'}}\n</AkTypography>", {"contents":"<AkTypography data-test-sbomComponent-version @tag='span'>\n  {{or @sbomComponent.version '-'}}\n</AkTypography>","moduleName":"irene/components/sbom/scan-details/component-list/version/index.hbs","parseOptions":{"srcName":"irene/components/sbom/scan-details/component-list/version/index.hbs"}});
import Component from '@glimmer/component';
import SbomComponentModel from 'irene/models/sbom-component';

export interface SbomScanDetailsComponentListVersionSignature {
  Args: {
    sbomComponent: SbomComponentModel;
  };
}

export default class SbomScanDetailsComponentListVersionComponent extends Component<SbomScanDetailsComponentListVersionSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Sbom::ScanDetails::ComponentList::Version': typeof SbomScanDetailsComponentListVersionComponent;
    'sbom/scan-details/component-list/version': typeof SbomScanDetailsComponentListVersionComponent;
  }
}
