import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<ul data-test-ak-list ...attributes local-class='ak-list-root'>\n  {{yield (hash listItem=(component 'ak-list/item'))}}\n</ul>", {"contents":"<ul data-test-ak-list ...attributes local-class='ak-list-root'>\n  {{yield (hash listItem=(component 'ak-list/item'))}}\n</ul>","moduleName":"irene/components/ak-list/index.hbs","parseOptions":{"srcName":"irene/components/ak-list/index.hbs"}});
import Component from '@glimmer/component';
import AkListItemComponent from './item';

export interface AkListSignature {
  Element: HTMLUListElement;
  Blocks: { default: [{ listItem: typeof AkListItemComponent }] };
}

export default class AkListComponent extends Component<AkListSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    AkList: typeof AkListComponent;
  }
}
