import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkTree::Provider\n  @treeData={{@treeData}}\n  @expanded={{@expanded}}\n  @checked={{@checked}}\n  @onCheck={{@onCheck}}\n  @onExpand={{@onExpand}}\n  @cascade={{@cascade}}\n  @disabled={{@disabled}}\n  as |tree|\n>\n  <AkTree::Nodes @tree={{tree}} @treeData={{tree.nodes}} as |node|>\n    {{yield node tree}}\n  </AkTree::Nodes>\n</AkTree::Provider>", {"contents":"<AkTree::Provider\n  @treeData={{@treeData}}\n  @expanded={{@expanded}}\n  @checked={{@checked}}\n  @onCheck={{@onCheck}}\n  @onExpand={{@onExpand}}\n  @cascade={{@cascade}}\n  @disabled={{@disabled}}\n  as |tree|\n>\n  <AkTree::Nodes @tree={{tree}} @treeData={{tree.nodes}} as |node|>\n    {{yield node tree}}\n  </AkTree::Nodes>\n</AkTree::Provider>","moduleName":"irene/components/ak-tree/index.hbs","parseOptions":{"srcName":"irene/components/ak-tree/index.hbs"}});
import Component from '@glimmer/component';

import {
  type AkTreeNodeFlattenedProps,
  type AkTreeNodeProps,
  type AkTreeProviderDefaultBlockProps,
  type AkTreeProviderSignature,
} from 'irene/components/ak-tree/provider';

export interface AkTreeSignature<N extends AkTreeNodeProps>
  extends Omit<AkTreeProviderSignature<N>, 'Blocks'> {
  Blocks: {
    default: [
      node: AkTreeNodeFlattenedProps | undefined,
      tree: AkTreeProviderDefaultBlockProps<N>,
    ];
  };
}

export default class AkTreeComponent<
  N extends AkTreeNodeProps,
> extends Component<AkTreeSignature<N>> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    AkTree: typeof AkTreeComponent;
  }
}
