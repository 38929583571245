import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div local-class='settings-analysis-container'>\n  <div class='py-2 px-3'>\n    <ProjectSettings::AnalysisSettings::ToggleAnalysis\n      @project={{this.project}}\n    />\n  </div>\n\n  <AkDivider @color='dark' />\n\n  <div class='py-2'>\n    <ProjectSettings::AnalysisSettings::RegulatoryPreference\n      @project={{this.project}}\n    />\n  </div>\n\n  <AkDivider @color='dark' />\n\n  <div class='py-2'>\n    <ProjectSettings::AnalysisSettings::ReportPreference\n      @project={{this.project}}\n    />\n  </div>\n\n  <AkDivider @color='dark' />\n\n  <div class='py-2 px-3'>\n    <ProjectSettings::AnalysisSettings::VulnerabilityList\n      @project={{this.project}}\n    />\n  </div>\n</div>", {"contents":"<div local-class='settings-analysis-container'>\n  <div class='py-2 px-3'>\n    <ProjectSettings::AnalysisSettings::ToggleAnalysis\n      @project={{this.project}}\n    />\n  </div>\n\n  <AkDivider @color='dark' />\n\n  <div class='py-2'>\n    <ProjectSettings::AnalysisSettings::RegulatoryPreference\n      @project={{this.project}}\n    />\n  </div>\n\n  <AkDivider @color='dark' />\n\n  <div class='py-2'>\n    <ProjectSettings::AnalysisSettings::ReportPreference\n      @project={{this.project}}\n    />\n  </div>\n\n  <AkDivider @color='dark' />\n\n  <div class='py-2 px-3'>\n    <ProjectSettings::AnalysisSettings::VulnerabilityList\n      @project={{this.project}}\n    />\n  </div>\n</div>","moduleName":"irene/components/project-settings/analysis-settings/index.hbs","parseOptions":{"srcName":"irene/components/project-settings/analysis-settings/index.hbs"}});
import Component from '@glimmer/component';
import ProjectModel from 'irene/models/project';

interface ProjectSettingsAnalysisSettingsSignature {
  Args: {
    project: ProjectModel | null;
  };
}

export default class ProjectSettingsAnalysisSettingsComponent extends Component<ProjectSettingsAnalysisSettingsSignature> {
  get project() {
    return this.args.project;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ProjectSettings::AnalysisSettings': typeof ProjectSettingsAnalysisSettingsComponent;
  }
}
