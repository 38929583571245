import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div local-class='bordered-box'>\n  <AkTabs local-class='tabs-container' as |akt|>\n    {{#each this.tabItems as |item|}}\n      <akt.tabItem\n        @id={{item.id}}\n        @buttonVariant={{true}}\n        @onTabClick={{this.handleTabClick}}\n        @isActive={{eq item.id this.selectedTab}}\n      >\n        {{item.label}}\n      </akt.tabItem>\n    {{/each}}\n  </AkTabs>\n\n  {{#let (component this.activeTabComponent) as |Component|}}\n    <Component @file={{@file}} />\n  {{/let}}\n</div>", {"contents":"<div local-class='bordered-box'>\n  <AkTabs local-class='tabs-container' as |akt|>\n    {{#each this.tabItems as |item|}}\n      <akt.tabItem\n        @id={{item.id}}\n        @buttonVariant={{true}}\n        @onTabClick={{this.handleTabClick}}\n        @isActive={{eq item.id this.selectedTab}}\n      >\n        {{item.label}}\n      </akt.tabItem>\n    {{/each}}\n  </AkTabs>\n\n  {{#let (component this.activeTabComponent) as |Component|}}\n    <Component @file={{@file}} />\n  {{/let}}\n</div>","moduleName":"irene/components/file-details/insights-wrapper/index.hbs","parseOptions":{"srcName":"irene/components/file-details/insights-wrapper/index.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';

import FileModel from 'irene/models/file';

export interface FileDetailsInsightsWrapperSignature {
  Args: {
    file: FileModel;
  };
}

export default class FileDetailsInsightsWrapperComponent extends Component<FileDetailsInsightsWrapperSignature> {
  @service declare intl: IntlService;

  @tracked selectedTab = 'scan_summary';

  get tabItems() {
    return [
      {
        id: 'scan_summary',
        label: this.intl.t('scanSummary'),
        component: 'file-details/scan-summary' as const,
      },
      {
        id: 'compliance_insights',
        label: this.intl.t('owaspDetails'),
        component: 'file-details/compliance-insights' as const,
      },
    ];
  }

  get activeTabComponent() {
    return this.tabItems.find((t) => t.id === this.selectedTab)?.component;
  }

  @action
  handleTabClick(id: string) {
    this.selectedTab = id;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'FileDetails::InsightsWrapper': typeof FileDetailsInsightsWrapperComponent;
  }
}
