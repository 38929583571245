/* eslint-disable ember/use-ember-data-rfc-395-imports */
import { ModelSchema } from 'ember-data';
import commondrf from './commondrf';
import Store from '@ember-data/store';
import ModelRegistry from 'ember-data/types/registries/model';

export default class VulnerabilityPreferenceAdapter extends commondrf {
  query<K extends keyof ModelRegistry>(
    store: Store,
    type: ModelSchema<K>,
    q: { id: string | number }
  ) {
    const url = this.buildURLFromBase(
      `${this.namespace}/profiles/${q.id}/vulnerability_preferences`
    );

    return this.ajax(url, 'GET');
  }
}

declare module 'ember-data/types/registries/adapter' {
  export default interface AdapterRegistry {
    'vulnerability-preference': VulnerabilityPreferenceAdapter;
  }
}
