import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkTypography @color='textSecondary'>\n  {{dayjs-from-now @invitation.createdOn}}\n</AkTypography>", {"contents":"<AkTypography @color='textSecondary'>\n  {{dayjs-from-now @invitation.createdOn}}\n</AkTypography>","moduleName":"irene/components/organization-invitation-list/invited-on/index.hbs","parseOptions":{"srcName":"irene/components/organization-invitation-list/invited-on/index.hbs"}});
import Component from '@glimmer/component';
import OrganizationInvitationModel from 'irene/models/organization-invitation';
import OrganizationTeamInvitationModel from 'irene/models/organization-team-invitation';

export interface OrganizationInvitationListInvitedOnSignature {
  Args: {
    invitation: OrganizationInvitationModel | OrganizationTeamInvitationModel;
  };
  Element: HTMLElement;
}

export default class OrganizationInvitationListInvitedOn extends Component<OrganizationInvitationListInvitedOnSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'organization-invitation-list/invited-on': typeof OrganizationInvitationListInvitedOn;
    'OrganizationInvitationList::InvitedOn': typeof OrganizationInvitationListInvitedOn;
  }
}
