export default {
  "analysis-content-title": "_analysis-content-title_1qs6rd",
  "clipboard-button": "_clipboard-button_1qs6rd",
  "vulnerability-finding-container": "_vulnerability-finding-container_1qs6rd",
  "vulnerability-finding-description": "_vulnerability-finding-description_1qs6rd",
  "vulnerability-finding-header": "_vulnerability-finding-header_1qs6rd",
  "pre-wrap": "_pre-wrap_1qs6rd",
  "pre-line": "_pre-line_1qs6rd",
  "analysis-overridded-passed": "_analysis-overridded-passed_1qs6rd"
};
