import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#let (or this.project.lastFileId.content null) as |lastFile|}}\n  <LinkTo\n    @route='authenticated.dashboard.file'\n    @model={{lastFile.id}}\n    local-class='project-card'\n    data-test-project-overview-container\n  >\n    <FileOverview\n      ...attributes\n      @file={{lastFile}}\n      @profileId={{this.project.activeProfileId}}\n      @hideCTAs={{true}}\n      @showMenuButton={{true}}\n    />\n  </LinkTo>\n{{/let}}", {"contents":"{{#let (or this.project.lastFileId.content null) as |lastFile|}}\n  <LinkTo\n    @route='authenticated.dashboard.file'\n    @model={{lastFile.id}}\n    local-class='project-card'\n    data-test-project-overview-container\n  >\n    <FileOverview\n      ...attributes\n      @file={{lastFile}}\n      @profileId={{this.project.activeProfileId}}\n      @hideCTAs={{true}}\n      @showMenuButton={{true}}\n    />\n  </LinkTo>\n{{/let}}","moduleName":"irene/components/project-overview/index.hbs","parseOptions":{"srcName":"irene/components/project-overview/index.hbs"}});
import Component from '@glimmer/component';
import ProjectModel from 'irene/models/project';

interface ProjectOverviewSignature {
  Element: HTMLElement;
  Args: { project: ProjectModel };
}

export default class ProjectOverviewComponent extends Component<ProjectOverviewSignature> {
  get project() {
    return this.args.project || null;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    ProjectOverview: typeof ProjectOverviewComponent;
  }
}
