import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div local-class='dast-root'>\n  <div local-class='dast-container'>\n    {{yield}}\n  </div>\n</div>", {"contents":"<div local-class='dast-root'>\n  <div local-class='dast-container'>\n    {{yield}}\n  </div>\n</div>","moduleName":"irene/components/file-details/dynamic-scan/page-wrapper/index.hbs","parseOptions":{"srcName":"irene/components/file-details/dynamic-scan/page-wrapper/index.hbs"}});
import Component from '@glimmer/component';

export interface FileDetailsDynamicScanPageWrapperComponentSignature {
  Blocks: {
    default: [];
  };
}

export default class FileDetailsDynamicScanPageWrapperComponent extends Component<FileDetailsDynamicScanPageWrapperComponentSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'FileDetails::DynamicScan::PageWrapper': typeof FileDetailsDynamicScanPageWrapperComponent;
  }
}
