export default {
  "analysis-risk-tag": "_analysis-risk-tag_eoiay5",
  "analysis-risk-tag-label": "_analysis-risk-tag-label_eoiay5",
  "analysis-risk-overridden-icon": "_analysis-risk-overridden-icon_eoiay5",
  "tooltip-content-text": "_tooltip-content-text_eoiay5",
  "tooltip-content-icon": "_tooltip-content-icon_eoiay5",
  "is-critical": "_is-critical_eoiay5",
  "is-danger": "_is-danger_eoiay5",
  "is-warning": "_is-warning_eoiay5",
  "is-info": "_is-info_eoiay5",
  "is-success": "_is-success_eoiay5",
  "is-default": "_is-default_eoiay5",
  "is-waiting": "_is-waiting_eoiay5",
  "is-progress": "_is-progress_eoiay5",
  "is-errored": "_is-errored_eoiay5"
};
