export default {
  "ak-stack-direction-row": "_ak-stack-direction-row_har3vp",
  "ak-stack-direction-row-reverse": "_ak-stack-direction-row-reverse_har3vp",
  "ak-stack-direction-column": "_ak-stack-direction-column_har3vp",
  "ak-stack-direction-column-reverse": "_ak-stack-direction-column-reverse_har3vp",
  "ak-stack-alignitems-flex-start": "_ak-stack-alignitems-flex-start_har3vp",
  "ak-stack-alignitems-center": "_ak-stack-alignitems-center_har3vp",
  "ak-stack-alignitems-flex-end": "_ak-stack-alignitems-flex-end_har3vp",
  "ak-stack-alignitems-stretch": "_ak-stack-alignitems-stretch_har3vp",
  "ak-stack-justifycontent-start": "_ak-stack-justifycontent-start_har3vp",
  "ak-stack-justifycontent-flex-start": "_ak-stack-justifycontent-flex-start_har3vp",
  "ak-stack-justifycontent-center": "_ak-stack-justifycontent-center_har3vp",
  "ak-stack-justifycontent-stretch": "_ak-stack-justifycontent-stretch_har3vp",
  "ak-stack-justifycontent-normal": "_ak-stack-justifycontent-normal_har3vp",
  "ak-stack-justifycontent-space-between": "_ak-stack-justifycontent-space-between_har3vp",
  "ak-stack-justifycontent-space-around": "_ak-stack-justifycontent-space-around_har3vp",
  "ak-stack-justifycontent-space-evenly": "_ak-stack-justifycontent-space-evenly_har3vp",
  "ak-stack-justifycontent-flex-end": "_ak-stack-justifycontent-flex-end_har3vp",
  "ak-stack-justifycontent-right": "_ak-stack-justifycontent-right_har3vp",
  "ak-stack-justifycontent-left": "_ak-stack-justifycontent-left_har3vp",
  "ak-stack-justifycontent-end": "_ak-stack-justifycontent-end_har3vp",
  "ak-stack-flexwrap-nowrap": "_ak-stack-flexwrap-nowrap_har3vp",
  "ak-stack-flexwrap-wrap": "_ak-stack-flexwrap-wrap_har3vp",
  "ak-stack-flexwrap-wrap-reverse": "_ak-stack-flexwrap-wrap-reverse_har3vp",
  "ak-stack-spacing-0": "_ak-stack-spacing-0_har3vp",
  "ak-stack-spacing-1/2": "_ak-stack-spacing-1/2_har3vp",
  "ak-stack-spacing-1": "_ak-stack-spacing-1_har3vp",
  "ak-stack-spacing-3/2": "_ak-stack-spacing-3/2_har3vp",
  "ak-stack-spacing-2": "_ak-stack-spacing-2_har3vp",
  "ak-stack-spacing-5/2": "_ak-stack-spacing-5/2_har3vp",
  "ak-stack-spacing-3": "_ak-stack-spacing-3_har3vp",
  "ak-stack-spacing-7/2": "_ak-stack-spacing-7/2_har3vp",
  "ak-stack-spacing-4": "_ak-stack-spacing-4_har3vp",
  "ak-stack-spacing-9/2": "_ak-stack-spacing-9/2_har3vp",
  "ak-stack-spacing-5": "_ak-stack-spacing-5_har3vp",
  "ak-stack-spacing-11/2": "_ak-stack-spacing-11/2_har3vp",
  "ak-stack-spacing-6": "_ak-stack-spacing-6_har3vp",
  "ak-stack-width-1/12": "_ak-stack-width-1/12_har3vp",
  "ak-stack-width-2/12": "_ak-stack-width-2/12_har3vp",
  "ak-stack-width-3/12": "_ak-stack-width-3/12_har3vp",
  "ak-stack-width-4/12": "_ak-stack-width-4/12_har3vp",
  "ak-stack-width-5/12": "_ak-stack-width-5/12_har3vp",
  "ak-stack-width-6/12": "_ak-stack-width-6/12_har3vp",
  "ak-stack-width-7/12": "_ak-stack-width-7/12_har3vp",
  "ak-stack-width-8/12": "_ak-stack-width-8/12_har3vp",
  "ak-stack-width-9/12": "_ak-stack-width-9/12_har3vp",
  "ak-stack-width-10/12": "_ak-stack-width-10/12_har3vp",
  "ak-stack-width-11/12": "_ak-stack-width-11/12_har3vp",
  "ak-stack-width-12/12": "_ak-stack-width-12/12_har3vp",
  "ak-stack-width-full": "_ak-stack-width-full_har3vp",
  "ak-stack-width-fit-content": "_ak-stack-width-fit-content_har3vp",
  "ak-stack-width-auto": "_ak-stack-width-auto_har3vp"
};
