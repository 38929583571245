import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkStack @alignItems='center' @spacing='1.5'>\n  <AppPlatformIcon @platform='{{@project.platformIconClass}}' />\n\n  <AkTypography @noWrap={{true}}>\n    {{@project.packageName}}\n  </AkTypography>\n</AkStack>", {"contents":"<AkStack @alignItems='center' @spacing='1.5'>\n  <AppPlatformIcon @platform='{{@project.platformIconClass}}' />\n\n  <AkTypography @noWrap={{true}}>\n    {{@project.packageName}}\n  </AkTypography>\n</AkStack>","moduleName":"irene/components/organization/service-account/add-project/list/name/index.hbs","parseOptions":{"srcName":"irene/components/organization/service-account/add-project/list/name/index.hbs"}});
import Component from '@glimmer/component';
import type ProjectModel from 'irene/models/project';

export interface OrganizationServiceAccountAddProjectListNameSignature {
  Args: {
    project: ProjectModel;
  };
}

export default class OrganizationServiceAccountAddProjectListNameComponent extends Component<OrganizationServiceAccountAddProjectListNameSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'organization/service-account/add-project/list/name': typeof OrganizationServiceAccountAddProjectListNameComponent;
  }
}
