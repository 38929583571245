import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkStack\n  @spacing='0.5'\n  @alignItems='center'\n  data-test-securityAnalysisListTable-selectedScanType\n>\n  <AkStack @spacing='1' @alignItems='center'>\n    <AkIcon @iconName={{this.iconName}} @size='small' @color='primary' />\n\n    {{#if this.showLabel}}\n      <AkTypography local-class='trigger-label'>\n        {{this.optionTitle}}\n        -\n      </AkTypography>\n    {{/if}}\n  </AkStack>\n\n  <AkTypography @fontWeight='medium' local-class='trigger-label'>\n    {{#if (eq this.selectedItem -1)}}\n      {{t 'allScans'}}\n    {{else}}\n      {{t (vulnerability-type this.selectedItem)}}\n    {{/if}}\n  </AkTypography>\n</AkStack>", {"contents":"<AkStack\n  @spacing='0.5'\n  @alignItems='center'\n  data-test-securityAnalysisListTable-selectedScanType\n>\n  <AkStack @spacing='1' @alignItems='center'>\n    <AkIcon @iconName={{this.iconName}} @size='small' @color='primary' />\n\n    {{#if this.showLabel}}\n      <AkTypography local-class='trigger-label'>\n        {{this.optionTitle}}\n        -\n      </AkTypography>\n    {{/if}}\n  </AkStack>\n\n  <AkTypography @fontWeight='medium' local-class='trigger-label'>\n    {{#if (eq this.selectedItem -1)}}\n      {{t 'allScans'}}\n    {{else}}\n      {{t (vulnerability-type this.selectedItem)}}\n    {{/if}}\n  </AkTypography>\n</AkStack>","moduleName":"irene/components/security/analysis-list/filter-selected-item/index.hbs","parseOptions":{"srcName":"irene/components/security/analysis-list/filter-selected-item/index.hbs"}});
import Component from '@glimmer/component';

interface SecurityAnalysisListFilterSelectedItemArgs {
  extra?: Record<
    'optionTitle' | 'selectedItem' | 'showLabel' | 'iconName',
    unknown
  >;
}

export default class SecurityAnalysisListFilterSelectedItemComponent extends Component<SecurityAnalysisListFilterSelectedItemArgs> {
  get optionTitle() {
    return this.args.extra?.optionTitle as string;
  }

  get selectedItem() {
    return this.args.extra?.selectedItem as string;
  }

  get showLabel() {
    return this.args.extra?.showLabel as boolean;
  }

  get iconName() {
    return this.args.extra?.iconName as string;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Security::AnalysisList::FilterSelectedItem': typeof SecurityAnalysisListFilterSelectedItemComponent;
    'security/analysis-list/filter-selected-item': typeof SecurityAnalysisListFilterSelectedItemComponent;
  }
}
