export default {
  "analysis-details-root": "_analysis-details-root_1nv92z",
  "analysis-details-header": "_analysis-details-header_1nv92z",
  "analysis-details-header-root": "_analysis-details-header-root_1nv92z",
  "analysis-details-header-container": "_analysis-details-header-container_1nv92z",
  "analysis-details-body-container": "_analysis-details-body-container_1nv92z",
  "analysis-section": "_analysis-section_1nv92z",
  "analysis-overridded-passed": "_analysis-overridded-passed_1nv92z",
  "analysis-content-title-container": "_analysis-content-title-container_1nv92z",
  "analysis-static-content": "_analysis-static-content_1nv92z",
  "analysis-regulatory-section": "_analysis-regulatory-section_1nv92z",
  "analysis-regulatory-content": "_analysis-regulatory-content_1nv92z",
  "analysis-section-container": "_analysis-section-container_1nv92z",
  "analysis-content-container": "_analysis-content-container_1nv92z",
  "analysis-content-label": "_analysis-content-label_1nv92z",
  "analysis-content-value": "_analysis-content-value_1nv92z"
};
