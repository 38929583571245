export default {
  "ak-icon-root": "_ak-icon-root_1c2k6u",
  "ak-icon-color-primary": "_ak-icon-color-primary_1c2k6u",
  "ak-icon-color-secondary": "_ak-icon-color-secondary_1c2k6u",
  "ak-icon-color-textPrimary": "_ak-icon-color-textPrimary_1c2k6u",
  "ak-icon-color-textSecondary": "_ak-icon-color-textSecondary_1c2k6u",
  "ak-icon-color-error": "_ak-icon-color-error_1c2k6u",
  "ak-icon-color-success": "_ak-icon-color-success_1c2k6u",
  "ak-icon-color-warn": "_ak-icon-color-warn_1c2k6u",
  "ak-icon-color-info": "_ak-icon-color-info_1c2k6u",
  "ak-icon-color-inherit": "_ak-icon-color-inherit_1c2k6u",
  "ak-icon-size-small": "_ak-icon-size-small_1c2k6u",
  "ak-icon-size-medium": "_ak-icon-size-medium_1c2k6u"
};
