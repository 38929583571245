export default {
  "ak-icon-button-root": "_ak-icon-button-root_1zo6qj",
  "ak-icon-button-variant-outlined": "_ak-icon-button-variant-outlined_1zo6qj",
  "ak-icon-button-border-color-default": "_ak-icon-button-border-color-default_1zo6qj",
  "ak-icon-button-border-color-primary": "_ak-icon-button-border-color-primary_1zo6qj",
  "ak-icon-button-border-color-secondary": "_ak-icon-button-border-color-secondary_1zo6qj",
  "ak-icon-button-variant-default": "_ak-icon-button-variant-default_1zo6qj",
  "ak-icon-button-size-small": "_ak-icon-button-size-small_1zo6qj",
  "ak-icon-button-size-medium": "_ak-icon-button-size-medium_1zo6qj"
};
