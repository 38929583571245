import Model, { attr } from '@ember-data/model';
import { inject as service } from '@ember/service';
import IntlService from 'ember-intl/services/intl';

export enum VulnerabilitySeverity {
  UNKNOWN = 1,
  NONE = 2,
  INFO = 3,
  LOW = 4,
  MEDIUM = 5,
  HIGH = 6,
  CRITICAL = 7,
}

export interface SbomVulnerabilityRating {
  source: {
    url: string;
    name: string;
  };
  score?: number;
  severity?: VulnerabilitySeverity;
  method: string;
  vector: string;
  justification: string;
}

export default class SbomVulnerabilityModel extends Model {
  @service declare intl: IntlService;

  @attr('string')
  declare vulnerabilityId: string;

  @attr('string')
  declare description: string;

  @attr('string')
  declare detail: string;

  @attr('string')
  declare recommendation: string;

  @attr('string')
  declare sourceName: string;

  @attr('string')
  declare sourceUrl: string;

  @attr()
  declare ratings: SbomVulnerabilityRating[];

  @attr('string')
  declare score: string;

  @attr('number')
  declare severity: VulnerabilitySeverity;

  get severityDisplayValue() {
    switch (this.severity) {
      case VulnerabilitySeverity.CRITICAL:
        return this.intl.t('critical');

      case VulnerabilitySeverity.HIGH:
        return this.intl.t('high');

      case VulnerabilitySeverity.MEDIUM:
        return this.intl.t('medium');

      case VulnerabilitySeverity.LOW:
        return this.intl.t('low');

      case VulnerabilitySeverity.INFO:
        return this.intl.t('info');

      case VulnerabilitySeverity.NONE:
        return this.intl.t('none');

      case VulnerabilitySeverity.UNKNOWN:
        return this.intl.t('unknown');

      default:
        return this.intl.t('unknown');
    }
  }

  get severityClass() {
    return (
      VulnerabilitySeverity[this.severity] ||
      VulnerabilitySeverity[VulnerabilitySeverity.UNKNOWN]
    ).toLowerCase();
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'sbom-vulnerability': SbomVulnerabilityModel;
  }
}
