import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkTypography data-test-sbomApp-lastAnalysedOn>\n  {{#if @sbomProject.latestSbFile}}\n    {{this.lastAnalysedOn}}\n  {{else}}\n    -\n  {{/if}}\n</AkTypography>", {"contents":"<AkTypography data-test-sbomApp-lastAnalysedOn>\n  {{#if @sbomProject.latestSbFile}}\n    {{this.lastAnalysedOn}}\n  {{else}}\n    -\n  {{/if}}\n</AkTypography>","moduleName":"irene/components/sbom/app-list/last-analysed-on/index.hbs","parseOptions":{"srcName":"irene/components/sbom/app-list/last-analysed-on/index.hbs"}});
import Component from '@glimmer/component';
import SbomProjectModel from 'irene/models/sbom-project';
import dayjs from 'dayjs';

export interface SbomProjectListLastAnalysedOnSignature {
  Args: {
    sbomProject: SbomProjectModel;
  };
}

export default class SbomProjectListLastAnalysedOnComponent extends Component<SbomProjectListLastAnalysedOnSignature> {
  get lastAnalysedOn() {
    const completedAt = this.args.sbomProject.latestSbFile?.get('completedAt');

    if (completedAt) {
      return dayjs(completedAt).format('DD MMM YYYY');
    }

    return '-';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Sbom::AppList::LastAnalysedOn': typeof SbomProjectListLastAnalysedOnComponent;
    'sbom/app-list/last-analysed-on': typeof SbomProjectListLastAnalysedOnComponent;
  }
}
