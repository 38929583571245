import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkStack @direction='column' @spacing='3' class='p-3'>\n  <FileDetails::SeverityLevel @file={{@file}} />\n\n  <FileDetails::KeyInsights @file={{@file}} />\n</AkStack>", {"contents":"<AkStack @direction='column' @spacing='3' class='p-3'>\n  <FileDetails::SeverityLevel @file={{@file}} />\n\n  <FileDetails::KeyInsights @file={{@file}} />\n</AkStack>","moduleName":"irene/components/file-details/scan-summary/index.hbs","parseOptions":{"srcName":"irene/components/file-details/scan-summary/index.hbs"}});
import Component from '@glimmer/component';
import FileModel from 'irene/models/file';

export interface FileDetailsScanSummarySignature {
  Args: {
    file: FileModel;
  };
}

export default class FileDetailsScanSummaryComponent extends Component<FileDetailsScanSummarySignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'FileDetails::ScanSummary': typeof FileDetailsScanSummaryComponent;
    'file-details/scan-summary': typeof FileDetailsScanSummaryComponent;
  }
}
