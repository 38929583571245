import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if @archive.isAvailable}}\n  <AkTypography @align='center'>\n    {{t 'expires'}}\n    {{this.availableUntil}}\n  </AkTypography>\n{{/if}}\n\n{{#if @archive.isExpired}}\n  <AkTypography @align='center'>\n    {{t 'expired'}}\n  </AkTypography>\n{{/if}}\n\n{{#if @archive.isInProgress}}\n  <AkStack @justifyContent='center' @alignItems='center' @spacing='1.5'>\n    <AkLoader @size={{14}} />\n    <AkTypography>\n      {{@archive.progressPercent}}%\n    </AkTypography>\n  </AkStack>\n{{/if}}", {"contents":"{{#if @archive.isAvailable}}\n  <AkTypography @align='center'>\n    {{t 'expires'}}\n    {{this.availableUntil}}\n  </AkTypography>\n{{/if}}\n\n{{#if @archive.isExpired}}\n  <AkTypography @align='center'>\n    {{t 'expired'}}\n  </AkTypography>\n{{/if}}\n\n{{#if @archive.isInProgress}}\n  <AkStack @justifyContent='center' @alignItems='center' @spacing='1.5'>\n    <AkLoader @size={{14}} />\n    <AkTypography>\n      {{@archive.progressPercent}}%\n    </AkTypography>\n  </AkStack>\n{{/if}}","moduleName":"irene/components/organization-archive/list/status/index.hbs","parseOptions":{"srcName":"irene/components/organization-archive/list/status/index.hbs"}});
import Component from '@glimmer/component';
import relativeTime from 'dayjs/plugin/relativeTime';
import dayjs from 'dayjs';

import type OrganizationArchiveModel from 'irene/models/organization-archive';

export interface OrganizationArchiveListStatusSignature {
  Args: {
    archive: OrganizationArchiveModel;
  };
}

dayjs.extend(relativeTime);

export default class OrganizationArchiveListStatusComponent extends Component<OrganizationArchiveListStatusSignature> {
  get availableUntil() {
    return dayjs(this.args.archive.availableUntil).fromNow();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'OrganizationArchive::List::Status': typeof OrganizationArchiveListStatusComponent;
    'organization-archive/list/status': typeof OrganizationArchiveListStatusComponent;
  }
}
