import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkTypography data-test-sbomScan-generatedOn>\n  {{this.generatedOn}}\n</AkTypography>", {"contents":"<AkTypography data-test-sbomScan-generatedOn>\n  {{this.generatedOn}}\n</AkTypography>","moduleName":"irene/components/sbom/app-scan/list/generated-on/index.hbs","parseOptions":{"srcName":"irene/components/sbom/app-scan/list/generated-on/index.hbs"}});
import Component from '@glimmer/component';
import dayjs from 'dayjs';

import SbomFileModel from 'irene/models/sbom-file';

export interface SbomAppScanListGeneratedOnSignature {
  Args: {
    sbomFile: SbomFileModel;
  };
}

export default class SbomAppScanListGeneratedOnComponent extends Component<SbomAppScanListGeneratedOnSignature> {
  get generatedOn() {
    const completedAt = this.args.sbomFile.completedAt;

    return completedAt ? dayjs(completedAt).format('DD MMM YYYY') : '-';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Sbom::AppScan::List::GeneratedOn': typeof SbomAppScanListGeneratedOnComponent;
    'sbom/app-scan/list/generated-on': typeof SbomAppScanListGeneratedOnComponent;
  }
}
