import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AnalysisRisk::Tag\n  @computedRisk={{@analysis.computedRisk}}\n  @status={{@analysis.status}}\n  @isOverridden={{@analysis.isNonPassedRiskOverridden}}\n  @overriddenRisk={{@analysis.overriddenRisk}}\n  @originalRisk={{@analysis.risk}}\n/>", {"contents":"<AnalysisRisk::Tag\n  @computedRisk={{@analysis.computedRisk}}\n  @status={{@analysis.status}}\n  @isOverridden={{@analysis.isNonPassedRiskOverridden}}\n  @overriddenRisk={{@analysis.overriddenRisk}}\n  @originalRisk={{@analysis.risk}}\n/>","moduleName":"irene/components/file-details/vulnerability-analysis/impact/index.hbs","parseOptions":{"srcName":"irene/components/file-details/vulnerability-analysis/impact/index.hbs"}});
import Component from '@glimmer/component';
import AnalysisModel from 'irene/models/analysis';

export interface FileDetailsVulnerabilityAnalysisImpactSignature {
  Args: {
    analysis: AnalysisModel;
  };
}

export default class FileDetailsVulnerabilityAnalysisImpactComponent extends Component<FileDetailsVulnerabilityAnalysisImpactSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'file-details/vulnerability-analysis/impact': typeof FileDetailsVulnerabilityAnalysisImpactComponent;
  }
}
