import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkStack\n  @direction='column'\n  @width='full'\n  local-class='file-details-root'\n  class='px-3'\n>\n  <AkBreadcrumbs::Container>\n    {{#each this.breadcrumbItems as |item|}}\n      <AkBreadcrumbs::Item\n        @route={{item.route}}\n        @model={{item.model}}\n        @linkTitle={{item.linkTitle}}\n      />\n    {{/each}}\n  </AkBreadcrumbs::Container>\n\n  <Security::FileDetailsActions @file={{@file}} />\n\n  <Security::AnalysisList @file={{@file}} />\n</AkStack>", {"contents":"<AkStack\n  @direction='column'\n  @width='full'\n  local-class='file-details-root'\n  class='px-3'\n>\n  <AkBreadcrumbs::Container>\n    {{#each this.breadcrumbItems as |item|}}\n      <AkBreadcrumbs::Item\n        @route={{item.route}}\n        @model={{item.model}}\n        @linkTitle={{item.linkTitle}}\n      />\n    {{/each}}\n  </AkBreadcrumbs::Container>\n\n  <Security::FileDetailsActions @file={{@file}} />\n\n  <Security::AnalysisList @file={{@file}} />\n</AkStack>","moduleName":"irene/components/security/file-details/index.hbs","parseOptions":{"srcName":"irene/components/security/file-details/index.hbs"}});
import Component from '@glimmer/component';
import type SecurityFileModel from 'irene/models/security/file';

export interface SecurityFileDetailsComponentSignature {
  Args: {
    file: SecurityFileModel;
  };
}

export default class SecurityFileDetailsComponent extends Component<SecurityFileDetailsComponentSignature> {
  get file() {
    return this.args.file;
  }

  get breadcrumbItems() {
    return [
      {
        route: 'authenticated.security.projects',
        linkTitle: 'All Projects',
      },
      this.file?.project?.get('id')
        ? {
            route: 'authenticated.security.files',
            linkTitle: 'List of Files',
            model: this.file?.project?.get('id'),
          }
        : null,
      {
        route: 'authenticated.security.file',
        linkTitle: 'File Details',
      },
    ].filter(Boolean);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Security::FileDetails': typeof SecurityFileDetailsComponent;
  }
}
