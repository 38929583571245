import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkRadio\n  @checked={{this.checked}}\n  @onChange={{this.handleDevicePrefSelect}}\n  data-test-fileDetails-dynamicScanDrawer-devicePrefTable-deviceSelectRadioInput\n/>", {"contents":"<AkRadio\n  @checked={{this.checked}}\n  @onChange={{this.handleDevicePrefSelect}}\n  data-test-fileDetails-dynamicScanDrawer-devicePrefTable-deviceSelectRadioInput\n/>","moduleName":"irene/components/file-details/dynamic-scan/action/drawer/device-pref-table/selected-device/index.hbs","parseOptions":{"srcName":"irene/components/file-details/dynamic-scan/action/drawer/device-pref-table/selected-device/index.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import type ProjectAvailableDeviceModel from 'irene/models/project-available-device';

export interface FileDetailsDynamicScanDrawerDevicePrefTableSelectedDeviceSignature {
  Args: {
    deviceProps: ProjectAvailableDeviceModel;
    selectedDeviceId?: string;
    onDeviceClick(device: ProjectAvailableDeviceModel): void;
  };
}

export default class FileDetailsDynamicScanDrawerDevicePrefTableSelectedDeviceComponent extends Component<FileDetailsDynamicScanDrawerDevicePrefTableSelectedDeviceSignature> {
  get devicePrefProps() {
    return this.args.deviceProps;
  }

  get checked() {
    return this.devicePrefProps.deviceIdentifier === this.args.selectedDeviceId;
  }

  @action handleDevicePrefSelect() {
    this.args.onDeviceClick(this.devicePrefProps);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'file-details/dynamic-scan/action/drawer/device-pref-table/selected-device': typeof FileDetailsDynamicScanDrawerDevicePrefTableSelectedDeviceComponent;
  }
}
