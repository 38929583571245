import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkStack @direction='column' @spacing='4' class='p-2'>\n  <AccountSettings::Security::PasswordChange />\n\n  <AkDivider @color='dark' />\n\n  <AccountSettings::Security::MultiFactorAuth @user={{@user}} />\n</AkStack>", {"contents":"<AkStack @direction='column' @spacing='4' class='p-2'>\n  <AccountSettings::Security::PasswordChange />\n\n  <AkDivider @color='dark' />\n\n  <AccountSettings::Security::MultiFactorAuth @user={{@user}} />\n</AkStack>","moduleName":"irene/components/account-settings/security/index.hbs","parseOptions":{"srcName":"irene/components/account-settings/security/index.hbs"}});
import Component from '@glimmer/component';
import UserModel from 'irene/models/user';

export interface AccountSettingsSecuritySignature {
  Args: {
    user: UserModel;
  };
}

export default class AccountSettingsSecurityComponent extends Component<AccountSettingsSecuritySignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AccountSettings::Security': typeof AccountSettingsSecurityComponent;
  }
}
