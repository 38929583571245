export default {
  "meta-info": "_meta-info_10aey8",
  "pipe": "_pipe_10aey8",
  "vulnerability-stats": "_vulnerability-stats_10aey8",
  "severity-levels": "_severity-levels_10aey8",
  "severity-level": "_severity-level_10aey8",
  "severity-label": "_severity-label_10aey8",
  "severity-color": "_severity-color_10aey8",
  "severity-color--critical": "_severity-color--critical_10aey8",
  "severity-color--high": "_severity-color--high_10aey8",
  "severity-color--medium": "_severity-color--medium_10aey8",
  "severity-color--low": "_severity-color--low_10aey8",
  "severity-color--passed": "_severity-color--passed_10aey8",
  "severity-color--untested": "_severity-color--untested_10aey8"
};
