import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkStack\n  @alignItems='center'\n  @justifyContent='center'\n  @spacing='1'\n  data-test-amVersionTable-actionColumnHeader\n>\n  <AkTypography @fontWeight='medium'>{{t 'action'}}</AkTypography>\n\n  <AkTooltip\n    @color='dark'\n    @placement='top-start'\n    @arrow={{true}}\n    data-test-amVersionTable-actionColumnHeader-tooltip\n  >\n    <:tooltipContent>\n      <div\n        local-class='tooltip-content'\n        data-test-amVersionTable-actionColumnHeader-tooltipContent\n      >\n        <AkTypography @color='inherit'>\n          {{t 'appMonitoringModule.actionsInfo'}}\n        </AkTypography>\n      </div>\n    </:tooltipContent>\n\n    <:default>\n      <AkIcon\n        @iconName='info'\n        @size='small'\n        data-test-amVersionTable-actionColumnHeader-tooltipIcon\n      />\n    </:default>\n  </AkTooltip>\n</AkStack>", {"contents":"<AkStack\n  @alignItems='center'\n  @justifyContent='center'\n  @spacing='1'\n  data-test-amVersionTable-actionColumnHeader\n>\n  <AkTypography @fontWeight='medium'>{{t 'action'}}</AkTypography>\n\n  <AkTooltip\n    @color='dark'\n    @placement='top-start'\n    @arrow={{true}}\n    data-test-amVersionTable-actionColumnHeader-tooltip\n  >\n    <:tooltipContent>\n      <div\n        local-class='tooltip-content'\n        data-test-amVersionTable-actionColumnHeader-tooltipContent\n      >\n        <AkTypography @color='inherit'>\n          {{t 'appMonitoringModule.actionsInfo'}}\n        </AkTypography>\n      </div>\n    </:tooltipContent>\n\n    <:default>\n      <AkIcon\n        @iconName='info'\n        @size='small'\n        data-test-amVersionTable-actionColumnHeader-tooltipIcon\n      />\n    </:default>\n  </AkTooltip>\n</AkStack>","moduleName":"irene/components/app-monitoring/version-table/actions-header/index.hbs","parseOptions":{"srcName":"irene/components/app-monitoring/version-table/actions-header/index.hbs"}});
import Component from '@glimmer/component';
import AmAppVersionModel from 'irene/models/am-app-version';

interface AppMonitoringVersionTableActionsSignature {
  Args: {
    amAppVersion: AmAppVersionModel;
  };
}

export default class AppMonitoringVersionTableActionsComponent extends Component<AppMonitoringVersionTableActionsSignature> {
  get amAppVersion() {
    return this.args.amAppVersion;
  }

  get isScanned() {
    return this.amAppVersion.get('latestFile').get('id');
  }
}
