export default {
  "report-actions": "_report-actions_fza66m",
  "button-small": "_button-small_fza66m",
  "button-progress": "_button-progress_fza66m",
  "progress-loader": "_progress-loader_fza66m",
  "download-button": "_download-button_fza66m",
  "report-loading-error": "_report-loading-error_fza66m",
  "is-progress": "_is-progress_fza66m",
  "progress": "_progress_fza66m"
};
