import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div local-class='nf-icon-container' {{did-insert this.fetchNotifications}}>\n  <button\n    type='button'\n    local-class='nf-icon nf-button'\n    {{on 'click' this.onClick}}\n    title='{{this.akNotifications.unReadCount}} unread notifications'\n    data-test-bell-icon\n  >\n    <div local-class='icon-container' data-test-bell-icon-trigger>\n      <AkIcon @iconName='notifications' data-test-notification-icon />\n      {{#if this.akNotifications.hasUnReadNotifications}}\n        <div local-class='nf-unread-dot' data-test-unread-dot></div>\n      {{/if}}\n    </div>\n\n  </button>\n\n  <NotificationsDropdown\n    @anchorRef={{this.anchorRef}}\n    @onClose={{this.closeNotification}}\n  />\n</div>", {"contents":"<div local-class='nf-icon-container' {{did-insert this.fetchNotifications}}>\n  <button\n    type='button'\n    local-class='nf-icon nf-button'\n    {{on 'click' this.onClick}}\n    title='{{this.akNotifications.unReadCount}} unread notifications'\n    data-test-bell-icon\n  >\n    <div local-class='icon-container' data-test-bell-icon-trigger>\n      <AkIcon @iconName='notifications' data-test-notification-icon />\n      {{#if this.akNotifications.hasUnReadNotifications}}\n        <div local-class='nf-unread-dot' data-test-unread-dot></div>\n      {{/if}}\n    </div>\n\n  </button>\n\n  <NotificationsDropdown\n    @anchorRef={{this.anchorRef}}\n    @onClose={{this.closeNotification}}\n  />\n</div>","moduleName":"irene/components/notifications-page/bell-icon/index.hbs","parseOptions":{"srcName":"irene/components/notifications-page/bell-icon/index.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import AkNotificationsService from 'irene/services/ak-notifications';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class NotificationsPageBellIconComponent extends Component {
  @tracked anchorRef: HTMLElement | null = null;
  @service declare akNotifications: AkNotificationsService;

  @action
  onClick(event: Event) {
    if (this.anchorRef) {
      this.closeNotification();

      return;
    }

    this.anchorRef = event.currentTarget as HTMLElement;
    this.fetchNotifications();
  }

  @action
  closeNotification() {
    this.anchorRef = null;
  }

  @action
  fetchNotifications() {
    this.akNotifications.fetchUnRead.perform();
  }
}
