export default {
  "severity-indicator": "_severity-indicator_wdasff",
  "severity-critical": "_severity-critical_wdasff",
  "severity-high": "_severity-high_wdasff",
  "severity-medium": "_severity-medium_wdasff",
  "severity-low": "_severity-low_wdasff",
  "severity-passed": "_severity-passed_wdasff",
  "severity-none": "_severity-none_wdasff",
  "tooltip-content-container": "_tooltip-content-container_wdasff"
};
