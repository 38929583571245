import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{! @glint-nocheck: or}}\n<button\n  data-test-ak-icon-button\n  ...attributes\n  local-class='\n    ak-icon-button-root \n    ak-icon-button-variant-{{this.variant}}\n    ak-icon-button-border-color-{{or @borderColor \"default\"}}\n    ak-icon-button-size-{{this.size}}'\n  type={{or @type 'button'}}\n>\n  {{yield}}\n</button>", {"contents":"{{! @glint-nocheck: or}}\n<button\n  data-test-ak-icon-button\n  ...attributes\n  local-class='\n    ak-icon-button-root \n    ak-icon-button-variant-{{this.variant}}\n    ak-icon-button-border-color-{{or @borderColor \"default\"}}\n    ak-icon-button-size-{{this.size}}'\n  type={{or @type 'button'}}\n>\n  {{yield}}\n</button>","moduleName":"irene/components/ak-icon-button/index.hbs","parseOptions":{"srcName":"irene/components/ak-icon-button/index.hbs"}});
import Component from '@glimmer/component';

interface AkIconButtonSignature {
  Element: HTMLButtonElement;
  Args: {
    variant?: 'outlined' | 'default';
    type?: 'submit' | 'reset' | 'button';
    size?: 'medium' | 'small';
    borderColor?: 'default' | 'primary' | 'secondary';
  };

  Blocks: {
    default: [];
  };
}

export default class AkIconButtonComponent extends Component<AkIconButtonSignature> {
  get size() {
    return this.args.size || 'medium';
  }

  get variant() {
    return this.args.variant || 'default';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    AkIconButton: typeof AkIconButtonComponent;
  }
}
