/* eslint-disable ember/no-computed-properties-in-native-classes */
import Model, { attr } from '@ember-data/model';
import { computed } from '@ember/object';
import { htmlSafe } from '@ember/template';

export default class VulnerabilityModel extends Model {
  @attr('string')
  declare name: string;

  @attr('string')
  declare uuid: string;

  @attr('string')
  declare description: string;

  @attr('string')
  declare question: string;

  @attr('string')
  declare successMessage: string;

  @attr('string')
  declare moreInfo: string;

  @attr('string')
  declare heading: string;

  @attr('string')
  declare intro: string;

  @attr('string')
  declare compliant: string;

  @attr('number')
  declare platform: number;

  @attr('string')
  declare nonCompliant: string;

  @attr('string')
  declare relatedTo: string;

  @attr('string')
  declare businessImplication: string;

  @attr
  declare types: number[];

  @computed('description')
  get descriptionUnescapedd() {
    return htmlSafe(this.description);
  }
}

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    vulnerability: VulnerabilityModel;
  }
}
