import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<nav local-class='subnav' data-test-clients-nav>\n  {{#each this.tabs as |tab|}}\n    {{#if tab.enabled}}\n      <LinkTo\n        id={{tab.id}}\n        @route={{tab.link}}\n        local-class='subnav-link {{if\n          (eq tab.link this.currentRoute)\n          'active'\n        }}'\n        data-test-nav-tab={{tab.id}}\n      >\n        {{tab.label}}\n      </LinkTo>\n    {{/if}}\n  {{/each}}\n</nav>", {"contents":"<nav local-class='subnav' data-test-clients-nav>\n  {{#each this.tabs as |tab|}}\n    {{#if tab.enabled}}\n      <LinkTo\n        id={{tab.id}}\n        @route={{tab.link}}\n        local-class='subnav-link {{if\n          (eq tab.link this.currentRoute)\n          'active'\n        }}'\n        data-test-nav-tab={{tab.id}}\n      >\n        {{tab.label}}\n      </LinkTo>\n    {{/if}}\n  {{/each}}\n</nav>","moduleName":"irene/components/partner/clients-nav/index.hbs","parseOptions":{"srcName":"irene/components/partner/clients-nav/index.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class PartnerClientNavComponent extends Component {
  @service intl;
  @service router;
  @service partner;

  get tabs() {
    return [
      {
        id: 'overview',
        label: this.intl.t('overview'),
        enabled: true,
        link: 'authenticated.partner.clients.overview',
      },
      {
        id: 'invitations',
        label: this.intl.t('invitations'),
        enabled: true,
        link: 'authenticated.partner.clients.invitations',
      },
      {
        id: 'registration-requests',
        label: this.intl.t('registrationRequests'),
        enabled: this.partner.access.admin_registration,
        link: 'authenticated.partner.clients.registration-requests',
      },
    ];
  }

  get currentRoute() {
    return this.router.currentRoute.name;
  }
}
