/* eslint-disable prettier/prettier */
import { helper } from '@ember/component/helper';
import ENUMS from 'irene/enums';

// This function receives the params `params, hash`
const vulnerabilityType = function(params) {

  const currentVulnerabilityType = params[0];

  if (currentVulnerabilityType === ENUMS.VULNERABILITY_TYPE.STATIC) {
    return "static";
  } else if (currentVulnerabilityType === ENUMS.VULNERABILITY_TYPE.DYNAMIC) {
    return "dynamic";
  } else if (currentVulnerabilityType === ENUMS.VULNERABILITY_TYPE.MANUAL) {
    return "manual";
  } else {
    return "api";
  }
};

const VulnerabilityTypeHelper = helper(vulnerabilityType);

export { vulnerabilityType };

export default VulnerabilityTypeHelper;
