export default {
  "title": "_title_1ledau",
  "table": "_table_1ledau",
  "table-header": "_table-header_1ledau",
  "table-row": "_table-row_1ledau",
  "pagination": "_pagination_1ledau",
  "loading": "_loading_1ledau",
  "loading-error": "_loading-error_1ledau",
  "empty-data": "_empty-data_1ledau"
};
