import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkTypography data-test-amVersionTable-dateFound>\n  {{this.dateFound}}\n</AkTypography>", {"contents":"<AkTypography data-test-amVersionTable-dateFound>\n  {{this.dateFound}}\n</AkTypography>","moduleName":"irene/components/app-monitoring/version-table/date-found/index.hbs","parseOptions":{"srcName":"irene/components/app-monitoring/version-table/date-found/index.hbs"}});
import Component from '@glimmer/component';
import AmAppVersionModel from 'irene/models/am-app-version';

import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.extend(advancedFormat);

interface AppMonitoringVersionTableDateFoundSignature {
  Args: {
    amAppVersion: AmAppVersionModel;
  };
}

export default class AppMonitoringVersionTableDateFoundComponent extends Component<AppMonitoringVersionTableDateFoundSignature> {
  get dateFound() {
    return dayjs(this.args.amAppVersion.get('createdOn')).format('Do MMM YYYY');
  }
}
