export default {
  "swagger-ui": "_swagger-ui_1bnxb0",
  "wrapper": "_wrapper_1bnxb0",
  "info": "_info_1bnxb0",
  "main": "_main_1bnxb0",
  "title": "_title_1bnxb0",
  "version-stamp": "_version-stamp_1bnxb0",
  "version": "_version_1bnxb0",
  "description": "_description_1bnxb0",
  "renderedMarkdown": "_renderedMarkdown_1bnxb0",
  "highlight-code": "_highlight-code_1bnxb0",
  "microlight": "_microlight_1bnxb0",
  "opblock-body": "_opblock-body_1bnxb0",
  "btn": "_btn_1bnxb0",
  "authorize": "_authorize_1bnxb0",
  "auth-btn-wrapper": "_auth-btn-wrapper_1bnxb0",
  "try-out__btn": "_try-out__btn_1bnxb0",
  "cancel": "_cancel_1bnxb0",
  "execute": "_execute_1bnxb0",
  "btn-done": "_btn-done_1bnxb0",
  "opblock-tag": "_opblock-tag_1bnxb0",
  "expand-operation": "_expand-operation_1bnxb0",
  "opblock-summary": "_opblock-summary_1bnxb0",
  "execute-wrapper": "_execute-wrapper_1bnxb0",
  "btn-group": "_btn-group_1bnxb0",
  "swagger-ui-description": "_swagger-ui-description_1bnxb0",
  "information-container": "_information-container_1bnxb0",
  "block": "_block_1bnxb0",
  "swagger-ui-apis-container": "_swagger-ui-apis-container_1bnxb0",
  "models": "_models_1bnxb0",
  "dialog-ux": "_dialog-ux_1bnxb0",
  "modal-ux-content": "_modal-ux-content_1bnxb0",
  "scheme-container": "_scheme-container_1bnxb0",
  "schemes": "_schemes_1bnxb0",
  "schemes-server-container": "_schemes-server-container_1bnxb0",
  "servers-title": "_servers-title_1bnxb0",
  "servers": "_servers_1bnxb0",
  "auth-container": "_auth-container_1bnxb0",
  "api_key_value": "_api_key_value_1bnxb0",
  "swagger-ui-schemas-container": "_swagger-ui-schemas-container_1bnxb0",
  "is-open": "_is-open_1bnxb0",
  "model-container": "_model-container_1bnxb0",
  "model": "_model_1bnxb0"
};
