import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{! @glint-nocheck: or}}\n<span\n  data-test-ak-icon\n  ...attributes\n  class='{{this.variantClass}} ak-icon-{{@iconName}}'\n  local-class='\n    ak-icon-root\n    ak-icon-size-{{or @size \"medium\"}}\n    ak-icon-color-{{or @color \"inherit\"}}'\n></span>", {"contents":"{{! @glint-nocheck: or}}\n<span\n  data-test-ak-icon\n  ...attributes\n  class='{{this.variantClass}} ak-icon-{{@iconName}}'\n  local-class='\n    ak-icon-root\n    ak-icon-size-{{or @size \"medium\"}}\n    ak-icon-color-{{or @color \"inherit\"}}'\n></span>","moduleName":"irene/components/ak-icon/index.hbs","parseOptions":{"srcName":"irene/components/ak-icon/index.hbs"}});
import Component from '@glimmer/component';

export type AkIconColorVariant =
  | 'inherit'
  | 'textPrimary'
  | 'textSecondary'
  | 'primary'
  | 'secondary'
  | 'success'
  | 'error'
  | 'info'
  | 'warn';

export interface AkIconSignature {
  Element: HTMLSpanElement;
  Args: {
    variant?: 'filled' | 'rounded' | 'outlined';
    iconName: string;
    size?: 'medium' | 'small';
    color?: AkIconColorVariant;
  };
}

export default class AkIconComponent extends Component<AkIconSignature> {
  variants = {
    filled: 'ak-icon',
    rounded: 'ak-icon-round',
    outlined: 'ak-icon-outlined',
  };

  get variantClass() {
    const variant = this.args.variant || 'filled';
    return this.variants[variant];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    AkIcon: typeof AkIconComponent;
  }
}
