import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{!-- {{#if this.showMessage}} --}}\n<span>\n  no message object registered for messageCode:\n  {{this.messageCode}}\n</span>\n{{!-- {{/if}} --}}", {"contents":"{{!-- {{#if this.showMessage}} --}}\n<span>\n  no message object registered for messageCode:\n  {{this.messageCode}}\n</span>\n{{!-- {{/if}} --}}","moduleName":"irene/components/notifications-page/messages/error/index.hbs","parseOptions":{"srcName":"irene/components/notifications-page/messages/error/index.hbs"}});
import Component from '@glimmer/component';
import config from 'irene/config/environment';
import NfInAppNotificationModel from 'irene/models/nf-in-app-notification';
import { ErrorContext } from './context';

interface NotificationsPageMessagesEmptyArgs {
  notification: NfInAppNotificationModel;
  context: ErrorContext;
}

export default class NotificationsPageMessagesEmpty extends Component<NotificationsPageMessagesEmptyArgs> {
  get showMessage() {
    return config.environment !== 'production';
  }

  get messageCode() {
    return this.args.notification.messageCode;
  }
}
