import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkStack class='mt-1' @alignItems='center' @spacing='1'>\n  <AkButton\n    data-test-namespaceMessage-approve-button\n    @color='primary'\n    {{on 'click' @onApprove}}\n  >\n    {{t 'approve'}}\n  </AkButton>\n\n  <AkButton\n    data-test-namespaceMessage-reject-button\n    @color='neutral'\n    @variant='outlined'\n    {{on 'click' @onReject}}\n  >\n    {{t 'reject'}}\n  </AkButton>\n</AkStack>", {"contents":"<AkStack class='mt-1' @alignItems='center' @spacing='1'>\n  <AkButton\n    data-test-namespaceMessage-approve-button\n    @color='primary'\n    {{on 'click' @onApprove}}\n  >\n    {{t 'approve'}}\n  </AkButton>\n\n  <AkButton\n    data-test-namespaceMessage-reject-button\n    @color='neutral'\n    @variant='outlined'\n    {{on 'click' @onReject}}\n  >\n    {{t 'reject'}}\n  </AkButton>\n</AkStack>","moduleName":"irene/components/notifications-page/namespace-message/unmoderated/index.hbs","parseOptions":{"srcName":"irene/components/notifications-page/namespace-message/unmoderated/index.hbs"}});
import Component from '@glimmer/component';

export interface NotificationsPageNamespaceMessageUnmoderatedSignature {
  Args: {
    onApprove: () => Promise<void>;
    onReject: () => Promise<void>;
  };
}

export default class NotificationsPageNamespaceMessageUnmoderatedComponent extends Component<NotificationsPageNamespaceMessageUnmoderatedSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NotificationsPage::NamespaceMessage::Unmoderated': typeof NotificationsPageNamespaceMessageUnmoderatedComponent;
  }
}
