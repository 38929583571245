import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkStack\n  @justifyContent='flex-start'\n  @alignItems='center'\n  @flexWrap='wrap'\n  @spacing='1'\n  data-test-securityAnalysisListTable-scanTypes\n>\n  {{#each this.tags as |tag|}}\n    <AkTooltip\n      aria-label={{concat (t tag.text) ' scan tag tooltip'}}\n      @arrow={{true}}\n      @placement='top'\n      @color='dark'\n      @title='{{t tag.text}} {{t\n        (if tag.status \"scanCompleted\" \"scanNotCompleted\")\n      }}'\n    >\n      <AkChip\n        @variant='semi-filled'\n        @size='small'\n        @label={{t tag.text}}\n        @color={{if tag.status 'success' 'error'}}\n      >\n        <:icon>\n          <AkIcon @iconName={{if tag.status 'check-circle' 'cancel'}} />\n        </:icon>\n      </AkChip>\n    </AkTooltip>\n  {{/each}}\n</AkStack>", {"contents":"<AkStack\n  @justifyContent='flex-start'\n  @alignItems='center'\n  @flexWrap='wrap'\n  @spacing='1'\n  data-test-securityAnalysisListTable-scanTypes\n>\n  {{#each this.tags as |tag|}}\n    <AkTooltip\n      aria-label={{concat (t tag.text) ' scan tag tooltip'}}\n      @arrow={{true}}\n      @placement='top'\n      @color='dark'\n      @title='{{t tag.text}} {{t\n        (if tag.status \"scanCompleted\" \"scanNotCompleted\")\n      }}'\n    >\n      <AkChip\n        @variant='semi-filled'\n        @size='small'\n        @label={{t tag.text}}\n        @color={{if tag.status 'success' 'error'}}\n      >\n        <:icon>\n          <AkIcon @iconName={{if tag.status 'check-circle' 'cancel'}} />\n        </:icon>\n      </AkChip>\n    </AkTooltip>\n  {{/each}}\n</AkStack>","moduleName":"irene/components/security/analysis-list/table/scan-type/index.hbs","parseOptions":{"srcName":"irene/components/security/analysis-list/table/scan-type/index.hbs"}});
import Component from '@glimmer/component';
import ENUMS from 'irene/enums';

import type SecurityAnalysisModel from 'irene/models/security/analysis';

export interface SecurityAnalysisListTableScanTypeComponentSignature {
  Args: {
    analysis: SecurityAnalysisModel;
  };
}

export default class SecurityAnalysisListTableScanTypeComponent extends Component<SecurityAnalysisListTableScanTypeComponentSignature> {
  get analysis() {
    return this.args.analysis;
  }

  get tags() {
    const types = this.analysis?.vulnerability?.get('types');

    if (types === undefined) {
      return [];
    }

    const tags = [];

    for (const type of Array.from(types)) {
      if (type === ENUMS.VULNERABILITY_TYPE.STATIC) {
        tags.push({
          status: this.analysis?.file?.get('isStaticDone'),
          text: 'static',
        });
      }

      if (type === ENUMS.VULNERABILITY_TYPE.DYNAMIC) {
        tags.push({
          status: this.analysis?.file?.get('isDynamicDone'),
          text: 'dynamic',
        });
      }

      if (type === ENUMS.VULNERABILITY_TYPE.MANUAL) {
        tags.push({
          status: this.analysis?.file?.get('manual') === ENUMS.MANUAL.DONE,
          text: 'manual',
        });
      }

      if (type === ENUMS.VULNERABILITY_TYPE.API) {
        tags.push({
          status: this.analysis?.file?.get('isApiDone'),
          text: 'api',
        });
      }
    }

    return tags;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'security/analysis-list/table/scan-type': typeof SecurityAnalysisListTableScanTypeComponent;
  }
}
