import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkChip\n  @variant='filled'\n  @label={{@sbomVulnerability.severityDisplayValue}}\n  @size='small'\n  local-class='severity-chip severity-chip-{{@sbomVulnerability.severityClass}}'\n  data-test-sbomComponentVulnerabilities-severity\n/>", {"contents":"<AkChip\n  @variant='filled'\n  @label={{@sbomVulnerability.severityDisplayValue}}\n  @size='small'\n  local-class='severity-chip severity-chip-{{@sbomVulnerability.severityClass}}'\n  data-test-sbomComponentVulnerabilities-severity\n/>","moduleName":"irene/components/sbom/component-details/vulnerabilities/severity/index.hbs","parseOptions":{"srcName":"irene/components/sbom/component-details/vulnerabilities/severity/index.hbs"}});
import Component from '@glimmer/component';
import SbomVulnerabilityModel from 'irene/models/sbom-vulnerability';

export interface SbomComponentDetailsVulnerabilitiesSeveritySignature {
  Args: {
    sbomVulnerability: SbomVulnerabilityModel;
  };
}

export default class SbomComponentDetailsVulnerabilitiesSeverityComponent extends Component<SbomComponentDetailsVulnerabilitiesSeveritySignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'sbom/component-details/vulnerabilities/severity': typeof SbomComponentDetailsVulnerabilitiesSeverityComponent;
  }
}
