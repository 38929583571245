import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkLink\n  @underline='none'\n  @route='authenticated.security.file'\n  @model={{@file.id}}\n  data-test-file-list-view-file-btn\n>\n  <AkButton @variant='outlined'>\n    View\n  </AkButton>\n</AkLink>", {"contents":"<AkLink\n  @underline='none'\n  @route='authenticated.security.file'\n  @model={{@file.id}}\n  data-test-file-list-view-file-btn\n>\n  <AkButton @variant='outlined'>\n    View\n  </AkButton>\n</AkLink>","moduleName":"irene/components/security/file-search-list/view/index.hbs","parseOptions":{"srcName":"irene/components/security/file-search-list/view/index.hbs"}});
import Component from '@glimmer/component';
import SecurityFileModel from 'irene/models/security/file';

export interface SecurityFileSearchListViewComponentSignature {
  Args: {
    file: SecurityFileModel;
  };
}

export default class SecurityFileSearchListViewComponent extends Component<SecurityFileSearchListViewComponentSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Security::FileSearchList::View': typeof SecurityFileSearchListViewComponent;
    'security/file-search-list/view': typeof SecurityFileSearchListViewComponent;
  }
}
