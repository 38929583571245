import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkStack @alignItems='center' @spacing='2'>\n  <AkIcon\n    @iconName={{@project.platformIconClass}}\n    local-class='team-project-icon team-project-icon-{{@project.platformIconClass}}'\n  />\n\n  <AkTypography @noWrap={{true}} title={{@project.packageName}}>\n    {{@project.packageName}}\n  </AkTypography>\n</AkStack>", {"contents":"<AkStack @alignItems='center' @spacing='2'>\n  <AkIcon\n    @iconName={{@project.platformIconClass}}\n    local-class='team-project-icon team-project-icon-{{@project.platformIconClass}}'\n  />\n\n  <AkTypography @noWrap={{true}} title={{@project.packageName}}>\n    {{@project.packageName}}\n  </AkTypography>\n</AkStack>","moduleName":"irene/components/organization-team/add-team-project/project-info/index.hbs","parseOptions":{"srcName":"irene/components/organization-team/add-team-project/project-info/index.hbs"}});
import Component from '@glimmer/component';
import OrganizationProjectModel from 'irene/models/organization-project';

export interface OrganizationTeamProjectListProjectInfoComponentSignature {
  Args: {
    project: OrganizationProjectModel;
  };
  Element: HTMLElement;
}

export default class OrganizationTeamProjectListProjectInfoComponent extends Component<OrganizationTeamProjectListProjectInfoComponentSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'OrganizationTeam::AddTeamProject::ProjectInfo': typeof OrganizationTeamProjectListProjectInfoComponent;
  }
}
