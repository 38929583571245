export default {
  "ak-typography": "_ak-typography_jq5r3g",
  "ak-typography-no-gutter-bottom": "_ak-typography-no-gutter-bottom_jq5r3g",
  "ak-typography-gutter-bottom": "_ak-typography-gutter-bottom_jq5r3g",
  "ak-typography-color-primary": "_ak-typography-color-primary_jq5r3g",
  "ak-typography-color-secondary": "_ak-typography-color-secondary_jq5r3g",
  "ak-typography-color-textPrimary": "_ak-typography-color-textPrimary_jq5r3g",
  "ak-typography-color-textSecondary": "_ak-typography-color-textSecondary_jq5r3g",
  "ak-typography-color-neutral": "_ak-typography-color-neutral_jq5r3g",
  "ak-typography-color-error": "_ak-typography-color-error_jq5r3g",
  "ak-typography-color-success": "_ak-typography-color-success_jq5r3g",
  "ak-typography-color-warn": "_ak-typography-color-warn_jq5r3g",
  "ak-typography-color-info": "_ak-typography-color-info_jq5r3g",
  "ak-typography-color-inherit": "_ak-typography-color-inherit_jq5r3g",
  "ak-typography-font-weight-bold": "_ak-typography-font-weight-bold_jq5r3g",
  "ak-typography-font-weight-medium": "_ak-typography-font-weight-medium_jq5r3g",
  "ak-typography-font-weight-regular": "_ak-typography-font-weight-regular_jq5r3g",
  "ak-typography-font-weight-light": "_ak-typography-font-weight-light_jq5r3g",
  "ak-typography-align-left": "_ak-typography-align-left_jq5r3g",
  "ak-typography-align-center": "_ak-typography-align-center_jq5r3g",
  "ak-typography-align-right": "_ak-typography-align-right_jq5r3g",
  "ak-typography-align-justify": "_ak-typography-align-justify_jq5r3g",
  "ak-typography-align-inherit": "_ak-typography-align-inherit_jq5r3g",
  "ak-typography-h1": "_ak-typography-h1_jq5r3g",
  "ak-typography-h2": "_ak-typography-h2_jq5r3g",
  "ak-typography-h3": "_ak-typography-h3_jq5r3g",
  "ak-typography-h4": "_ak-typography-h4_jq5r3g",
  "ak-typography-h5": "_ak-typography-h5_jq5r3g",
  "ak-typography-h6": "_ak-typography-h6_jq5r3g",
  "ak-typography-subtitle1": "_ak-typography-subtitle1_jq5r3g",
  "ak-typography-subtitle2": "_ak-typography-subtitle2_jq5r3g",
  "ak-typography-body1": "_ak-typography-body1_jq5r3g",
  "ak-typography-body2": "_ak-typography-body2_jq5r3g",
  "ak-typography-body3": "_ak-typography-body3_jq5r3g",
  "no-wrap": "_no-wrap_jq5r3g",
  "ak-typography-underline-none": "_ak-typography-underline-none_jq5r3g",
  "ak-typography-underline-always": "_ak-typography-underline-always_jq5r3g",
  "ak-typography-underline-hover": "_ak-typography-underline-hover_jq5r3g"
};
