import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkStack @alignItems='center' @spacing='0.5'>\n  {{#if this.isLatestScanReport}}\n    <AkTypography>\n      {{t 'latestScan'}}\n    </AkTypography>\n  {{else}}\n    <AkTypography>\n      {{t 'comprehensive'}}\n    </AkTypography>\n\n    <AkTooltip @arrow={{true}} @color='light' @placement='bottom'>\n      <:tooltipContent>\n        <AkTypography data-test-orgArchive-durationDateRange class='p-1'>\n          {{this.duration}}\n        </AkTypography>\n      </:tooltipContent>\n\n      <:default>\n        <AkIcon\n          data-test-orgArchive-durationIcon\n          @iconName='event'\n          @size='small'\n          @color='textPrimary'\n        />\n      </:default>\n    </AkTooltip>\n  {{/if}}\n</AkStack>", {"contents":"<AkStack @alignItems='center' @spacing='0.5'>\n  {{#if this.isLatestScanReport}}\n    <AkTypography>\n      {{t 'latestScan'}}\n    </AkTypography>\n  {{else}}\n    <AkTypography>\n      {{t 'comprehensive'}}\n    </AkTypography>\n\n    <AkTooltip @arrow={{true}} @color='light' @placement='bottom'>\n      <:tooltipContent>\n        <AkTypography data-test-orgArchive-durationDateRange class='p-1'>\n          {{this.duration}}\n        </AkTypography>\n      </:tooltipContent>\n\n      <:default>\n        <AkIcon\n          data-test-orgArchive-durationIcon\n          @iconName='event'\n          @size='small'\n          @color='textPrimary'\n        />\n      </:default>\n    </AkTooltip>\n  {{/if}}\n</AkStack>","moduleName":"irene/components/organization-archive/list/report-type/index.hbs","parseOptions":{"srcName":"irene/components/organization-archive/list/report-type/index.hbs"}});
import Component from '@glimmer/component';
import dayjs from 'dayjs';

import type OrganizationArchiveModel from 'irene/models/organization-archive';
import { OrganizationArchiveType } from 'irene/models/organization-archive';

export interface OrganizationArchiveListReportTypeSignature {
  Args: {
    archive: OrganizationArchiveModel;
  };
}

export default class OrganizationArchiveListReportTypeComponent extends Component<OrganizationArchiveListReportTypeSignature> {
  get duration() {
    const fromDate = dayjs(this.args.archive.fromDate).format('DD MMM YYYY');
    const toDate = dayjs(this.args.archive.toDate).format('DD MMM YYYY');

    return `${fromDate} - ${toDate}`;
  }

  get isLatestScanReport() {
    return (
      this.args.archive.archiveType === OrganizationArchiveType.LATEST_SCAN
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'OrganizationArchive::List::ReportType': typeof OrganizationArchiveListReportTypeComponent;
    'organization-archive/list/report-type': typeof OrganizationArchiveListReportTypeComponent;
  }
}
