import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if @analysis.id}}\n  <AkLink\n    @route='authenticated.security.analysis'\n    @color='primary'\n    @model={{@analysis.id}}\n    data-test-securityAnalysisListTable-analysisName\n  >\n    {{this.vulnerabilityName}}\n  </AkLink>\n{{/if}}", {"contents":"{{#if @analysis.id}}\n  <AkLink\n    @route='authenticated.security.analysis'\n    @color='primary'\n    @model={{@analysis.id}}\n    data-test-securityAnalysisListTable-analysisName\n  >\n    {{this.vulnerabilityName}}\n  </AkLink>\n{{/if}}","moduleName":"irene/components/security/analysis-list/table/name/index.hbs","parseOptions":{"srcName":"irene/components/security/analysis-list/table/name/index.hbs"}});
import Component from '@glimmer/component';
import type SecurityAnalysisModel from 'irene/models/security/analysis';

export interface SecurityAnalysisListTableNameComponentSignature {
  Args: {
    analysis: SecurityAnalysisModel;
  };
}

export default class SecurityAnalysisListTableNameComponent extends Component<SecurityAnalysisListTableNameComponentSignature> {
  get vulnerabilityName() {
    return this.args.analysis?.vulnerability?.get('name');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'security/analysis-list/table/name': typeof SecurityAnalysisListTableNameComponent;
  }
}
