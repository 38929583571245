export default {
  "client": "_client_1p5fmz",
  "invalid-credits": "_invalid-credits_1p5fmz",
  "title": "_title_1p5fmz",
  "empty-title": "_empty-title_1p5fmz",
  "credit-tranfer": "_credit-tranfer_1p5fmz",
  "transferable-credits": "_transferable-credits_1p5fmz",
  "tranfer-count": "_tranfer-count_1p5fmz",
  "input-count": "_input-count_1p5fmz",
  "credit-type": "_credit-type_1p5fmz",
  "tranfer-btn": "_tranfer-btn_1p5fmz"
};
