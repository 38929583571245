import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkStack\n  ...attributes\n  data-test-appPlatform-container\n  local-class='app-platform-icon-container {{if @bordered \"bordered\"}}'\n  @justifyContent='center'\n  @alignItems='center'\n>\n  <AkIcon\n    data-test-appPlatform-icon\n    @iconName='{{this.platformIconClass}}'\n    local-class='app-platform-icon-{{this.platformIconClass}}'\n  />\n</AkStack>", {"contents":"<AkStack\n  ...attributes\n  data-test-appPlatform-container\n  local-class='app-platform-icon-container {{if @bordered \"bordered\"}}'\n  @justifyContent='center'\n  @alignItems='center'\n>\n  <AkIcon\n    data-test-appPlatform-icon\n    @iconName='{{this.platformIconClass}}'\n    local-class='app-platform-icon-{{this.platformIconClass}}'\n  />\n</AkStack>","moduleName":"irene/components/sbom/app-platform/index.hbs","parseOptions":{"srcName":"irene/components/sbom/app-platform/index.hbs"}});
import Component from '@glimmer/component';
import SbomProjectModel from 'irene/models/sbom-project';

export interface SbomAppPlatformSignature {
  Element: HTMLElement;
  Args: {
    sbomProject?: SbomProjectModel;
    bordered?: boolean;
  };
}

export default class SbomAppPlatformComponent extends Component<SbomAppPlatformSignature> {
  get platformIconClass() {
    return this.args.sbomProject?.project.get('platformIconClass');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Sbom::AppPlatform': typeof SbomAppPlatformComponent;
    'sbom/app-platform': typeof SbomAppPlatformComponent;
  }
}
