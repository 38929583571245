import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkStack\n  @width='full'\n  @alignItems='center'\n  @justifyContent='center'\n  local-class='notification-dropdown-footer'\n  class='py-2'\n>\n  <AkLink\n    @color='primary'\n    @route='authenticated.dashboard.notifications'\n    @underline='always'\n    @title='View notifications'\n    {{on 'click' this.onClick}}\n    data-test-notification-dropdown-link\n  >\n    <strong>View All Notifications</strong>\n  </AkLink>\n</AkStack>", {"contents":"<AkStack\n  @width='full'\n  @alignItems='center'\n  @justifyContent='center'\n  local-class='notification-dropdown-footer'\n  class='py-2'\n>\n  <AkLink\n    @color='primary'\n    @route='authenticated.dashboard.notifications'\n    @underline='always'\n    @title='View notifications'\n    {{on 'click' this.onClick}}\n    data-test-notification-dropdown-link\n  >\n    <strong>View All Notifications</strong>\n  </AkLink>\n</AkStack>","moduleName":"irene/components/notifications-dropdown/footer/index.hbs","parseOptions":{"srcName":"irene/components/notifications-dropdown/footer/index.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';

interface NotificationsDropdownComponentArgs {
  onViewAllNotificationClick(): void;
}

export default class NotificationsDropdownFooterComponent extends Component<NotificationsDropdownComponentArgs> {
  @action onClick() {
    if (this.args.onViewAllNotificationClick) {
      this.args.onViewAllNotificationClick();
    }
  }
}
