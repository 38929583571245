export default {
  "ak-pagination": "_ak-pagination_c191ev",
  "ak-pagination-divider": "_ak-pagination-divider_c191ev",
  "pagination-buttons": "_pagination-buttons_c191ev",
  "ak-pagination-button-prev-icon": "_ak-pagination-button-prev-icon_c191ev",
  "ak-pagination-button-next-icon": "_ak-pagination-button-next-icon_c191ev",
  "ak-pagination-select": "_ak-pagination-select_c191ev",
  "ak-pagination-prev-button": "_ak-pagination-prev-button_c191ev",
  "ak-pagination-next-button": "_ak-pagination-next-button_c191ev",
  "ak-pagination-variant-compact": "_ak-pagination-variant-compact_c191ev"
};
