import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<span\n  ...attributes\n  local-class='\n    toggle-container\n    toggle-container-{{if @size @size \"default\"}}\n    {{if @disabled \"disabled\"}}\n  '\n>\n  <Input\n    data-test-toggle-input\n    local-class='toggle-input toggle-input-{{if @size @size \"default\"}}'\n    @type='checkbox'\n    disabled={{@disabled}}\n    readonly={{@readonly}}\n    @checked={{@checked}}\n    id={{this.id}}\n    {{on 'change' this.onChange}}\n    {{on 'click' this.onClick}}\n  />\n  <span local-class='track' data-test-toggle-input-slider></span>\n</span>", {"contents":"<span\n  ...attributes\n  local-class='\n    toggle-container\n    toggle-container-{{if @size @size \"default\"}}\n    {{if @disabled \"disabled\"}}\n  '\n>\n  <Input\n    data-test-toggle-input\n    local-class='toggle-input toggle-input-{{if @size @size \"default\"}}'\n    @type='checkbox'\n    disabled={{@disabled}}\n    readonly={{@readonly}}\n    @checked={{@checked}}\n    id={{this.id}}\n    {{on 'change' this.onChange}}\n    {{on 'click' this.onClick}}\n  />\n  <span local-class='track' data-test-toggle-input-slider></span>\n</span>","moduleName":"irene/components/ak-toggle/index.hbs","parseOptions":{"srcName":"irene/components/ak-toggle/index.hbs"}});
import Component from '@glimmer/component';
import { guidFor } from '@ember/object/internals';
import { action } from '@ember/object';

export interface AkToggleSignature {
  Element: HTMLSpanElement;
  Args: {
    id?: string;
    size?: string;
    checked?: boolean;
    disabled?: boolean;
    readonly?: boolean;
    onChange?: (event: Event, checked?: boolean) => void;
    onClick?: (event: MouseEvent, checked?: boolean) => void;
  };
}

export default class AkToggleComponent extends Component<AkToggleSignature> {
  get id() {
    return this.args.id || `ak-toggle-${guidFor(this)}`;
  }

  @action
  onClick(event: MouseEvent) {
    if (this.args.readonly) {
      event.preventDefault();

      return false;
    }

    if (this.args.onClick) {
      this.args.onClick(event);
    }
  }

  @action
  onChange(event: Event) {
    const target = event?.target as HTMLInputElement;

    if (this.args.onChange) {
      this.args.onChange(event, target.checked);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    AkToggle: typeof AkToggleComponent;
  }
}
