import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AppLogo @src={{this.iconUrl}} @role='none' />", {"contents":"<AppLogo @src={{this.iconUrl}} @role='none' />","moduleName":"irene/components/sbom/app-logo/index.hbs","parseOptions":{"srcName":"irene/components/sbom/app-logo/index.hbs"}});
import Component from '@glimmer/component';
import SbomProjectModel from 'irene/models/sbom-project';

export interface SbomAppLogoSignature {
  Args: {
    sbomProject?: SbomProjectModel;
  };
}

export default class SbomAppLogoComponent extends Component<SbomAppLogoSignature> {
  get iconUrl() {
    return this.args.sbomProject?.project.get('lastFile')?.get('iconUrl');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Sbom::AppLogo': typeof SbomAppLogoComponent;
  }
}
