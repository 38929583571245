import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkStack class='mt-1' @alignItems='center' @spacing='1'>\n  <AkIcon\n    data-test-namespaceMessage-rejected-icon\n    @iconName='cancel'\n    @color='error'\n  />\n\n  <AkTypography data-test-namespaceMessage-rejected-message>\n    {{t 'notificationModule.namespaceMessage.rejected'}}\n  </AkTypography>\n</AkStack>", {"contents":"<AkStack class='mt-1' @alignItems='center' @spacing='1'>\n  <AkIcon\n    data-test-namespaceMessage-rejected-icon\n    @iconName='cancel'\n    @color='error'\n  />\n\n  <AkTypography data-test-namespaceMessage-rejected-message>\n    {{t 'notificationModule.namespaceMessage.rejected'}}\n  </AkTypography>\n</AkStack>","moduleName":"irene/components/notifications-page/namespace-message/rejected/index.hbs","parseOptions":{"srcName":"irene/components/notifications-page/namespace-message/rejected/index.hbs"}});
import Component from '@glimmer/component';

export default class NotificationsPageNamespaceMessageRejectedComponent extends Component {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NotificationsPage::NamespaceMessage::Rejected': typeof NotificationsPageNamespaceMessageRejectedComponent;
  }
}
