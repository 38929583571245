export default {
  "severity-chip": "_severity-chip_hwsq8h",
  "severity-chip-critical": "_severity-chip-critical_hwsq8h",
  "severity-chip-high": "_severity-chip-high_hwsq8h",
  "severity-chip-medium": "_severity-chip-medium_hwsq8h",
  "severity-chip-low": "_severity-chip-low_hwsq8h",
  "severity-chip-info": "_severity-chip-info_hwsq8h",
  "severity-chip-none": "_severity-chip-none_hwsq8h",
  "severity-chip-unknown": "_severity-chip-unknown_hwsq8h"
};
