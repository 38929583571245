import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkStack\n  @direction='column'\n  data-test-generalSettings-projectTeamTable-teamInfo\n>\n  <AkLink\n    @color='primary'\n    @route='authenticated.dashboard.organization.teams'\n    @query={{hash team_query=@team.name}}\n    @fontWeight='medium'\n    @typographyVariant='h5'\n    {{style marginBottom='0.2857em'}}\n    data-test-generalSettings-projectTeamTable-teamInfoLink\n  >\n    {{@team.name}}\n  </AkLink>\n\n  <AkTypography @color='textSecondary' @variant='body2'>\n    {{@team.membersCount}}\n    {{t 'members'}}\n  </AkTypography>\n</AkStack>", {"contents":"<AkStack\n  @direction='column'\n  data-test-generalSettings-projectTeamTable-teamInfo\n>\n  <AkLink\n    @color='primary'\n    @route='authenticated.dashboard.organization.teams'\n    @query={{hash team_query=@team.name}}\n    @fontWeight='medium'\n    @typographyVariant='h5'\n    {{style marginBottom='0.2857em'}}\n    data-test-generalSettings-projectTeamTable-teamInfoLink\n  >\n    {{@team.name}}\n  </AkLink>\n\n  <AkTypography @color='textSecondary' @variant='body2'>\n    {{@team.membersCount}}\n    {{t 'members'}}\n  </AkTypography>\n</AkStack>","moduleName":"irene/components/project-settings/general-settings/project-team-table/teams/index.hbs","parseOptions":{"srcName":"irene/components/project-settings/general-settings/project-team-table/teams/index.hbs"}});
import Component from '@glimmer/component';
import ProjectTeamModel from 'irene/models/project-team';

interface ProjectSettingsGeneralSettingsProjectTeamTableTeamsSignature {
  Args: {
    team: ProjectTeamModel | null;
  };
}

export default class ProjectSettingsGeneralSettingsProjectTeamTableTeamsComponent extends Component<ProjectSettingsGeneralSettingsProjectTeamTableTeamsSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'project-settings/general-settings/project-team-table/teams': typeof ProjectSettingsGeneralSettingsProjectTeamTableTeamsComponent;
  }
}
