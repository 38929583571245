export default {
  "ak-loader-root": "_ak-loader-root_kekvu",
  "SpinnerFrames": "_SpinnerFrames_kekvu",
  "ak-loader-svg": "_ak-loader-svg_kekvu",
  "indeterminate": "_indeterminate_kekvu",
  "ak-loader-svg-track": "_ak-loader-svg-track_kekvu",
  "ak-loader-svg-indicator": "_ak-loader-svg-indicator_kekvu",
  "ak-loader-track-color-primary": "_ak-loader-track-color-primary_kekvu",
  "ak-loader-track-color-secondary": "_ak-loader-track-color-secondary_kekvu",
  "ak-loader-track-color-info-dark": "_ak-loader-track-color-info-dark_kekvu",
  "ak-loader-track-color-info": "_ak-loader-track-color-info_kekvu",
  "ak-loader-track-color-success": "_ak-loader-track-color-success_kekvu",
  "ak-loader-track-color-warn-dark": "_ak-loader-track-color-warn-dark_kekvu",
  "ak-loader-track-color-warn": "_ak-loader-track-color-warn_kekvu",
  "ak-loader-track-color-error": "_ak-loader-track-color-error_kekvu",
  "ak-loader-indicator-color-primary": "_ak-loader-indicator-color-primary_kekvu",
  "ak-loader-indicator-color-secondary": "_ak-loader-indicator-color-secondary_kekvu",
  "ak-loader-indicator-color-info": "_ak-loader-indicator-color-info_kekvu",
  "ak-loader-indicator-color-info-dark": "_ak-loader-indicator-color-info-dark_kekvu",
  "ak-loader-indicator-color-success": "_ak-loader-indicator-color-success_kekvu",
  "ak-loader-indicator-color-warn": "_ak-loader-indicator-color-warn_kekvu",
  "ak-loader-indicator-color-warn-dark": "_ak-loader-indicator-color-warn-dark_kekvu",
  "ak-loader-indicator-color-error": "_ak-loader-indicator-color-error_kekvu",
  "ak-loader-svg-label": "_ak-loader-svg-label_kekvu"
};
