import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<UploadApp::ViaSystem />\n\n{{#if this.urlUploadAllowed}}\n  <UploadApp::ViaLink />\n{{/if}}\n\n<UploadApp::Status />", {"contents":"<UploadApp::ViaSystem />\n\n{{#if this.urlUploadAllowed}}\n  <UploadApp::ViaLink />\n{{/if}}\n\n<UploadApp::Status />","moduleName":"irene/components/upload-app/index.hbs","parseOptions":{"srcName":"irene/components/upload-app/index.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

import ConfigurationService from 'irene/services/configuration';

export default class UploadAppComponent extends Component {
  @service declare configuration: ConfigurationService;

  get urlUploadAllowed() {
    return this.configuration.serverData.urlUploadAllowed;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    UploadApp: typeof UploadAppComponent;
  }
}
