import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  data-test-NovncRfb-canvasContainer\n  data-test-cy='NovncRfb-canvasContainer'\n  local-class='novnc-rfb'\n  {{did-insert this.onDidInsertElement}}\n  {{will-destroy this.onWillDestroyElement}}\n/>", {"contents":"<div\n  data-test-NovncRfb-canvasContainer\n  data-test-cy='NovncRfb-canvasContainer'\n  local-class='novnc-rfb'\n  {{did-insert this.onDidInsertElement}}\n  {{will-destroy this.onWillDestroyElement}}\n/>","moduleName":"irene/components/novnc-rfb/index.hbs","parseOptions":{"srcName":"irene/components/novnc-rfb/index.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
// @ts-expect-error no types
import RFB from '@novnc/novnc/lib/rfb';

export interface NovncRfbSignature {
  Args: {
    deviceFarmURL: string;
    deviceFarmPassword: string;
  };
}

export default class NovncRfbComponent extends Component<NovncRfbSignature> {
  @tracked rfb: any = null;

  @action
  onDidInsertElement(element: HTMLDivElement) {
    this.rfb = new RFB(element, this.args.deviceFarmURL, {
      credentials: {
        password: this.args.deviceFarmPassword,
      },
    });

    this.rfb.addEventListener('connect', () => {
      const sizing_element = element;

      this.rfb.scaleViewport = true;

      this.rfb._display.autoscale(
        sizing_element.offsetWidth,
        sizing_element.offsetHeight
      );
    });
  }

  @action
  onWillDestroyElement() {
    this.rfb?.removeEventListener('connect', () => {
      this.rfb?.disconnect();

      this.rfb = null;
    });
  }
}
