import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkStack @alignItems='center' @spacing='2'>\n  <AkIcon\n    @iconName='{{@namespace.platformIconClass}}'\n    local-class='team-project-icon-{{@namespace.platformIconClass}} team-project-icon'\n  />\n\n  <AkTypography @tag='span' @noWrap={{true}}>\n    {{@namespace.value}}\n  </AkTypography>\n</AkStack>", {"contents":"<AkStack @alignItems='center' @spacing='2'>\n  <AkIcon\n    @iconName='{{@namespace.platformIconClass}}'\n    local-class='team-project-icon-{{@namespace.platformIconClass}} team-project-icon'\n  />\n\n  <AkTypography @tag='span' @noWrap={{true}}>\n    {{@namespace.value}}\n  </AkTypography>\n</AkStack>","moduleName":"irene/components/organization-namespace/namespace-value/index.hbs","parseOptions":{"srcName":"irene/components/organization-namespace/namespace-value/index.hbs"}});
import Component from '@glimmer/component';
import OrganizationNamespaceModel from 'irene/models/organization-namespace';

export interface OrganizationNamespaceComponentSignature {
  Args: {
    namespace: OrganizationNamespaceModel;
  };
  Element: HTMLElement;
}

export default class OrganizationNamespaceValueComponent extends Component<OrganizationNamespaceComponentSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'OrganizationNamespace::NamespaceValue': typeof OrganizationNamespaceValueComponent;
  }
}
