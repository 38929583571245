export default {
  "ak-link-root": "_ak-link-root_9h6zsb",
  "ak-link-disabled": "_ak-link-disabled_9h6zsb",
  "ak-link-text": "_ak-link-text_9h6zsb",
  "ak-link-color-primary": "_ak-link-color-primary_9h6zsb",
  "ak-link-color-secondary": "_ak-link-color-secondary_9h6zsb",
  "ak-link-color-textPrimary": "_ak-link-color-textPrimary_9h6zsb",
  "ak-link-color-textSecondary": "_ak-link-color-textSecondary_9h6zsb",
  "ak-link-color-warn": "_ak-link-color-warn_9h6zsb",
  "ak-link-color-error": "_ak-link-color-error_9h6zsb",
  "ak-link-color-success": "_ak-link-color-success_9h6zsb",
  "ak-link-color-info": "_ak-link-color-info_9h6zsb",
  "ak-link-color-inherit": "_ak-link-color-inherit_9h6zsb",
  "ak-link-underline-none": "_ak-link-underline-none_9h6zsb",
  "ak-link-underline-always": "_ak-link-underline-always_9h6zsb",
  "ak-link-underline-hover": "_ak-link-underline-hover_9h6zsb",
  "ak-link-left-icon": "_ak-link-left-icon_9h6zsb",
  "ak-link-right-icon": "_ak-link-right-icon_9h6zsb",
  "ak-link-noWrap": "_ak-link-noWrap_9h6zsb"
};
