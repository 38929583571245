import CommonDRFAdapter from './commondrf';

export default class SbomVulnerabilityAuditAdapter extends CommonDRFAdapter {
  _buildNestedURL(sbomComponentId: string | number) {
    const baseURL = `${this.namespace_v2}/sb_components`;

    const sbomComponentUrl = this.buildURLFromBase(
      `${baseURL}/${encodeURIComponent(sbomComponentId)}`
    );

    return `${sbomComponentUrl}/sb_vulnerability_audits`;
  }

  urlForQuery(query: { sbomComponentId: string | number }) {
    return this._buildNestedURL(query.sbomComponentId);
  }
}

declare module 'ember-data/types/registries/adapter' {
  export default interface AdapterRegistry {
    'sbom-vulnerability-audit': SbomVulnerabilityAuditAdapter;
  }
}
