export default {
  "ak-accordion-root": "_ak-accordion-root_12v6qm",
  "details": "_details_12v6qm",
  "summary": "_summary_12v6qm",
  "collapsed": "_collapsed_12v6qm",
  "primary": "_primary_12v6qm",
  "secondary": "_secondary_12v6qm",
  "disabled": "_disabled_12v6qm",
  "summary-expand-icon": "_summary-expand-icon_12v6qm",
  "expanded": "_expanded_12v6qm"
};
