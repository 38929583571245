export default {
  "meta-info": "_meta-info_1ekhr4",
  "pipe": "_pipe_1ekhr4",
  "vulnerability-stats": "_vulnerability-stats_1ekhr4",
  "severity-levels": "_severity-levels_1ekhr4",
  "severity-level": "_severity-level_1ekhr4",
  "severity-label": "_severity-label_1ekhr4",
  "severity-color": "_severity-color_1ekhr4",
  "severity-color--critical": "_severity-color--critical_1ekhr4",
  "severity-color--high": "_severity-color--high_1ekhr4",
  "severity-color--medium": "_severity-color--medium_1ekhr4",
  "severity-color--low": "_severity-color--low_1ekhr4",
  "severity-color--passed": "_severity-color--passed_1ekhr4",
  "severity-color--untested": "_severity-color--untested_1ekhr4"
};
