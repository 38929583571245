import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#let\n  (component\n    'marketplace/card-content'\n    title=@data.title\n    logo=@data.logo\n    text=@data.description\n  )\n  as |Component|\n}}\n  {{#if @data.link}}\n    {{#if this.me.org.is_admin}}\n      <LinkTo @route={{@data.link}} local-class='marketplace-card'>\n        <Component />\n      </LinkTo>\n    {{else}}\n      <a\n        local-class='marketplace-card'\n        href='#'\n        {{on 'click' this.showIntegrationsPermissionDenied}}\n      >\n        <Component />\n      </a>\n    {{/if}}\n  {{else}}\n    <div local-class='marketplace-card-disabled'>\n      <div local-class='marketplace-card'>\n        <Component />\n      </div>\n    </div>\n  {{/if}}\n{{/let}}", {"contents":"{{#let\n  (component\n    'marketplace/card-content'\n    title=@data.title\n    logo=@data.logo\n    text=@data.description\n  )\n  as |Component|\n}}\n  {{#if @data.link}}\n    {{#if this.me.org.is_admin}}\n      <LinkTo @route={{@data.link}} local-class='marketplace-card'>\n        <Component />\n      </LinkTo>\n    {{else}}\n      <a\n        local-class='marketplace-card'\n        href='#'\n        {{on 'click' this.showIntegrationsPermissionDenied}}\n      >\n        <Component />\n      </a>\n    {{/if}}\n  {{else}}\n    <div local-class='marketplace-card-disabled'>\n      <div local-class='marketplace-card'>\n        <Component />\n      </div>\n    </div>\n  {{/if}}\n{{/let}}","moduleName":"irene/components/marketplace/integration-card/index.hbs","parseOptions":{"srcName":"irene/components/marketplace/integration-card/index.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import IntlService from 'ember-intl/services/intl';

import MeService from 'irene/services/me';

interface MarketplaceIntegrationCardSignature {
  Args: {
    data: {
      link: string;
      title: string;
      logo: string;
      description: string;
    };
  };
}

export default class MarketplaceIntegrationCardComponent extends Component<MarketplaceIntegrationCardSignature> {
  @service declare intl: IntlService;
  @service declare me: MeService;
  @service('notifications') declare notify: NotificationService;

  @action
  showIntegrationsPermissionDenied() {
    this.notify.error(this.intl.t('integrationsPermissionDenied'));
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Marketplace::IntegrationCard': typeof MarketplaceIntegrationCardComponent;
  }
}
