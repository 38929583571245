export default {
  "sast-page-root": "_sast-page-root_1ki9l6",
  "sast-page-container": "_sast-page-container_1ki9l6",
  "sast-results-info": "_sast-results-info_1ki9l6",
  "sast-results-sticky-header": "_sast-results-sticky-header_1ki9l6",
  "breadcrumb-container": "_breadcrumb-container_1ki9l6",
  "sast-results-header": "_sast-results-header_1ki9l6",
  "badge-count-text": "_badge-count-text_1ki9l6",
  "sast-results-tabs": "_sast-results-tabs_1ki9l6"
};
