import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkStack @tag='nav' @alignItems='center' class='pb-2'>\n  <AkIconButton {{on 'click' (fn this.handleMoveCenter -1 'month')}}>\n    <AkIcon {{style fontSize='1.5em !important'}} @iconName='arrow-left' />\n  </AkIconButton>\n\n  <AkStack class='mx-auto' @alignItems='center' @spacing='1'>\n    <AkTypography @variant='h4' @fontWeight='regular' @tag='span'>\n      {{this.centerMonth}}\n    </AkTypography>\n\n    <AkTypography\n      @variant='h4'\n      @fontWeight='regular'\n      @tag='span'\n      @color='textSecondary'\n    >\n      {{this.centerYear}}\n    </AkTypography>\n  </AkStack>\n\n  <AkIconButton {{on 'click' (fn this.handleMoveCenter 1 'month')}}>\n    <AkIcon {{style fontSize='1.5em !important'}} @iconName='arrow-right' />\n  </AkIconButton>\n</AkStack>", {"contents":"<AkStack @tag='nav' @alignItems='center' class='pb-2'>\n  <AkIconButton {{on 'click' (fn this.handleMoveCenter -1 'month')}}>\n    <AkIcon {{style fontSize='1.5em !important'}} @iconName='arrow-left' />\n  </AkIconButton>\n\n  <AkStack class='mx-auto' @alignItems='center' @spacing='1'>\n    <AkTypography @variant='h4' @fontWeight='regular' @tag='span'>\n      {{this.centerMonth}}\n    </AkTypography>\n\n    <AkTypography\n      @variant='h4'\n      @fontWeight='regular'\n      @tag='span'\n      @color='textSecondary'\n    >\n      {{this.centerYear}}\n    </AkTypography>\n  </AkStack>\n\n  <AkIconButton {{on 'click' (fn this.handleMoveCenter 1 'month')}}>\n    <AkIcon {{style fontSize='1.5em !important'}} @iconName='arrow-right' />\n  </AkIconButton>\n</AkStack>","moduleName":"irene/components/ak-date-picker/calendar-nav/index.hbs","parseOptions":{"srcName":"irene/components/ak-date-picker/calendar-nav/index.hbs"}});
import Component from '@glimmer/component';
import dayjs from 'dayjs';
import { action } from '@ember/object';

import { Calendar } from '..';
import { humanizeMonths } from 'irene/utils/date-time';

export interface AkDatePickerCalendarNavSignature {
  Args: {
    calendar: Calendar;
  };
}

export default class AkDatePickerCalendarNavComponent extends Component<AkDatePickerCalendarNavSignature> {
  get centerMonth() {
    return this.monthNames[dayjs(this.args.calendar.center).month()];
  }

  get centerYear() {
    return dayjs(this.args.calendar.center).year();
  }

  get monthNames() {
    return humanizeMonths();
  }

  @action
  handleMoveCenter(step: number, unit: 'month' | 'year', event: Event) {
    const calendar = this.args.calendar;

    calendar.actions.moveCenter?.(step, unit, calendar, event);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AkDatePicker::CalendarNav': typeof AkDatePickerCalendarNavComponent;
    'ak-date-picker/calendar-nav': typeof AkDatePickerCalendarNavComponent;
  }
}
