import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{! @glint-nocheck : or}}\n{{#let (element (or @tag 'hr')) as |Tag|}}\n  <Tag\n    data-test-ak-divider\n    ...attributes\n    local-class='\n      ak-divider-root \n      ak-divider-variant-{{or @variant \"fullWidth\"}} \n      ak-divider-color-{{or @color \"light\"}}'\n  />\n{{/let}}", {"contents":"{{! @glint-nocheck : or}}\n{{#let (element (or @tag 'hr')) as |Tag|}}\n  <Tag\n    data-test-ak-divider\n    ...attributes\n    local-class='\n      ak-divider-root \n      ak-divider-variant-{{or @variant \"fullWidth\"}} \n      ak-divider-color-{{or @color \"light\"}}'\n  />\n{{/let}}","moduleName":"irene/components/ak-divider/index.hbs","parseOptions":{"srcName":"irene/components/ak-divider/index.hbs"}});
import Component from '@glimmer/component';

export interface AkDividerSignature {
  Element: HTMLElement;
  Args: {
    tag?: string;
    variant?: 'fullWidth' | 'middle';
    color?: 'light' | 'dark';
  };
  Blocks: { default: [] };
}

export default class AkDividerComponent extends Component<AkDividerSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    AkDivider: typeof AkDividerComponent;
  }
}
