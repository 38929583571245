import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkIcon\n  ...attributes\n  @iconName='{{@platform}}'\n  local-class='app-platform-icon app-platform-icon-{{@platform}}'\n/>", {"contents":"<AkIcon\n  ...attributes\n  @iconName='{{@platform}}'\n  local-class='app-platform-icon app-platform-icon-{{@platform}}'\n/>","moduleName":"irene/components/app-platform-icon/index.hbs","parseOptions":{"srcName":"irene/components/app-platform-icon/index.hbs"}});
import Component from '@glimmer/component';

export interface AppPlatformIconSignature {
  Element: HTMLSpanElement;
  Args: {
    platform: 'android' | 'apple';
  };
}

export default class AppPlatformIconComponent extends Component<AppPlatformIconSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    AppPlatformIcon: typeof AppPlatformIconComponent;
  }
}
