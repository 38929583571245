export default {
  "settings-block": "_settings-block_1qtcxt",
  "settings-description": "_settings-description_1qtcxt",
  "header-app-moitoring-page": "_header-app-moitoring-page_1qtcxt",
  "header-app-moitoring-page-title": "_header-app-moitoring-page-title_1qtcxt",
  "header-app-moitoring-page-sorting-filter": "_header-app-moitoring-page-sorting-filter_1qtcxt",
  "filter-input": "_filter-input_1qtcxt",
  "filter-input-dropdown": "_filter-input-dropdown_1qtcxt",
  "search-input-container-width": "_search-input-container-width_1qtcxt",
  "search-package-name-input": "_search-package-name-input_1qtcxt",
  "divider": "_divider_1qtcxt",
  "clear-filter-label": "_clear-filter-label_1qtcxt",
  "clear-filter-icon": "_clear-filter-icon_1qtcxt",
  "clear-filter": "_clear-filter_1qtcxt"
};
