export default {
  "meta-info": "_meta-info_1n2fd3",
  "pipe": "_pipe_1n2fd3",
  "vulnerability-stats": "_vulnerability-stats_1n2fd3",
  "severity-levels": "_severity-levels_1n2fd3",
  "severity-level": "_severity-level_1n2fd3",
  "severity-label": "_severity-label_1n2fd3",
  "severity-color": "_severity-color_1n2fd3",
  "severity-color--critical": "_severity-color--critical_1n2fd3",
  "severity-color--high": "_severity-color--high_1n2fd3",
  "severity-color--medium": "_severity-color--medium_1n2fd3",
  "severity-color--low": "_severity-color--low_1n2fd3",
  "severity-color--passed": "_severity-color--passed_1n2fd3",
  "severity-color--untested": "_severity-color--untested_1n2fd3"
};
