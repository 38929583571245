import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{! @glint-nocheck: or, eq }}\n<AkStack\n  data-test-ak-appbar\n  ...attributes\n  local-class='\n    ak-appbar-root \n    ak-appbar-color-{{or @color \"inherit\"}}\n    ak-appbar-position-{{or @position \"static\"}}\n    ak-appbar-placement-{{or @placement \"top\"}}\n    {{if (eq @gutter false) \"\" \"ak-appbar-gutter\"}}\n    {{if @elevation \"ak-appbar-bottom-elevation\"}}'\n  @direction={{@direction}}\n  @alignItems={{or @alignItems 'center'}}\n  @spacing={{@spacing}}\n  @justifyContent={{@justifyContent}}\n>\n  {{yield (hash classes=this.classes)}}\n</AkStack>", {"contents":"{{! @glint-nocheck: or, eq }}\n<AkStack\n  data-test-ak-appbar\n  ...attributes\n  local-class='\n    ak-appbar-root \n    ak-appbar-color-{{or @color \"inherit\"}}\n    ak-appbar-position-{{or @position \"static\"}}\n    ak-appbar-placement-{{or @placement \"top\"}}\n    {{if (eq @gutter false) \"\" \"ak-appbar-gutter\"}}\n    {{if @elevation \"ak-appbar-bottom-elevation\"}}'\n  @direction={{@direction}}\n  @alignItems={{or @alignItems 'center'}}\n  @spacing={{@spacing}}\n  @justifyContent={{@justifyContent}}\n>\n  {{yield (hash classes=this.classes)}}\n</AkStack>","moduleName":"irene/components/ak-appbar/index.hbs","parseOptions":{"srcName":"irene/components/ak-appbar/index.hbs"}});
import Component from '@glimmer/component';
import styles from './index.scss';

import { AkStackSignature } from '../ak-stack';

type DefaultBlock = {
  classes: {
    defaultIconBtn?: string;
  };
};

type AkAppbarDirection = AkStackSignature['Args']['direction'];
type AkAppbarAlignItems = AkStackSignature['Args']['alignItems'];
type AkAppbarSpacing = AkStackSignature['Args']['spacing'];
type AkAppbarJustifyContent = AkStackSignature['Args']['justifyContent'];

export interface AkAppbarSignature {
  Element: HTMLElement;
  Args: {
    direction?: AkAppbarDirection;
    alignItems?: AkAppbarAlignItems;
    justifyContent?: AkAppbarJustifyContent;
    spacing?: AkAppbarSpacing;
    placement?: 'top' | 'bottom';
    position?: 'static' | 'fixed' | 'relative' | 'absolute' | 'sticky';
    gutter?: boolean;
    elevation?: boolean;
    color?: 'inherit' | 'default' | 'light' | 'dark';
  };
  Blocks: {
    default: [DefaultBlock];
  };
}

export default class AkAppbarComponent extends Component<AkAppbarSignature> {
  get classes() {
    return {
      defaultIconBtn: styles['ak-appbar-default-icon-button'],
    };
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    AkAppbar: typeof AkAppbarComponent;
  }
}
