import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  local-class='divider-container'\n  data-test-analysisRisk-overrideEditDrawer-divider\n>\n  <div local-class='divider'></div>\n\n  <AkTypography @tag='div' local-class='divider-label'>\n    {{@label}}\n  </AkTypography>\n</div>", {"contents":"<div\n  local-class='divider-container'\n  data-test-analysisRisk-overrideEditDrawer-divider\n>\n  <div local-class='divider'></div>\n\n  <AkTypography @tag='div' local-class='divider-label'>\n    {{@label}}\n  </AkTypography>\n</div>","moduleName":"irene/components/analysis-risk/override-edit-drawer/divider/index.hbs","parseOptions":{"srcName":"irene/components/analysis-risk/override-edit-drawer/divider/index.hbs"}});
import Component from '@glimmer/component';

export interface AnalysisRiskOverrideEditDrawerDividerSignature {
  Args: {
    label: string;
  };
}

export default class AnalysisRiskOverrideEditDrawerDividerComponent extends Component<AnalysisRiskOverrideEditDrawerDividerSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AnalysisRisk::OverrideEditDrawer::Divider': typeof AnalysisRiskOverrideEditDrawerDividerComponent;
    'analysis-risk/override-edit-drawer/divider': typeof AnalysisRiskOverrideEditDrawerDividerComponent;
  }
}
