import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkStack class='mt-1' @alignItems='center' @spacing='1'>\n  <AkIcon\n    data-test-namespaceMessage-approved-icon\n    @iconName='check-circle'\n    @color='success'\n  />\n\n  <AkTypography data-test-namespaceMessage-approved-message>\n    {{t\n      'notificationModule.namespaceMessage.approved'\n      moderaterName=@moderaterName\n    }}\n  </AkTypography>\n</AkStack>", {"contents":"<AkStack class='mt-1' @alignItems='center' @spacing='1'>\n  <AkIcon\n    data-test-namespaceMessage-approved-icon\n    @iconName='check-circle'\n    @color='success'\n  />\n\n  <AkTypography data-test-namespaceMessage-approved-message>\n    {{t\n      'notificationModule.namespaceMessage.approved'\n      moderaterName=@moderaterName\n    }}\n  </AkTypography>\n</AkStack>","moduleName":"irene/components/notifications-page/namespace-message/approved/index.hbs","parseOptions":{"srcName":"irene/components/notifications-page/namespace-message/approved/index.hbs"}});
import Component from '@glimmer/component';

export interface NotificationsPageNamespaceMessageApprovedSignature {
  Args: {
    moderaterName: string;
  };
}

export default class NotificationsPageNamespaceMessageApprovedComponent extends Component<NotificationsPageNamespaceMessageApprovedSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NotificationsPage::NamespaceMessage::Approved': typeof NotificationsPageNamespaceMessageApprovedComponent;
  }
}
