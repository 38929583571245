import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkTable @dense={{true}} as |t|>\n  <t.head @columns={{this.columns}} />\n\n  <t.body @rows={{this.allFindings}} as |b|>\n    <b.row\n      data-test-securityAnalysisDetailsTable-row\n      data-test-securityAnalysisDetailsTable-rowId='{{b.rowValue.id}}'\n      as |r|\n    >\n      <r.cell>\n        {{#let (component r.columnValue.component) as |Component|}}\n          <Component\n            @finding={{r.rowValue}}\n            @openRemoveFindingConfirmBox={{@openRemoveFindingConfirmBox}}\n          />\n        {{/let}}\n      </r.cell>\n    </b.row>\n  </t.body>\n</AkTable>", {"contents":"<AkTable @dense={{true}} as |t|>\n  <t.head @columns={{this.columns}} />\n\n  <t.body @rows={{this.allFindings}} as |b|>\n    <b.row\n      data-test-securityAnalysisDetailsTable-row\n      data-test-securityAnalysisDetailsTable-rowId='{{b.rowValue.id}}'\n      as |r|\n    >\n      <r.cell>\n        {{#let (component r.columnValue.component) as |Component|}}\n          <Component\n            @finding={{r.rowValue}}\n            @openRemoveFindingConfirmBox={{@openRemoveFindingConfirmBox}}\n          />\n        {{/let}}\n      </r.cell>\n    </b.row>\n  </t.body>\n</AkTable>","moduleName":"irene/components/security/analysis-details/findings/table/index.hbs","parseOptions":{"srcName":"irene/components/security/analysis-details/findings/table/index.hbs"}});
import Component from '@glimmer/component';

import SecurityAnalysisModel, {
  type SecurityAnalysisFinding,
} from 'irene/models/security/analysis';

export interface SecurityAnalysisDetailsFindingsTableComponentSignature {
  Args: {
    analysis: SecurityAnalysisModel | null;
    allFindings: SecurityAnalysisFinding[] | null;
    openRemoveFindingConfirmBox(findindId?: number): void;
  };
}

export default class SecurityAnalysisDetailsFindingsTableComponent extends Component<SecurityAnalysisDetailsFindingsTableComponentSignature> {
  get columns() {
    return [
      {
        name: 'Title',
        width: 70,
        textAlign: 'left',
        component: 'security/analysis-details/findings/table/title',
      },
      {
        name: 'Description',
        width: 180,
        component: 'security/analysis-details/findings/table/description',
        textAlign: 'left',
      },
      {
        name: 'Action',
        component: 'security/analysis-details/findings/table/action',
        textAlign: 'right',
      },
    ];
  }

  get analysis() {
    return this.args.analysis;
  }

  get allFindings() {
    return this.args.allFindings?.slice();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Security::AnalysisDetails::Findings::Table': typeof SecurityAnalysisDetailsFindingsTableComponent;
  }
}
