export default {
  "expanded-icon": "_expanded-icon_1h4uni",
  "down": "_down_1h4uni",
  "checkbox-tree-node": "_checkbox-tree-node_1h4uni",
  "root": "_root_1h4uni",
  "parent": "_parent_1h4uni",
  "child": "_child_1h4uni",
  "leaf": "_leaf_1h4uni",
  "has-parent-sibling": "_has-parent-sibling_1h4uni",
  "secondary": "_secondary_1h4uni",
  "disabled": "_disabled_1h4uni"
};
