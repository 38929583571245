import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<ProjectPreferences::Provider\n  @project={{@project}}\n  @profileId={{@profileId}}\n  @platform={{@platform}}\n  as |dpContext|\n>\n  {{yield\n    (hash\n      DevicePreferenceComponent=(component\n        'project-preferences/device-preference' dpContext=dpContext\n      )\n    )\n  }}\n</ProjectPreferences::Provider>", {"contents":"<ProjectPreferences::Provider\n  @project={{@project}}\n  @profileId={{@profileId}}\n  @platform={{@platform}}\n  as |dpContext|\n>\n  {{yield\n    (hash\n      DevicePreferenceComponent=(component\n        'project-preferences/device-preference' dpContext=dpContext\n      )\n    )\n  }}\n</ProjectPreferences::Provider>","moduleName":"irene/components/project-preferences/index.hbs","parseOptions":{"srcName":"irene/components/project-preferences/index.hbs"}});
import Component from '@glimmer/component';
import { WithBoundArgs } from '@glint/template';

import ProjectModel from 'irene/models/project';
import ProjectPreferencesDevicePreferenceComponent from './device-preference';

export interface ProjectPreferencesSignature {
  Args: {
    project?: ProjectModel | null;
    profileId?: number | string;
    platform?: number;
  };
  Blocks: {
    default: [
      {
        DevicePreferenceComponent: WithBoundArgs<
          typeof ProjectPreferencesDevicePreferenceComponent,
          'dpContext'
        >;
      },
    ];
  };
}

export default class ProjectPreferencesComponent extends Component<ProjectPreferencesSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    ProjectPreferences: typeof ProjectPreferencesComponent;
  }
}
