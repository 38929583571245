import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div local-class='app-monitoring-container'>\n  <AppMonitoring::Settings @settings={{@appMonitoring.settings}} />\n  <AppMonitoring::Table @settings={{@appMonitoring.settings}} />\n</div>", {"contents":"<div local-class='app-monitoring-container'>\n  <AppMonitoring::Settings @settings={{@appMonitoring.settings}} />\n  <AppMonitoring::Table @settings={{@appMonitoring.settings}} />\n</div>","moduleName":"irene/components/app-monitoring/index.hbs","parseOptions":{"srcName":"irene/components/app-monitoring/index.hbs"}});
import Component from '@glimmer/component';
import { AppMonitoringRouteModel } from 'irene/routes/authenticated/dashboard/app-monitoring';

interface AppMonitoringSignature {
  Args: {
    appMonitoring: AppMonitoringRouteModel;
  };
}

export default class AppMonitoringComponent extends Component<AppMonitoringSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    AppMonitoring: typeof AppMonitoringComponent;
  }
}
