import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div local-class='section-card-container'>\n  <AkStack\n    @alignItems='center'\n    @justifyContent='space-between'\n    local-class='section-card-header section-card-header-color-{{this.headerColor}}'\n  >\n    <AkTypography\n      data-test-serviceAccountSection-title\n      @color='inherit'\n      @variant='subtitle1'\n    >\n      {{@title}}\n    </AkTypography>\n\n    {{#if @showHeaderAction}}\n      {{yield (hash classes=this.classes) to='headerAction'}}\n    {{/if}}\n  </AkStack>\n\n  <AkDivider @color='dark' />\n\n  <div class='p-3' local-class='section-card-body'>\n    {{yield}}\n  </div>\n\n  {{#if @showFooterAction}}\n    <AkStack\n      @alignItems='center'\n      @spacing='1.5'\n      local-class='section-card-footer'\n      data-test-serviceAccountSection-footer\n    >\n      {{yield to='footerAction'}}\n    </AkStack>\n  {{/if}}\n</div>", {"contents":"<div local-class='section-card-container'>\n  <AkStack\n    @alignItems='center'\n    @justifyContent='space-between'\n    local-class='section-card-header section-card-header-color-{{this.headerColor}}'\n  >\n    <AkTypography\n      data-test-serviceAccountSection-title\n      @color='inherit'\n      @variant='subtitle1'\n    >\n      {{@title}}\n    </AkTypography>\n\n    {{#if @showHeaderAction}}\n      {{yield (hash classes=this.classes) to='headerAction'}}\n    {{/if}}\n  </AkStack>\n\n  <AkDivider @color='dark' />\n\n  <div class='p-3' local-class='section-card-body'>\n    {{yield}}\n  </div>\n\n  {{#if @showFooterAction}}\n    <AkStack\n      @alignItems='center'\n      @spacing='1.5'\n      local-class='section-card-footer'\n      data-test-serviceAccountSection-footer\n    >\n      {{yield to='footerAction'}}\n    </AkStack>\n  {{/if}}\n</div>","moduleName":"irene/components/organization/service-account/section/card/index.hbs","parseOptions":{"srcName":"irene/components/organization/service-account/section/card/index.hbs"}});
import Component from '@glimmer/component';
import styles from './index.scss';

export interface OrganizationServiceAccountSectionCardSignature {
  Args: {
    title: string;
    headerColor?: 'default' | 'primary';
    showHeaderAction?: boolean;
    showFooterAction?: boolean;
  };
  Blocks: {
    default: [];
    headerAction: [{ classes: { headerActionBtn?: string } }];
    footerAction: [];
  };
}

export default class OrganizationServiceAccountSectionCardComponent extends Component<OrganizationServiceAccountSectionCardSignature> {
  get headerColor() {
    return this.args.headerColor || 'default';
  }

  get classes() {
    return {
      headerActionBtn: styles['header-action-btn'],
    };
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Organization::ServiceAccount::Section::Card': typeof OrganizationServiceAccountSectionCardComponent;
  }
}
