import commondrf from '../commondrf';

export default class SecurityVulnerabilityAdapter extends commondrf {
  namespace = 'api/hudson-api';
  _buildURL(modelName: string | number, id: string | number) {
    const baseURL = this.buildURLFromBase(`${this.namespace}/projects`);

    if (id) {
      return `${baseURL}/${encodeURIComponent(id)}`;
    }

    return baseURL;
  }

  _buildNestedURL(
    modelName: string | number,
    projectId: string | number,
    id: string | number | undefined
  ) {
    const projectURL = this._buildURL(modelName, projectId);
    const vulnerabilitiesURL = [projectURL, 'vulnerabilities'].join('/');

    if (id) {
      return `${vulnerabilitiesURL}/${encodeURIComponent(id)}`;
    }

    return vulnerabilitiesURL;
  }

  urlForQuery(
    query: { projectId: string | number; id?: string | number },
    modelName: string | number
  ) {
    return this._buildNestedURL(modelName, query.projectId, query?.id);
  }
}

declare module 'ember-data/types/registries/adapter' {
  export default interface AdapterRegistry {
    'security/vulnerability': SecurityVulnerabilityAdapter;
  }
}
