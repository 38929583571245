import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n  local-class='organization-dashboard-root {{if\n    this.isSidebarCollapsed\n    \"collapsed\"\n    \"expanded\"\n  }}'\n>\n  <HomePage::OrganizationDashboard::Header\n    local-class='dashboard-header'\n    @user={{@user}}\n    @logoutAction={{@logoutAction}}\n    @onToggleOnboardingGuide={{this.onToggleOnboardingGuide}}\n  />\n\n  <HomePage::OrganizationDashboard::SideNav\n    local-class='dashboard-sidenav'\n    @isSecurityEnabled={{@isSecurityEnabled}}\n    @isCollapsed={{this.isSidebarCollapsed}}\n    @toggleSidebar={{this.toggleSidebar}}\n  />\n\n  {{! #id is required for scroll-to-top util }}\n  <main id='ak-organization-dashboard-main' local-class='dashboard-main'>\n    {{yield}}\n  </main>\n\n  {{#if this.showOnboardingGuide}}\n    <HomePage::OrganizationDashboard::OnboardingGuide\n      @onClose={{this.onToggleOnboardingGuide}}\n    />\n  {{/if}}\n</div>", {"contents":"<div\n  local-class='organization-dashboard-root {{if\n    this.isSidebarCollapsed\n    \"collapsed\"\n    \"expanded\"\n  }}'\n>\n  <HomePage::OrganizationDashboard::Header\n    local-class='dashboard-header'\n    @user={{@user}}\n    @logoutAction={{@logoutAction}}\n    @onToggleOnboardingGuide={{this.onToggleOnboardingGuide}}\n  />\n\n  <HomePage::OrganizationDashboard::SideNav\n    local-class='dashboard-sidenav'\n    @isSecurityEnabled={{@isSecurityEnabled}}\n    @isCollapsed={{this.isSidebarCollapsed}}\n    @toggleSidebar={{this.toggleSidebar}}\n  />\n\n  {{! #id is required for scroll-to-top util }}\n  <main id='ak-organization-dashboard-main' local-class='dashboard-main'>\n    {{yield}}\n  </main>\n\n  {{#if this.showOnboardingGuide}}\n    <HomePage::OrganizationDashboard::OnboardingGuide\n      @onClose={{this.onToggleOnboardingGuide}}\n    />\n  {{/if}}\n</div>","moduleName":"irene/components/home-page/organization-dashboard/index.hbs","parseOptions":{"srcName":"irene/components/home-page/organization-dashboard/index.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

import UserModel from 'irene/models/user';

export interface HomePageOrganizationDashboardSignature {
  Args: {
    logoutAction: () => void;
    isSecurityEnabled?: boolean;
    user: UserModel;
  };

  Blocks: {
    default: [];
  };
}

export default class HomePageOrganizationDashboardComponent extends Component<HomePageOrganizationDashboardSignature> {
  @service('browser/window') declare window: Window;

  @tracked isSidebarCollapsed: boolean;
  @tracked showOnboardingGuide = false;

  constructor(
    owner: unknown,
    args: HomePageOrganizationDashboardSignature['Args']
  ) {
    super(owner, args);

    const storedState = this.window.localStorage.getItem('sidebarState');

    this.isSidebarCollapsed =
      storedState !== null ? storedState === 'collapsed' : true;
  }

  @action
  toggleSidebar() {
    this.isSidebarCollapsed = !this.isSidebarCollapsed;

    this.window.localStorage.setItem(
      'sidebarState',
      this.isSidebarCollapsed ? 'collapsed' : 'expanded'
    );
  }

  @action
  onToggleOnboardingGuide() {
    this.showOnboardingGuide = !this.showOnboardingGuide;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'HomePage::OrganizationDashboard': typeof HomePageOrganizationDashboardComponent;
  }
}
