export default {
  "header": "_header_12qa68",
  "title": "_title_12qa68",
  "table": "_table_12qa68",
  "table-header": "_table-header_12qa68",
  "add-column": "_add-column_12qa68",
  "table-row": "_table-row_12qa68",
  "pagination": "_pagination_12qa68",
  "loading-error": "_loading-error_12qa68",
  "loading": "_loading_12qa68",
  "empty-data": "_empty-data_12qa68",
  "badge": "_badge_12qa68"
};
