export default {
  "header-sbom-page": "_header-sbom-page_1pqet3",
  "header-sbom-page-title": "_header-sbom-page-title_1pqet3",
  "header-sbom-page-sorting-filter": "_header-sbom-page-sorting-filter_1pqet3",
  "filter-input": "_filter-input_1pqet3",
  "filter-input-dropdown": "_filter-input-dropdown_1pqet3",
  "search-input-container-width": "_search-input-container-width_1pqet3",
  "search-package-name-input": "_search-package-name-input_1pqet3",
  "divider": "_divider_1pqet3",
  "clear-filter-label": "_clear-filter-label_1pqet3",
  "clear-filter-icon": "_clear-filter-icon_1pqet3",
  "clear-filter": "_clear-filter_1pqet3"
};
