import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<form\n  {{on 'submit' (perform this.handleVerification)}}\n  class='w-full'\n  data-test-user-login-check-type\n>\n  <AkStack @width='full' @direction='column' @alignItems='stretch' @spacing='3'>\n    <AkTextField\n      @label={{t 'usernameEmailIdTextLabel'}}\n      @placeholder={{t 'usernameEmailIdTextPlaceholder'}}\n      @labelTypographyColor='secondary'\n      @labelTypographyVariant='subtitle2'\n      @value={{@username}}\n      autocomplete='username'\n      @autofocus={{true}}\n      {{autofocus}}\n      data-test-user-login-check-type-username-input\n    />\n\n    <AkButton\n      aria-label='login-next-button'\n      @type='submit'\n      @loading={{@showSpinner}}\n      @disabled={{if @username false true}}\n      data-test-user-login-check-type-button\n    >\n      {{t 'next'}}\n    </AkButton>\n  </AkStack>\n</form>", {"contents":"<form\n  {{on 'submit' (perform this.handleVerification)}}\n  class='w-full'\n  data-test-user-login-check-type\n>\n  <AkStack @width='full' @direction='column' @alignItems='stretch' @spacing='3'>\n    <AkTextField\n      @label={{t 'usernameEmailIdTextLabel'}}\n      @placeholder={{t 'usernameEmailIdTextPlaceholder'}}\n      @labelTypographyColor='secondary'\n      @labelTypographyVariant='subtitle2'\n      @value={{@username}}\n      autocomplete='username'\n      @autofocus={{true}}\n      {{autofocus}}\n      data-test-user-login-check-type-username-input\n    />\n\n    <AkButton\n      aria-label='login-next-button'\n      @type='submit'\n      @loading={{@showSpinner}}\n      @disabled={{if @username false true}}\n      data-test-user-login-check-type-button\n    >\n      {{t 'next'}}\n    </AkButton>\n  </AkStack>\n</form>","moduleName":"irene/components/user-login/check-type/index.hbs","parseOptions":{"srcName":"irene/components/user-login/check-type/index.hbs"}});
import Component from '@glimmer/component';
import { task } from 'ember-concurrency';

export interface UserLoginCheckTypeComponentSignature {
  Args: {
    username: string;
    showSpinner: boolean;
    verifySSO: () => void;
  };
  Element: HTMLElement;
}

export default class UserLoginCheckTypeComponent extends Component<UserLoginCheckTypeComponentSignature> {
  handleVerification = task(async (event: SubmitEvent) => {
    event.preventDefault();

    this.args.verifySSO();
  });
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'UserLogin::CheckType': typeof UserLoginCheckTypeComponent;
  }
}
