import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkStack\n  @alignItems='flex-end'\n  @justifyContent='center'\n  @width='full'\n  local-class='security-menu'\n  data-test-security-nav-menu\n>\n  <AkTabs as |Akt|>\n    {{#each this.menuItems as |item|}}\n      <Akt.tabItem\n        data-test-security-nav-menu-item='{{item.id}}'\n        @id={{item.id}}\n        @route={{item.route}}\n        @currentWhen={{item.currentWhen}}\n      >\n        {{item.label}}\n      </Akt.tabItem>\n    {{/each}}\n  </AkTabs>\n</AkStack>\n\n{{yield}}", {"contents":"<AkStack\n  @alignItems='flex-end'\n  @justifyContent='center'\n  @width='full'\n  local-class='security-menu'\n  data-test-security-nav-menu\n>\n  <AkTabs as |Akt|>\n    {{#each this.menuItems as |item|}}\n      <Akt.tabItem\n        data-test-security-nav-menu-item='{{item.id}}'\n        @id={{item.id}}\n        @route={{item.route}}\n        @currentWhen={{item.currentWhen}}\n      >\n        {{item.label}}\n      </Akt.tabItem>\n    {{/each}}\n  </AkTabs>\n</AkStack>\n\n{{yield}}","moduleName":"irene/components/security/nav-menu/index.hbs","parseOptions":{"srcName":"irene/components/security/nav-menu/index.hbs"}});
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import RouterService from '@ember/routing/router-service';

export interface SecurityNavMenuSignature {
  Blocks: { default: [] };
}

export default class SecurityNavMenuComponent extends Component<SecurityNavMenuSignature> {
  @service declare router: RouterService;

  get currentRoute() {
    return this.router.currentRouteName;
  }

  get menuItems() {
    return [
      {
        id: 'projects',
        route: 'authenticated.security.projects',
        label: 'Projects',
        currentWhen:
          'authenticated.security.projects authenticated.security.files authenticated.security.file authenticated.security.analysis',
      },
      {
        id: 'downloadapp',
        route: 'authenticated.security.downloadapp',
        label: 'Download App',
        currentWhen: 'authenticated.security.downloadapp',
      },
      {
        id: 'purgeanalysis',
        route: 'authenticated.security.purgeanalysis',
        label: 'Purge API Analyses',
        currentWhen: 'authenticated.security.purgeanalysis',
      },
    ];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Security::NavMenu': typeof SecurityNavMenuComponent;
  }
}
