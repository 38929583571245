import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkStack\n  @justifyContent='space-between'\n  @alignItems='center'\n  @flexWrap='wrap'\n  class='p-2'\n>\n  <AkTypography @variant='h5' data-test-fileDetailScanActions-dynamicScanTitle>\n    {{t 'dast'}}\n  </AkTypography>\n\n  <FileDetails::DynamicScan::StatusChip\n    @file={{@file}}\n    @chipStatusText={{this.chipStatusText}}\n    data-test-fileDetailScanActions-dynamicScanStatus\n  />\n</AkStack>\n\n<AkDivider @color='dark' />\n\n<AkLink\n  @color='primary'\n  @underline='always'\n  @fontWeight='bold'\n  @route='authenticated.dashboard.file.dynamic-scan.manual'\n  @model={{@file.id}}\n  class='px-2 py-1'\n  local-class='view-details-icon'\n  data-test-fileDetailScanActions-dynamicScanViewDetails\n>\n  <:default>\n    {{t 'viewDetails'}}\n  </:default>\n\n  <:rightIcon>\n    <AkIcon @iconName='arrow-right' />\n  </:rightIcon>\n</AkLink>", {"contents":"<AkStack\n  @justifyContent='space-between'\n  @alignItems='center'\n  @flexWrap='wrap'\n  class='p-2'\n>\n  <AkTypography @variant='h5' data-test-fileDetailScanActions-dynamicScanTitle>\n    {{t 'dast'}}\n  </AkTypography>\n\n  <FileDetails::DynamicScan::StatusChip\n    @file={{@file}}\n    @chipStatusText={{this.chipStatusText}}\n    data-test-fileDetailScanActions-dynamicScanStatus\n  />\n</AkStack>\n\n<AkDivider @color='dark' />\n\n<AkLink\n  @color='primary'\n  @underline='always'\n  @fontWeight='bold'\n  @route='authenticated.dashboard.file.dynamic-scan.manual'\n  @model={{@file.id}}\n  class='px-2 py-1'\n  local-class='view-details-icon'\n  data-test-fileDetailScanActions-dynamicScanViewDetails\n>\n  <:default>\n    {{t 'viewDetails'}}\n  </:default>\n\n  <:rightIcon>\n    <AkIcon @iconName='arrow-right' />\n  </:rightIcon>\n</AkLink>","moduleName":"irene/components/file-details/scan-actions/dynamic-scan/index.hbs","parseOptions":{"srcName":"irene/components/file-details/scan-actions/dynamic-scan/index.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';

import type FileModel from 'irene/models/file';

export interface FileDetailsScanActionsDynamicScanSignature {
  Args: {
    file: FileModel;
  };
}

export default class FileDetailsScanActionsDynamicScanComponent extends Component<FileDetailsScanActionsDynamicScanSignature> {
  @service declare intl: IntlService;

  get chipStatusText() {
    if (this.args.file.isDynamicStatusNeitherNoneNorReadyNorError) {
      return this.args.file.statusText;
    } else if (this.args.file.isDynamicStatusInProgress) {
      return this.intl.t('inProgress');
    } else if (this.args.file.isDynamicDone) {
      return this.intl.t('completed');
    } else {
      return this.intl.t('notStarted');
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'FileDetails::ScanActions::DynamicScan': typeof FileDetailsScanActionsDynamicScanComponent;
    'file-details/scan-actions/dynamic-scan': typeof FileDetailsScanActionsDynamicScanComponent;
  }
}
