export default {
  "side-menu-root": "_side-menu-root_1u0ueu",
  "expanded": "_expanded_1u0ueu",
  "divider": "_divider_1u0ueu",
  "collapsed": "_collapsed_1u0ueu",
  "scroll-container": "_scroll-container_1u0ueu",
  "side-menu-list": "_side-menu-list_1u0ueu",
  "app-logo": "_app-logo_1u0ueu",
  "favicon-logo": "_favicon-logo_1u0ueu",
  "menu-item-tooltip": "_menu-item-tooltip_1u0ueu",
  "menu-item-link": "_menu-item-link_1u0ueu",
  "active": "_active_1u0ueu",
  "menu-item-text": "_menu-item-text_1u0ueu",
  "menu-item-badge": "_menu-item-badge_1u0ueu",
  "menu-item-icon": "_menu-item-icon_1u0ueu",
  "submission-container": "_submission-container_1u0ueu",
  "lower-menu": "_lower-menu_1u0ueu",
  "pendo-icon": "_pendo-icon_1u0ueu",
  "pendo-ak-icon": "_pendo-ak-icon_1u0ueu",
  "rotated-icon": "_rotated-icon_1u0ueu",
  "lower-menu-chat": "_lower-menu-chat_1u0ueu",
  "no-hover": "_no-hover_1u0ueu"
};
