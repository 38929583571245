import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if @visible}}\n  <AkStack @alignItems='center' @spacing='0.5' {{style marginTop='0.2em'}}>\n    <AkIcon\n      {{style fontSize='1em !important'}}\n      @iconName='cancel'\n      @color='error'\n    />\n\n    <AkTypography\n      data-test-helper-text='{{@dataTestHelperText}}'\n      @color='error'\n      {{style fontSize='0.857rem'}}\n    >\n      {{@message}}\n    </AkTypography>\n  </AkStack>\n{{/if}}", {"contents":"{{#if @visible}}\n  <AkStack @alignItems='center' @spacing='0.5' {{style marginTop='0.2em'}}>\n    <AkIcon\n      {{style fontSize='1em !important'}}\n      @iconName='cancel'\n      @color='error'\n    />\n\n    <AkTypography\n      data-test-helper-text='{{@dataTestHelperText}}'\n      @color='error'\n      {{style fontSize='0.857rem'}}\n    >\n      {{@message}}\n    </AkTypography>\n  </AkStack>\n{{/if}}","moduleName":"irene/components/user-registration/form/error-helper/index.hbs","parseOptions":{"srcName":"irene/components/user-registration/form/error-helper/index.hbs"}});
import Component from '@glimmer/component';

interface FormErrorHelperSignature {
  Args: {
    message: string;
    dataTestHelperText: string;
    visible: boolean;
  };
  Blocks: {
    default: [];
  };
}

export default class FormErrorHelperComponent extends Component<FormErrorHelperSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'UserRegistration::Form::ErrorHelper': typeof FormErrorHelperComponent;
  }
}
