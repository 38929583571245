import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkStack @alignItems='center' @spacing='1.5'>\n  <AkTypography\n    data-test-org-user-username\n    title={{@member.member.username}}\n    @tag='span'\n    @noWrap={{true}}\n  >\n    {{@member.member.username}}\n  </AkTypography>\n\n  {{#if (eq @member.member.isActive false)}}\n    <AkChip\n      data-test-inactive-chip\n      @label={{t 'chipStatus.inactive'}}\n      @size='small'\n      @variant='semi-filled'\n    />\n  {{/if}}\n</AkStack>", {"contents":"<AkStack @alignItems='center' @spacing='1.5'>\n  <AkTypography\n    data-test-org-user-username\n    title={{@member.member.username}}\n    @tag='span'\n    @noWrap={{true}}\n  >\n    {{@member.member.username}}\n  </AkTypography>\n\n  {{#if (eq @member.member.isActive false)}}\n    <AkChip\n      data-test-inactive-chip\n      @label={{t 'chipStatus.inactive'}}\n      @size='small'\n      @variant='semi-filled'\n    />\n  {{/if}}\n</AkStack>","moduleName":"irene/components/organization-member/list/member-info/index.hbs","parseOptions":{"srcName":"irene/components/organization-member/list/member-info/index.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import MeService from 'irene/services/me';
import OrganizationMemberModel from 'irene/models/organization-member';

interface OrganizationMemberInfoSignature {
  Args: {
    member: OrganizationMemberModel;
  };
  Element: HTMLElement;
}

export default class OrganizationMemberInfoComponent extends Component<OrganizationMemberInfoSignature> {
  @service declare me: MeService;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    OrganizationMemberInfo: typeof OrganizationMemberInfoComponent;
  }
}
