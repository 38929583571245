import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<ImgLogo />", {"contents":"<ImgLogo />","moduleName":"irene/components/auth-assets/index.hbs","parseOptions":{"srcName":"irene/components/auth-assets/index.hbs"}});
import Component from '@glimmer/component';

export default class AuthAssetsComponent extends Component {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    AuthAssets: typeof AuthAssetsComponent;
  }
}
