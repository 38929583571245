import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkClipboard\n  @onSuccess={{this.handleCopySuccess}}\n  @onError={{this.handleCopyError}}\n  as |ac|\n>\n  <AkIconButton\n    data-clipboard-text={{@personalToken.key}}\n    id={{ac.triggerId}}\n    @size='small'\n  >\n    <AkIcon @iconName='content-copy' @color='info' />\n  </AkIconButton>\n</AkClipboard>", {"contents":"<AkClipboard\n  @onSuccess={{this.handleCopySuccess}}\n  @onError={{this.handleCopyError}}\n  as |ac|\n>\n  <AkIconButton\n    data-clipboard-text={{@personalToken.key}}\n    id={{ac.triggerId}}\n    @size='small'\n  >\n    <AkIcon @iconName='content-copy' @color='info' />\n  </AkIconButton>\n</AkClipboard>","moduleName":"irene/components/account-settings/developer-settings/personal-token-list/copy-token/index.hbs","parseOptions":{"srcName":"irene/components/account-settings/developer-settings/personal-token-list/copy-token/index.hbs"}});
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import IntlService from 'ember-intl/services/intl';
import ClipboardJS from 'clipboard/src/clipboard';

import PersonaltokenModel from 'irene/models/personaltoken';

export interface AccountSettingsDeveloperSettingsPersonaltokenListCopyTokenSignature {
  Args: {
    personalToken: PersonaltokenModel;
  };
}

export default class AccountSettingsDeveloperSettingsPersonaltokenListCopyTokenComponent extends Component<AccountSettingsDeveloperSettingsPersonaltokenListCopyTokenSignature> {
  @service declare intl: IntlService;
  @service('notifications') declare notify: NotificationService;

  @action
  handleCopySuccess(event: ClipboardJS.Event) {
    this.notify.info(this.intl.t('tokenCopied'));

    event.clearSelection();
  }

  @action
  handleCopyError() {
    this.notify.error(this.intl.t('pleaseTryAgain'));
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'account-settings/developer-settings/personal-token-list/copy-token': typeof AccountSettingsDeveloperSettingsPersonaltokenListCopyTokenComponent;
  }
}
