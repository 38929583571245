import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkStack\n  @direction='column'\n  @width='full'\n  @justifyContent='center'\n  @alignItems='center'\n  class='pt-3 pb-4'\n  ...attributes\n>\n  <AkSvg::NoApiUrlFilter />\n\n  <AkTypography @fontWeight='bold' class='mb-1 mt-3'>\n    {{@headerText}}\n  </AkTypography>\n\n  <AkTypography>\n    {{@subText}}\n  </AkTypography>\n</AkStack>", {"contents":"<AkStack\n  @direction='column'\n  @width='full'\n  @justifyContent='center'\n  @alignItems='center'\n  class='pt-3 pb-4'\n  ...attributes\n>\n  <AkSvg::NoApiUrlFilter />\n\n  <AkTypography @fontWeight='bold' class='mb-1 mt-3'>\n    {{@headerText}}\n  </AkTypography>\n\n  <AkTypography>\n    {{@subText}}\n  </AkTypography>\n</AkStack>","moduleName":"irene/components/file-details/dynamic-scan/action/drawer/automated-dast/empty-list-state/index.hbs","parseOptions":{"srcName":"irene/components/file-details/dynamic-scan/action/drawer/automated-dast/empty-list-state/index.hbs"}});
import Component from '@glimmer/component';

export interface FileDetailsDynamicScanActionDrawerAutomatedDastEmptyListStateSignature {
  Element: HTMLElement;
  Args: {
    headerText: string;
    subText: string;
  };
}

export default class FileDetailsDynamicScanActionDrawerAutomatedDastEmptyListStateComponent extends Component<FileDetailsDynamicScanActionDrawerAutomatedDastEmptyListStateSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'FileDetails::DynamicScan::Action::Drawer::AutomatedDast::EmptyListState': typeof FileDetailsDynamicScanActionDrawerAutomatedDastEmptyListStateComponent;
  }
}
