import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div local-class='severity-level-root'>\n  <div local-class='severity-level-header'>\n    <AkTypography data-test-fileDetailSeverityLevel-title @variant='subtitle1'>\n      {{t 'severityLevel'}}\n    </AkTypography>\n  </div>\n\n  <AkStack class='p-3' @spacing='5' @alignItems='center' @flexWrap='wrap'>\n    <FileChart\n      {{style maxWidth='350px'}}\n      @file={{@file}}\n      @legendMaxWidth={{350}}\n    />\n  </AkStack>\n</div>", {"contents":"<div local-class='severity-level-root'>\n  <div local-class='severity-level-header'>\n    <AkTypography data-test-fileDetailSeverityLevel-title @variant='subtitle1'>\n      {{t 'severityLevel'}}\n    </AkTypography>\n  </div>\n\n  <AkStack class='p-3' @spacing='5' @alignItems='center' @flexWrap='wrap'>\n    <FileChart\n      {{style maxWidth='350px'}}\n      @file={{@file}}\n      @legendMaxWidth={{350}}\n    />\n  </AkStack>\n</div>","moduleName":"irene/components/file-details/severity-level/index.hbs","parseOptions":{"srcName":"irene/components/file-details/severity-level/index.hbs"}});
import Component from '@glimmer/component';
import FileModel from 'irene/models/file';

export interface FileDetailsSeverityLevelSignature {
  Args: {
    file: FileModel;
  };
}

export default class FileDetailsSeverityLevelComponent extends Component<FileDetailsSeverityLevelSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'FileDetails::SeverityLevel': typeof FileDetailsSeverityLevelComponent;
    'file-details/severity-level': typeof FileDetailsSeverityLevelComponent;
  }
}
