import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkStack @width='full' local-class='empty-container' ...attributes>\n  {{#if (has-block 'illustration')}}\n    {{yield to='illustration'}}\n  {{else}}\n    <AkSvg::TeamsEmpty local-class='empty-svg' />\n  {{/if}}\n\n  <AkTypography @variant='h6'>\n    {{@emptyListText}}\n  </AkTypography>\n</AkStack>", {"contents":"<AkStack @width='full' local-class='empty-container' ...attributes>\n  {{#if (has-block 'illustration')}}\n    {{yield to='illustration'}}\n  {{else}}\n    <AkSvg::TeamsEmpty local-class='empty-svg' />\n  {{/if}}\n\n  <AkTypography @variant='h6'>\n    {{@emptyListText}}\n  </AkTypography>\n</AkStack>","moduleName":"irene/components/project-settings/empty-list/index.hbs","parseOptions":{"srcName":"irene/components/project-settings/empty-list/index.hbs"}});
import Component from '@glimmer/component';

interface ProjectSettingsEmptyListSignature {
  Element: HTMLElement;
  Args: { emptyListText?: string };
  Blocks: {
    illustration?: [];
  };
}

export default class ProjectSettingsEmptyListComponent extends Component<ProjectSettingsEmptyListSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ProjectSettings::EmptyList': typeof ProjectSettingsEmptyListComponent;
  }
}
