import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if (and @condition @label)}}\n  <AkChip\n    ...attributes\n    @size='small'\n    @variant='semi-filled'\n    @color={{@condition}}\n    @label={{@label}}\n    local-class='status'\n    data-test-am-status-element\n    data-test-am-status={{@condition}}\n  />\n{{/if}}", {"contents":"{{#if (and @condition @label)}}\n  <AkChip\n    ...attributes\n    @size='small'\n    @variant='semi-filled'\n    @color={{@condition}}\n    @label={{@label}}\n    local-class='status'\n    data-test-am-status-element\n    data-test-am-status={{@condition}}\n  />\n{{/if}}","moduleName":"irene/components/app-monitoring/status/index.hbs","parseOptions":{"srcName":"irene/components/app-monitoring/status/index.hbs"}});
import Component from '@glimmer/component';
import { AkChipColor } from 'irene/components/ak-chip';
interface AppMonitoringStatusSignature {
  Element: HTMLDivElement;
  Args: {
    condition?: AkChipColor;
    label?: string;
  };
}

export default class AppMonitoringStatusComponent extends Component<AppMonitoringStatusSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'AppMonitoring::Status': typeof AppMonitoringStatusComponent;
  }
}
