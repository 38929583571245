export default {
  "analysis-section": "_analysis-section_1tr0ny",
  "analysis-overridded-passed": "_analysis-overridded-passed_1tr0ny",
  "analysis-content-title": "_analysis-content-title_1tr0ny",
  "vulnerability-finding-container": "_vulnerability-finding-container_1tr0ny",
  "analysis-content-value": "_analysis-content-value_1tr0ny",
  "analysis-content-container": "_analysis-content-container_1tr0ny",
  "ak-pagination-button-prev-icon": "_ak-pagination-button-prev-icon_1tr0ny",
  "ak-pagination-button-next-icon": "_ak-pagination-button-next-icon_1tr0ny",
  "ak-pagination-select": "_ak-pagination-select_1tr0ny",
  "ak-pagination-prev-button": "_ak-pagination-prev-button_1tr0ny",
  "ak-pagination-next-button": "_ak-pagination-next-button_1tr0ny",
  "counter-container": "_counter-container_1tr0ny"
};
