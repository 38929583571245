import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkStack local-class='app-details-container' @direction='column' @spacing='1.5'>\n  <AkStack @spacing='1.5'>\n    <Sbom::AppLogo @sbomProject={{@sbomProject}} />\n    <Sbom::AppPlatform @sbomProject={{@sbomProject}} @bordered={{true}} />\n  </AkStack>\n\n  <AkStack @direction='column' @spacing='0.5'>\n    <AkTypography data-test-sbomReportDrawer-appName @variant='h5'>\n      {{this.appName}}\n    </AkTypography>\n\n    <AkTypography\n      data-test-sbomReportDrawer-appPackageName\n      @variant='h6'\n      @color='textSecondary'\n    >\n      {{this.appPackageName}}\n    </AkTypography>\n  </AkStack>\n\n  <AkTypography data-test-sbomReportDrawer-description>\n    {{t 'sbomModule.sbomDownloadReportDescription'}}\n  </AkTypography>\n</AkStack>", {"contents":"<AkStack local-class='app-details-container' @direction='column' @spacing='1.5'>\n  <AkStack @spacing='1.5'>\n    <Sbom::AppLogo @sbomProject={{@sbomProject}} />\n    <Sbom::AppPlatform @sbomProject={{@sbomProject}} @bordered={{true}} />\n  </AkStack>\n\n  <AkStack @direction='column' @spacing='0.5'>\n    <AkTypography data-test-sbomReportDrawer-appName @variant='h5'>\n      {{this.appName}}\n    </AkTypography>\n\n    <AkTypography\n      data-test-sbomReportDrawer-appPackageName\n      @variant='h6'\n      @color='textSecondary'\n    >\n      {{this.appPackageName}}\n    </AkTypography>\n  </AkStack>\n\n  <AkTypography data-test-sbomReportDrawer-description>\n    {{t 'sbomModule.sbomDownloadReportDescription'}}\n  </AkTypography>\n</AkStack>","moduleName":"irene/components/sbom/scan-report-drawer/app-details/index.hbs","parseOptions":{"srcName":"irene/components/sbom/scan-report-drawer/app-details/index.hbs"}});
import Component from '@glimmer/component';
import SbomProjectModel from 'irene/models/sbom-project';

export interface SbomScanReportDrawerAppDetailsSignature {
  Args: {
    sbomProject?: SbomProjectModel;
  };
}

export default class SbomScanReportDrawerAppDetailsComponent extends Component<SbomScanReportDrawerAppDetailsSignature> {
  get appName() {
    return this.args.sbomProject?.get('project')?.get('lastFile')?.get('name');
  }

  get appPackageName() {
    return this.args.sbomProject?.get('project')?.get('packageName');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Sbom::ScanReportDrawer::AppDetails': typeof SbomScanReportDrawerAppDetailsComponent;
  }
}
